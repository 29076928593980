import type { TestamonialCarousel as CarouselProps } from '~/types'
import { StepCarousel } from './StepCarousel'
import { TestimonialCarousel } from './TestimonialCarousel'

export const Carousel: React.FC<CarouselProps> = ({ testamonials, ...props }) => {
	return props.theme === 'dark' ? (
		<StepCarousel testimonials={testamonials} {...props} />
	) : (
		<TestimonialCarousel testimonials={testamonials} {...props} />
	)
}
