import type { FunctionComponent, SVGProps } from 'react'

// TODO: Replace with @nomonosound/gravity's icon
export const RemoveIcon: FunctionComponent<SVGProps<SVGSVGElement>> = ({ fill = 'currentColor', ...props }) => {
	return (
		<svg viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M12 2a10 10 0 1 0-.002 20A10 10 0 0 0 12 2Zm0 18a8.003 8.003 0 0 1-7.391-4.938 7.996 7.996 0 0 1 1.734-8.719 7.996 7.996 0 0 1 12.309 1.212 8.003 8.003 0 0 1-.995 10.102A8 8 0 0 1 12 20Zm4-9H8a.997.997 0 0 0-1 1 .997.997 0 0 0 1 1h8a.997.997 0 0 0 1-1 .997.997 0 0 0-1-1Z"
				fill="#232429"
			/>
		</svg>
	)
}
