import { FC, PropsWithChildren } from 'react'
import { AmazonButtons as AmazonButtonsProps, SegmentEvent } from '~/types'
import { track } from '~/utils/track'
import { Button } from './Button'

const BlackFill = { fill: '#232F3E' }
const OrangeFill = { fill: '#FF9900' }

const AmazonButtons: FC<PropsWithChildren<AmazonButtonsProps>> = ({ Amazon_UK_URL, Amazon_US_URL }) => {
	return (
		<div className="block sm:inline-flex">
			<Button
				target="_blank"
				className="!mt-0 ml-2 !h-14 !w-48 !bg-[#1F1F2114] !px-7 !text-base"
				to={Amazon_US_URL}
				onClick={() => {
					track(SegmentEvent.AmazonLinkClicked, {
						link: Amazon_US_URL,
						page: window.location.href
					})
				}}
			>
				<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M12.9467 9.29235C12.9467 10.4262 12.9753 11.3719 12.4022 12.3788C11.9396 13.1976 11.2069 13.7011 10.3882 13.7011C9.27058 13.7011 8.61971 12.8496 8.61971 11.5929C8.61971 9.11218 10.8425 8.66194 12.9467 8.66194V9.29235ZM15.8898 16.3947C15.6974 16.5665 15.4109 16.5707 15.194 16.456C14.2279 15.6537 14.056 15.2812 13.5238 14.5157C11.9273 16.145 10.7975 16.632 8.72619 16.632C6.27823 16.632 4.37061 15.1215 4.37061 12.0964C4.37061 9.73448 5.65196 8.12567 7.47348 7.33978C9.05365 6.64379 11.2601 6.521 12.9467 6.32867V5.95203C12.9467 5.26019 12.9998 4.4415 12.5946 3.84388C12.2384 3.30762 11.5589 3.08655 10.9612 3.08655C9.85189 3.08655 8.86123 3.65552 8.61971 4.83445C8.57053 5.0965 8.3782 5.35441 8.11623 5.36667L5.29163 5.0638C5.05426 5.01048 4.79229 4.81814 4.85778 4.45376C5.50864 1.03164 8.59918 0 11.3664 0C12.7828 0 14.633 0.37664 15.7506 1.44918C17.167 2.77135 17.0319 4.53564 17.0319 6.45551V10.9912C17.0319 12.3543 17.5968 12.9519 18.129 13.6888C18.3172 13.9508 18.3582 14.2661 18.1208 14.4625C17.5272 14.9577 16.471 15.8788 15.8898 16.3947Z"
						style={BlackFill}
					/>
					<path
						d="M19.0511 18.11C9.97072 22.4314 4.33534 18.8158 0.727964 16.6197C0.50474 16.4813 0.125338 16.6521 0.454522 17.0302C1.65632 18.4874 5.59487 21.9997 10.7358 21.9997C15.8803 21.9997 18.9408 19.1926 19.3237 18.7029C19.7039 18.2174 19.4354 17.9496 19.0511 18.11ZM21.6013 16.7016C21.3574 16.3841 20.1185 16.3249 19.3388 16.4206C18.558 16.5137 17.3858 16.9909 17.4877 17.2775C17.54 17.3848 17.6468 17.3367 18.1832 17.2884C18.7211 17.2348 20.228 17.0446 20.542 17.455C20.8575 17.8684 20.0614 19.8372 19.916 20.1548C19.7755 20.4723 19.9697 20.5542 20.2336 20.3427C20.4938 20.1313 20.9649 19.5838 21.2811 18.809C21.5951 18.0301 21.7866 16.9434 21.6013 16.7016Z"
						style={OrangeFill}
					/>
				</svg>
				Go to Amazon US
			</Button>
			<Button
				target="_blank"
				className="!mt-2 ml-2 !h-14 !w-48 !bg-[#1F1F2114] !px-7 !text-base sm:!mt-0"
				to={Amazon_UK_URL}
				onClick={() => {
					track(SegmentEvent.AmazonLinkClicked, {
						link: Amazon_UK_URL,
						page: window.location.href
					})
				}}
			>
				<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M12.9467 9.29235C12.9467 10.4262 12.9753 11.3719 12.4022 12.3788C11.9396 13.1976 11.2069 13.7011 10.3882 13.7011C9.27058 13.7011 8.61971 12.8496 8.61971 11.5929C8.61971 9.11218 10.8425 8.66194 12.9467 8.66194V9.29235ZM15.8898 16.3947C15.6974 16.5665 15.4109 16.5707 15.194 16.456C14.2279 15.6537 14.056 15.2812 13.5238 14.5157C11.9273 16.145 10.7975 16.632 8.72619 16.632C6.27823 16.632 4.37061 15.1215 4.37061 12.0964C4.37061 9.73448 5.65196 8.12567 7.47348 7.33978C9.05365 6.64379 11.2601 6.521 12.9467 6.32867V5.95203C12.9467 5.26019 12.9998 4.4415 12.5946 3.84388C12.2384 3.30762 11.5589 3.08655 10.9612 3.08655C9.85189 3.08655 8.86123 3.65552 8.61971 4.83445C8.57053 5.0965 8.3782 5.35441 8.11623 5.36667L5.29163 5.0638C5.05426 5.01048 4.79229 4.81814 4.85778 4.45376C5.50864 1.03164 8.59918 0 11.3664 0C12.7828 0 14.633 0.37664 15.7506 1.44918C17.167 2.77135 17.0319 4.53564 17.0319 6.45551V10.9912C17.0319 12.3543 17.5968 12.9519 18.129 13.6888C18.3172 13.9508 18.3582 14.2661 18.1208 14.4625C17.5272 14.9577 16.471 15.8788 15.8898 16.3947Z"
						style={BlackFill}
					/>
					<path
						d="M19.0511 18.11C9.97072 22.4314 4.33534 18.8158 0.727964 16.6197C0.50474 16.4813 0.125338 16.6521 0.454522 17.0302C1.65632 18.4874 5.59487 21.9997 10.7358 21.9997C15.8803 21.9997 18.9408 19.1926 19.3237 18.7029C19.7039 18.2174 19.4354 17.9496 19.0511 18.11ZM21.6013 16.7016C21.3574 16.3841 20.1185 16.3249 19.3388 16.4206C18.558 16.5137 17.3858 16.9909 17.4877 17.2775C17.54 17.3848 17.6468 17.3367 18.1832 17.2884C18.7211 17.2348 20.228 17.0446 20.542 17.455C20.8575 17.8684 20.0614 19.8372 19.916 20.1548C19.7755 20.4723 19.9697 20.5542 20.2336 20.3427C20.4938 20.1313 20.9649 19.5838 21.2811 18.809C21.5951 18.0301 21.7866 16.9434 21.6013 16.7016Z"
						style={OrangeFill}
					/>
				</svg>
				Go to Amazon UK
			</Button>
		</div>
	)
}

export default AmazonButtons
