import type { FunctionComponent } from 'react'

export const Play: FunctionComponent = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height="1em" fill="currentColor" viewBox="0 0 13 15">
			<path d="M12.5 7.5l-12 6.928V.572l12 6.928z" />
		</svg>
	)
}

export default Play
