import { V2 } from '@nomonosound/gravity'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { Fragment, FunctionComponent, PropsWithChildren, useState } from 'react'
import { Heading } from '~/components/FontStyles'
import {
	SpecsTable,
	Accordion as StyledAccordion,
	AccordionItem as StyledAccordionItem,
	AccordionTrigger as StyledAccordionTrigger
} from '~/components/blocks'
import type { Accordions } from '~/types'
import { cn, getBackgroundColor, getColor } from '~/utils'
import { StyledContent } from '../TechSpecs'
import { PortableText } from './PortableText'

const Wrapper: FunctionComponent<PropsWithChildren<Pick<Accordions, 'theme' | 'style'>>> = ({
	theme,
	style,
	children
}) => {
	return (theme && theme !== 'inherit') || style !== 'default' ? (
		<div
			data-name="portable-accordion-wrapper"
			className={cn({
				'xs:p-8 space-y-8 rounded-xl p-4 md:p-16': style === 'default',
				'space-y-4 rounded-lg p-3': style === 'compact'
			})}
			style={{ color: getColor({ theme }), backgroundColor: getBackgroundColor({ theme }) }}
		>
			{children}
		</div>
	) : (
		<Fragment>{children}</Fragment>
	)
}

export const PortableAccordion: FunctionComponent<Accordions> = ({ title, contents, style = 'default', theme }) => {
	const Accordion = style === 'default' ? StyledAccordion : AccordionPrimitive.Accordion
	const AccordionItem = style === 'default' ? StyledAccordionItem : AccordionPrimitive.Item
	const AccordionTrigger = style === 'default' ? StyledAccordionTrigger : AccordionPrimitive.Trigger
	const [active, setActive] = useState<string[]>([])
	return (
		<Wrapper theme={theme} style={style}>
			{title && <Heading type="displaySm">{title}</Heading>}
			<Accordion type="multiple" onValueChange={setActive} value={active}>
				{contents?.map(({ title: itemTitle, ...item }, i) => {
					const key = `item-${i}`
					return (
						<AccordionItem key={key} value={key}>
							<AccordionTrigger asChild>
								<div className="flex cursor-pointer justify-between">
									<PortableText blocks={itemTitle} />
									{style !== 'default' && (
										<div
											className={cn(
												'fill:fill-none fill:stroke-current svg svg:h-[1.5em] svg:transition-transform svg:duration-300',
												{ 'svg:-rotate-180': active.includes(key) }
											)}
										>
											<V2.ChevronDownLineIcon />
										</div>
									)}
								</div>
							</AccordionTrigger>
							<StyledContent>
								{item._type === 'customizableTable' ? (
									<SpecsTable {...item} />
								) : (
									<PortableText className="pt-2" blocks={item.content} />
								)}
							</StyledContent>
						</AccordionItem>
					)
				})}
			</Accordion>
		</Wrapper>
	)
}
