import type { FunctionComponent } from 'react'

export const ArrowLong: FunctionComponent = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
			<path stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M15 8H1m0 0l7 7M1 8l7-7" />
		</svg>
	)
}

export default ArrowLong
