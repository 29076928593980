import type { FunctionComponent } from 'react'
import styled from 'styled-components'
import type { Nullable } from '~/types'

export const Word = styled.span<{}>`
	color: ${(props) => (props.theme.current === 'light' ? 'var(--primary)' : '#B5BCFF')};
`

export interface HighlightProps {
	children: Nullable<string>
}

export const Highlight: FunctionComponent<HighlightProps> = ({ children }) => {
	if (!children) return null
	return (
		<>
			{children.split(' ').map((word, i) => {
				if (word.startsWith('!') && word.length > 1) {
					return <Word key={i}>{`${word.substring(1)} `}</Word>
				}
				return `${word} `
			})}
		</>
	)
}
