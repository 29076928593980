/* eslint-disable id-blacklist */

import { Fragment, ReactNode } from 'react'
import styled from 'styled-components'
import type { Serializers } from './types'

const NumberedList = styled.ol`
	list-style-type: none;
	padding-left: 0;

	display: grid;
	grid-template-columns: min-content auto;
	grid-auto-flow: row;
	grid-gap: 2.75rem 1.5rem;
	align-items: center;
`

const Number = styled.span<{ row: number }>`
	grid-row: ${(props) => props.row};
	grid-column: 1;
	background-color: transparent;
	border: 2px solid currentColor;
	border-radius: 999px;

	width: 2.25rem;
	height: 2.25rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 1.25rem;
`

const ListItem = styled.li<{ row: number }>`
	grid-row: ${(props) => props.row};
	grid-column: 2;

	strong {
		font-size: 1.25em;
	}
`

export const numberList: Pick<Serializers, 'list' | 'listItem'> = {
	list: {
		number: (props) => <ol>{props.children}</ol>,
		iconNumber: ({ children }) => (
			<NumberedList>
				{(children as ReactNode[]).map((child, i) => (
					<Fragment key={i}>
						<Number row={i + 1}>
							<span style={{ verticalAlign: 'middle' }}>{i + 1}</span>
						</Number>
						<ListItem row={i + 1}>{child}</ListItem>
					</Fragment>
				))}
			</NumberedList>
		)
	},
	listItem: {
		iconNumber: (props) => {
			return <>{props.children}</>
		},
		number: (props) => <li>{props.children}</li>,
		bullet: ({ children }) => <li>{children}</li>
	}
}
