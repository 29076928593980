import type { FunctionComponent } from 'react'
import type { EmbeddedVideo as EmbeddedVideoProps } from '~/types'
import { ExternalVideo } from './ExternalVideo'
import { VideoPlayer } from './VideoPlayer'
import { YoutubeVideo } from './YoutubeVideo'

export const EmbeddedVideo: FunctionComponent<EmbeddedVideoProps> = ({ video: media, ...props }) => {
	const video = media?.[0]

	switch (video?._type) {
		case 'externalVideo':
			return <ExternalVideo {...video} />
		case 'youtubeVideo':
			return <YoutubeVideo {...video} />
		case 'mux.video':
			return <VideoPlayer {...props} video={video} />
		default:
			return <h2 className="my-8 text-3xl">Video missing</h2>
	}
}
