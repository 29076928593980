import type { FontSize } from '~/types'

type Heading = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export function getSize(sz: FontSize | Heading | undefined) {
	switch (sz) {
		case 'sm':
		case 'h6':
			return 'text-sm'

		case 'normal':
			return 'inherit'

		case 'h5':
			return 'text-base'

		case 'lg':
		case 'h4':
			return 'text-lg'

		case 'h3':
		case '2xl':
			return 'text-xl'

		case 'h2':
		case '3xl':
			return 'text-2xl'

		case '4xl':
		case 'h1':
			return 'text-3xl'
	}

	return undefined
}
