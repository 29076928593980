import type { FunctionComponent } from 'react'

export const Pause: FunctionComponent = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height="1em" fill="currentColor" viewBox="0 0 16 16" className="pause">
			<path d="M2.2 2.5a.5.5 0 01.5-.5h2.5a.5.5 0 01.5.5v11a.5.5 0 01-.5.5H2.7a.5.5 0 01-.5-.5v-11zM10.2 2.5a.5.5 0 01.5-.5h2.5a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-2.5a.5.5 0 01-.5-.5v-11z" />
		</svg>
	)
}

export default Pause
