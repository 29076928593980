import { V2 } from '@nomonosound/gravity'
import type { FunctionComponent } from 'react'
import { PortableText } from '~/components/blocks'
import { ResponsiveImage } from '~/components/Image'
import type { BannerItem as BannerItemProps, Image } from '~/types'

export const BannerItem: FunctionComponent<BannerItemProps> = (item) => {
	return (
		<div
			className={[
				'flex flex-nowrap gap-x-2',
				item.iconPosition === 'left' ? 'flex-row' : 'flex-row-reverse'
			].join(' ')}
		>
			{typeof item.icon === 'string' && item.icon ? (
				<V2.Icon icon={item.icon} />
			) : item.icon ? (
				<ResponsiveImage image={item.icon as Image} layout="auto" height="20" width="20" objectFit="contain" />
			) : (
				<span />
			)}
			<div>
				<PortableText blocks={item.content} />
			</div>
		</div>
	)
}
