// countries where Klarna payments are not working
export const unavailableKlarnaCountries = [
	'BG',
	'HR',
	'CY',
	'CZ',
	'DK',
	'EE',
	'HU',
	'LV',
	'LT',
	'LU',
	'MT',
	'PL',
	'RO',
	'SK',
	'SI'
]

// countries where Klarna payments are working, but the klarna-placement badge is not working
// we're missing a data-client-id for US
// our price is too high for ES/FR
export const specialKlarnaCountries = ['US', 'ES', 'FR']
