import { useState, type FunctionComponent } from 'react'
import type { Except } from 'type-fest'
import type { MediaType } from '~/types'
import { useIsomorphicEffect, useWindowSize } from '~/utils'
import type { ResponsiveImageProps } from './Image'
import { ResponsiveImage } from './Image'
import { ExternalVideo, VideoPlayer } from './blocks'
import { YoutubeVideo } from './blocks/EmbeddedVideo/YoutubeVideo'

export interface MediaProps extends Except<ResponsiveImageProps, 'image'> {
	/**  TODO: Use Generated {@link Media} type when MuxVideo has proper */
	media: MediaType | MediaType[]
}

export function useMedia<T = MediaType>(media: T | T[] | undefined) {
	const [mediaState, setMediaState] = useState<T>()
	const { isMobile } = useWindowSize()

	useIsomorphicEffect(() => {
		if (isMobile && Array.isArray(media) && media.length > 1) {
			setMediaState(media[1])
		} else {
			setMediaState(Array.isArray(media) ? media[0] : media)
		}
	}, [media, isMobile])

	return mediaState
}

export const Media: FunctionComponent<MediaProps> = (props) => {
	const media = useMedia(props.media)

	if (!media) return null

	switch (media._type) {
		case 'externalVideo':
			return <ExternalVideo {...media} />
		case 'youtubeVideo':
			return <YoutubeVideo {...media} />

		case 'altimage':
		case 'image':
			return <ResponsiveImage {...props} image={media} />

		case 'mux.video':
			return <VideoPlayer {...props} video={media} player="mux" />

		default:
			return <h2>Unknown Media Type</h2>
	}
}
