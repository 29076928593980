import type { FunctionComponent } from 'react'
import { Fragment, useContext } from 'react'
import type { Spacer as SpacerType } from '~/types'
import { getBackgroundColor, useTheme } from '~/utils'
import { Breakout } from '../layout/Containers'
import { IdContext } from './IdContext'

const getAmount = (amt: string | undefined) => (amt?.match(/^[0-9]+$/) === null ? amt : `${amt}rem`)

export interface SpacerProps extends SpacerType {
	wrapper?: boolean
}

export const Spacer: FunctionComponent<SpacerProps> = ({
	_type,
	type = 'padding',
	amount,
	mobileAmount = amount,
	wrapper = true,
	inline = false,
	...props
}) => {
	const id = useContext(IdContext)
	const theme = useTheme(props.theme)
	const Wrapper = wrapper ? Breakout : Fragment

	const showOnDesktop = props.showOnDesktop || !!amount
	const showOnMobile = props.showOnMobile || !!mobileAmount

	return (
		<Wrapper>
			{showOnMobile && (
				<div
					id={id}
					style={{
						[`${type}Top`]: getAmount(mobileAmount),
						[`${type}Bottom`]: getAmount(mobileAmount),
						backgroundColor: getBackgroundColor({ theme })
					}}
					className={`${_type} ${inline ? 'inline-block' : 'block'} md:hidden`}
				/>
			)}
			{showOnDesktop && (
				<div
					id={id}
					style={{
						[`${type}Top`]: getAmount(amount),
						[`${type}Bottom`]: getAmount(amount),
						backgroundColor: getBackgroundColor({ theme })
					}}
					className={`${_type} hidden ${inline ? 'md:inline-block' : 'md:block'}`}
				/>
			)}
		</Wrapper>
	)
}
