/* eslint-disable @next/next/no-img-element */
import { colors } from '@nomonosound/gravity'
import type { FunctionComponent } from 'react'
import styled from 'styled-components'
import { isPresent } from 'ts-extras'
import { SanityLink } from '~/components/SanityLink'
import { getLinkText, getSecondaryColor, resolve, useSettings } from '~/utils'
import { openConsentManager } from '../ConsentManager'

interface NavSectionProps {}

export const NavSections: FunctionComponent<NavSectionProps> = () => {
	const { siteSettings } = useSettings()
	const { footerItems: navSections, opCount } = siteSettings || {}

	return (
		<>
			{navSections
				?.map(resolve)
				.filter(isPresent)
				.map((menu) => {
					return (
						<NavSection key={menu._id} opCount={opCount}>
							<p>{menu.title}</p>
							{menu.items?.map((link, i) => {
								return (
									<SanityLink
										key={i}
										className={
											opCount && getLinkText(link)?.toLowerCase().includes('career')
												? 'addCount'
												: ''
										}
										link={link}
									/>
								)
							})}
							{menu.slug?.current === 'legal' && (
								<a
									onClick={(e) => {
										e.preventDefault()
										openConsentManager()
									}}
									style={{ cursor: 'pointer' }}
								>
									Cookies
								</a>
							)}
						</NavSection>
					)
				})}
		</>
	)
}

interface NavSectionMiddleProps extends NavSectionProps {
	opCount?: number
}

const NavSection = styled.nav<NavSectionMiddleProps>`
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	> *:not(:first-child) {
		margin-top: 0.75rem;
	}

	p {
		margin: 0 0 0.25rem;
		color: ${getSecondaryColor};
	}
	a {
		color: inherit;
		text-decoration: none;
		white-space: nowrap;
	}
	.addCount {
		:after {
			content: '${(props) => props.opCount}';
			padding: 2px 0.5rem;
			color: ${colors.black};
			background: var(--primary);
			border-radius: 1rem;
			font-weight: 500;
			margin-left: 0.5rem;
			font-size: 12px;
		}
	}
`
