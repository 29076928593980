import { screenSizes } from '@nomonosound/gravity'
import type { FunctionComponent } from 'react'
import styled from 'styled-components'
import type { ProductContent as ProductContentProps } from '~/types'
import { Section } from '../Section'
import { ContentBox } from './ContentBox'

const ContentContainer = styled.div<Pick<ProductContentProps, 'settings'>>`
	margin-top: ${(p) => (p.theme.current === 'white' ? 4 : 0)}rem;
	display: grid;
	gap: ${(p) => (p.theme.current === 'white' ? 1 : 4)}rem;
	grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));

	text-align: ${(p) => p.settings?.textAlign ?? 'center'};
	@media (max-width: ${screenSizes.small}) {
		margin-top: 3.1rem;
		grid-template-columns: 1fr;
	}
`

export const ProductContent: FunctionComponent<ProductContentProps> = ({
	title,
	animate = true,
	content,
	settings
}) => {
	return (
		<Section {...settings}>
			<h2>{title}</h2>
			<ContentContainer>
				{content?.map((c, i) => (
					<ContentBox
						key={c._key ?? i}
						{...c}
						animate={animate}
						textAlign={settings?.textAlign ?? 'center'}
					/>
				))}
			</ContentContainer>
		</Section>
	)
}
