import { colors, screenSizes, size } from '@nomonosound/gravity'
import styled, { css } from 'styled-components'

export const PageHeading = styled.h1`
	font-size: 3.5rem; // 64px
	font-size: clamp(1.5rem, 10vw, 3.5rem); // min 20px, max 64px
	font-weight: normal;
	line-height: 1.15;
	margin: 0 auto;
	padding: 0;
	margin-bottom: 2.5rem; // 40px
	@media (max-width: ${screenSizes.small}) {
		margin-bottom: 1.8rem;
	}
	white-space: pre-wrap;
`

export const defaultSectionMargin = css`
	margin-bottom: 5rem;
	margin-bottom: clamp(2rem, 15rem, 10vw);
`

const HX = styled.div`
	font-weight: normal;
	line-height: 1.5;
`

export const H2 = styled.h2`
	font-size: 2.5rem; // 40px
	font-size: clamp(1.25rem, 8vw, 2.5rem);
	line-height: 1.2;
	margin: 0;
`

export const StyledPortableText = styled.article`
	h1,
	h2 {
		${HX}
	}
	/* h1 {
    font-size: 4.5rem; // 40px
    font-size: clamp(1.5rem, 10vw, 4.5rem);
    line-height: 1.2;
  } */
	h2 {
		${H2}
		margin: 2rem 0;
	}

	h3 {
		font-size: 1rem; // 20px
		line-height: 1.2;
		margin: 2rem 0;
	}

	h4 {
		font-size: 1rem; // 20px
		line-height: 1.2;
		margin: 2rem 0;
	}

	strong {
		font-weight: bold;
	}
	em {
		font-style: italic;
	}
	p {
		line-height: 1.5;
		font-size: 1rem;
		margin: 1rem 0;
	}
	ul li {
		font-size: 1rem;
		margin: 1rem 0;
	}

	p > a {
		color: ${colors.blue40};
		text-decoration: underline;
		text-underline-offset: 0.25em;
	}
`

export const DefaultIngres = styled.p`
	font-size: clamp(1rem, 22px, 4vw);
	line-height: 1.5;
	width: 46rem;
	max-width: 100%;
	margin: 0;
	color: ${colors.gray50};

	&[data-layout='center'] {
		margin: 0 auto;
		text-align: center;
	}
`

export const Ingres = styled.div`
	/* font-size: ${size.large}; */
	font-size: 20px;
	color: ${colors.gray20};
	p {
		color: inherit;
	}
`

const defaultButtonCss = css`
	overflow: visible;
	position: relative;
	background-color: transparent;
	font-size: 18px;
	padding: 8px 16px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-color: transparent;
	cursor: pointer;
	transition: border-color 0.12s ease-out, background-color 0.12s ease-out;
	background-color: #000;
	color: #ffffff;
	fill: #ffffff;
	width: 100%;
	text-decoration: none;
	white-space: nowrap;
	appearance: none;
`

const defaultButtonGradient = css`
	background: linear-gradient(93.32deg, #b5bcff -18.98%, #e4ccff 127.31%);
	background-color: #e4ccff;
`

export const gradientButtonCss = css`
	${defaultButtonCss}
	${defaultButtonGradient}
  	font-size: 1rem; // 16px
	padding: 0.7rem 1rem;
	width: max-content;
	color: ${colors.black};
	border-radius: 10px;
	transition: transform 150ms ease-out;

	&:hover {
		transform: translateY(-1px);
		background: linear-gradient(93.32deg, #b5bcff -18.98%, #e4ccff 127.31%);
	}
`

export const focusOnTab = css`
	&:focus {
		outline: 3px solid var(--primary);
		&:not(:focus-visible) {
			outline: none;
		}
	}
`
