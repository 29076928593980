import Script from 'next/script'
import { FC, PropsWithChildren, useRef, useState } from 'react'
import styled from 'styled-components'
import type { ExternalVideo as ExternalVideoProps } from '~/types'
import { assetUrl, useIsomorphicEffect, useOnScreen } from '~/utils'

const IFrame = styled.iframe`
	margin-left: auto;
	margin-right: auto;
	border-radius: 1rem;
	overflow: hidden;
	aspect-ratio: ${(p) => (p.height ? `${p.width} / ${p.height}` : '16 / 9')};
	height: auto;
	max-width: 100%;
	width: ${(p) => p.width}px;
`

const Img = styled.img`
	margin-left: auto;
	margin-right: auto;
	border-radius: 1rem;
	overflow: hidden;
	aspect-ratio: ${(p) => (p.height ? `${p.width} / ${p.height}` : '16 / 9')};
	height: auto;
	max-width: 100%;
	width: ${(p) => p.width}px;
`

export const ExternalVideo: FC<PropsWithChildren<ExternalVideoProps>> = ({ width, height, children, ...props }) => {
	const [isResponsive, setIsResponsive] = useState(false)
	const containerRef = useRef<HTMLDivElement>(null)
	const lockRef = useRef(false)
	const isOnScreen = useOnScreen(containerRef) // used to lazy load vimeo iframes (they don't work out of the box)

	if (isOnScreen && !lockRef.current) {
		lockRef.current = true
	}

	// Prevent hydration errors.
	useIsomorphicEffect(() => {
		setIsResponsive(!width && !height)
	}, [width, height])

	const url = assetUrl(props)

	const styleProps: Record<string, string | undefined | number> = {}

	if (isResponsive) {
		styleProps.className = 'absolute top-0 left-0 h-full w-full'
	} else {
		styleProps.width = width
		styleProps.height = height ?? (width ? Math.round(Number.parseInt(width) / (16 / 9)) : undefined)
	}

	const IframeComponent = (
		<IFrame
			src={url.toString()}
			frameBorder="0"
			allow="autoplay; fullscreen; picture-in-picture"
			allowFullScreen
			loading="lazy"
			{...styleProps}
		/>
	)

	const PlaceholderImageComponent = <Img {...styleProps} />

	return (
		<div ref={containerRef} style={{ width: '100%', height: '56.25%' }}>
			{lockRef.current ? (
				<>
					{isResponsive ? <div className="relative pt-[56.25%]">{IframeComponent}</div> : { IframeComponent }}
					{children}
					<Script key="vimeo" strategy="lazyOnload" src="https://player.vimeo.com/api/player.js" />
				</>
			) : isResponsive ? (
				<div className="relative pt-[56.25%]">{PlaceholderImageComponent}</div>
			) : (
				<>{PlaceholderImageComponent}</>
			)}
		</div>
	)
}
