import { V2, screenSizes } from '@nomonosound/gravity'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { FunctionComponent, MouseEventHandler, PropsWithChildren } from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import type { Except } from 'type-fest'
import type { Page, Link as SanitySchemaLink } from '~/types'
import { cn, getLinkPath, getLinkText, usePage } from '~/utils'
import type { ButtonProps } from './Button'
import { Button } from './Button'
import { ResponsiveImage } from './Image'
import { HeaderButtonCss } from './layout/Header/containers'

export interface SanityLinkProps extends PropsWithChildren {
	className?: string
	// Highligh link element if current page
	useActive?: boolean
	link: Except<SanitySchemaLink, 'pageReference'> & {
		pageReference?: SanitySchemaLink['pageReference'] | Page
	}
	handleCloseMenu?: MouseEventHandler<HTMLElement>
}

export const SanityLink: FunctionComponent<SanityLinkProps> = ({
	link: baseLink,
	handleCloseMenu,
	children,
	className,
	useActive = false
}) => {
	const { externalUrl, openInNewWindow, style = 'unstyled' } = baseLink
	const { asPath } = useRouter()
	const [isActive, setActive] = useState(false)

	// console.log('reference', baseLink.pageReference)

	const page = usePage(baseLink.pageReference)
	const link = {
		...baseLink,
		pageReference: page ?? undefined
	}

	const url = externalUrl ?? getLinkPath(link)
	const text = children ?? getLinkText(link)

	// useRouter values undefined on server render so update it once page is rendered on client
	useEffect(() => {
		const updateActiveStatus = () => setActive(useActive && asPath === url)
		updateActiveStatus()
		window?.addEventListener('load', updateActiveStatus)
		return () => {
			window?.removeEventListener('load', updateActiveStatus)
		}
	}, [asPath, url, useActive])

	const targetOptions = openInNewWindow ? { target: '_blank', rel: 'noopener noreferrer nofollow' } : {}

	const linkProps = {
		'className': cn({ addCount: getLinkText(link)?.toLowerCase().includes('career') }, `link--${style}`, className),
		'onClick': handleCloseMenu,
		'aria-current': isActive ? 'page' : undefined,
		...targetOptions
	} as const

	if (url) {
		switch (style) {
			case 'button':
			case 'secondaryButton':
			case 'outline':
				return (
					<Button
						to={url}
						variant={styleToVariant(style)}
						{...linkProps}
						className={`${linkProps.className} !inline-flex !w-auto`}
					>
						{text}
					</Button>
				)
			case 'gray':
				return (
					<Button
						to={url}
						variant="primary"
						{...linkProps}
						className={`${linkProps.className} !inline-flex !w-auto !bg-[#F0F0F5] !text-[#1F1F21]`}
					>
						{text}
						<V2.ChevronRightLineIcon className="!fill-none !stroke-[#1F1F21]" />
					</Button>
				)

			case 'customButton':
				return (
					<Button
						to={url}
						variant={styleToVariant(style)}
						style={{
							...(link.customButtonStyle?.color
								? {
										color: link.customButtonStyle.color
								  }
								: {}),
							...(link.customButtonStyle?.backgroundColor
								? {
										backgroundColor: link.customButtonStyle.backgroundColor
								  }
								: {})
						}}
						{...linkProps}
						className={`${linkProps.className} !inline-flex !w-auto`}
					>
						{text}
					</Button>
				)

			case 'link':
				return (
					<Link href={url} passHref prefetch={false}>
						<a
							{...linkProps}
							className={cn(
								'cursor-pointer whitespace-normal border-b-2 border-b-transparent leading-tight underline hover:underline',
								{ 'border-current': isActive },
								linkProps.className
							)}
						>
							{text}
						</a>
					</Link>
				)

			case 'unstyled':
				return (
					<Link href={url} passHref prefetch={false}>
						<a
							{...linkProps}
							className={cn(
								'border-b-2 border-b-transparent',
								{ 'border-current': isActive },
								linkProps.className
							)}
						>
							{text}
						</a>
					</Link>
				)

			case 'image':
				return (
					<Link href={url} passHref prefetch={false}>
						{link.image && (
							<a {...linkProps}>
								<ResponsiveImage layout="fill" height="1em" image={link.image} alt={link.text} />
							</a>
						)}
					</Link>
				)
		}
	} else {
		return null
	}
}

const styleToVariant = (style: SanitySchemaLink['style']): ButtonProps['variant'] => {
	switch (style) {
		case 'button':
			return 'primary'
		case 'secondaryButton':
			return 'default'
		case 'customButton':
			return 'default'
		case 'outline':
			return 'outlined'
		default:
			return undefined
	}
}

export const StyledSanityLink = styled(SanityLink)`
	&:not(.link--button, .link--secondaryButton, .link--outline, .link--customButton) {
		font-size: inherit;
	}
	&.link--button,
	&.link--secondaryButton,
	&.link--outline,
	&.link--customButton {
		${HeaderButtonCss};
		@media (max-width: ${screenSizes.small}) {
			font-size: 15px;
			padding: 1rem 1.75rem;
		}
	}
	&.link--secondaryButton {
		color: var(--text-inverted);
		background-color: var(--background-inverted);
	}
	&.link--outline {
		color: inherit;
	}
`

export default SanityLink
