import type { FunctionComponent } from 'react'
import type { Card as CardType, Cards as CardsProps } from '~/types'
import { cn, ThemeProvider, useTheme } from '~/utils'
import { ResponsiveImage } from '../Image'
import { PortableText } from './PortableText'

export const Card: FunctionComponent<CardType> = ({ _type, content, image }) => {
	const theme = useTheme()
	return (
		<div
			className={cn(
				'grid grid-rows-[min(17.5rem),min(15rem)] items-center rounded-2xl drop-shadow-md sm:drop-shadow-xl',
				'dark:bg-gray-900 dark:text-white',
				{ 'bg-white': theme.current === 'white', 'bg-light': theme.current === 'light' }
			)}
		>
			{image && (
				<div className="h-full w-full overflow-hidden rounded-t-2xl sm:rounded-b-2xl">
					<ResponsiveImage
						image={image}
						layout="fill"
						className="h-full w-full object-cover object-left-top"
					/>
				</div>
			)}
			<div className="px-6 text-left text-base">
				<div>
					<PortableText blocks={content} />
				</div>
			</div>
		</div>
	)
}

export const Cards: FunctionComponent<CardsProps> = ({ _type, theme: override, list = [] }) => {
	const theme = useTheme(override)
	return (
		<ThemeProvider theme={theme}>
			<div className="-mx-mobile grid gap-8 sm:mx-auto sm:auto-cols-fr sm:grid-flow-col" data-type={_type}>
				{list.map((card) => (
					<Card key={card._key} {...card} />
				))}
			</div>
		</ThemeProvider>
	)
}
