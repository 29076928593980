import { V2 } from '@nomonosound/gravity'
import dynamic from 'next/dynamic'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import AddToCartButton from '~/components/AddToCartButton'
import { Badge } from '~/components/Badge'
import { Button } from '~/components/Button'
import { Heading, Paragraph, getParagraphClasses } from '~/components/FontStyles'
import { Gallery } from '~/components/Gallery'
import { PricingText } from '~/components/PricingText'
import { ProductDocument } from '~/queries'
import { MediaType, ProductBlock as ProductBlockProps, SegmentEvent } from '~/types'
import {
	cn,
	displayPrice,
	getCookie,
	getPriceDetails,
	resolve,
	toAnalyticsProduct,
	track,
	useCountry,
	useCoupon,
	useGQLQuery,
	useMarket,
	useProduct
} from '~/utils'
import { IdContext } from '../IdContext'
import { Section } from '../Section'

// disable ssr for PortableText due to hydration errorsß
const PortableText = dynamic(async () => (await import('~/components/blocks')).PortableText, { ssr: false })

const StyledSection = styled.section`
	div[data-name='portable-accordion-wrapper'] {
		background-color: #1f1f210a !important;
	}
`

const StyledIcon = styled(V2.TruckFilledIcon)`
	path {
		fill: currentColor !important;
	}
`

const US_LINK =
	'https://www.amazon.com/dp/B0CKRYX38Z?maas=maas_adg_FD9FEE963CB808E82D4838DA1BD5AA0F_afap_abs&ref_=aa_maas&tag=maas'
const UK_LINK =
	'https://www.amazon.co.uk/Nomono-Sound-Capsule-High-Definition-Microphones/dp/B0CKRYX38Z?maas=maas_adg_4CB3BC1D563D43725688AB39B8071482_afap_abs&ref_=aa_maas&tag=maas'

export const ProductBlock: FunctionComponent<ProductBlockProps> = (props) => {
	const sectionId = useContext(IdContext)
	const [quantity] = useState(1)
	const market = useMarket()
	const [country] = useCountry()
	const parentProduct = useProduct()
	const coupon = useCoupon()
	const blockProduct = resolve(props.product)
	const { data, errors } = useGQLQuery(
		ProductDocument,
		{ productId: blockProduct?._id ?? '', marketID: market?._id ?? '' },
		{ enabled: market !== null && blockProduct !== null }
	)
	const product = blockProduct && data ? data : parentProduct

	useEffect(() => {
		if (product) track(SegmentEvent.ProductViewed, toAnalyticsProduct(product))
	}, [product])

	const details = product ? getPriceDetails({ product, quantity }, coupon ? [coupon] : [], market) : null

	const SavingsBadge = () =>
		details?.savings ? (
			<Badge size="md" className={cn(getParagraphClasses('overlineSm'), 'ml-2')}>
				Save {coupon?.amount_off ? displayPrice(details, 'savings', market) : `${coupon?.percent_off}%`}
			</Badge>
		) : null

	return (
		<>
			<StyledSection
				id={sectionId}
				className="mx-auto grid max-w-7xl grid-flow-row grid-cols-1 gap-6 md:grid-cols-[minmax(0,6fr),minmax(0,7fr)] md:py-4 lg:gap-16"
			>
				<span className="sr-only">{product?.title}</span>
				<div className="text-center md:hidden">
					<SavingsBadge />
				</div>
				<PortableText blocks={props.title} className="col-span-1 -mt-4 text-center text-base md:hidden" />
				<PortableText blocks={props.heading} className="col-span-1 -my-4 text-center text-base md:hidden" />
				<div>
					{product?.images && (
						<Gallery
							className="bg-overlay rounded-lg"
							mode="inline"
							images={product?.images as MediaType[]}
						/>
					)}
				</div>
				<div>
					<div className="grid grid-flow-row gap-y-6 rounded-2xl md:min-w-[320px] md:gap-y-4">
						<div
							style={{
								gridTemplateColumns: '4fr 1fr',
								gridGap: '1rem'
							}}
							className="hidden text-base md:grid"
						>
							<div>
								<PortableText wrapper={false} blocks={props.title} />
							</div>
							<div className="relative mt-4">
								<SavingsBadge />
							</div>
						</div>
						<div className="hidden text-base md:block">
							<PortableText wrapper={false} blocks={props.heading} />
						</div>
						{errors?.length ? (
							errors.map((err, i) => (
								<p key={i} className="text-xl">
									{err.message}
								</p>
							))
						) : (
							<>
								<div className="mt-2 space-y-2 md:order-2 md:mt-4">
									{details && (
										<>
											<div className="xs:text-3xl text-2xl sm:text-4xl">
												<Heading className="whitespace-nowrap" type="headingMd">
													<span className="whitespace-nowrap">
														{displayPrice(details, 'subtotal', market)}
													</span>
													<span className="whitespace-nowrap"> </span>
													{details.savings > 0 && (
														<s className="whitespace-nowrap text-gray-500">
															{displayPrice(details, 'compareAtPrice', market)}
														</s>
													)}
												</Heading>
											</div>
											<div className="text-gray-600">
												<PricingText prices={details} content={props.pricingDetails} />
											</div>
										</>
									)}
									<hr className="mb-32" />
									<div className="flex flex-wrap-reverse items-start justify-between">
										<h4 className="whitespace-nowrap font-medium text-gray-700 lg:text-xl">
											<Paragraph type="textSm">
												{details?.taxBehavior === 'inclusive'
													? props.includingTaxesText
													: props.excludingTaxesText}
											</Paragraph>
										</h4>
										{props.offers?.map((offer) => (
											<Badge size="md" key={offer}>
												{offer}
											</Badge>
										))}
									</div>
								</div>
								<div className="my-2 flex flex-row justify-start md:order-2 md:my-4">
									<AddToCartButton className="!h-14" />
									{country && ['US', 'GB'].includes(country.code) && !getCookie('cje') && (
										<Button
											target="_blank"
											className="!mt-0 ml-2 !h-14 !w-48 !bg-[#1F1F2114] !px-7 !text-base"
											to={country.code === 'US' ? US_LINK : UK_LINK}
											onClick={() => {
												track(SegmentEvent.AmazonLinkClicked, {
													country: country.code,
													link: country.code === 'US' ? US_LINK : UK_LINK,
													page: window.location.href
												})
											}}
										>
											<svg
												width="22"
												height="22"
												viewBox="0 0 22 22"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M12.9467 9.29235C12.9467 10.4262 12.9753 11.3719 12.4022 12.3788C11.9396 13.1976 11.2069 13.7011 10.3882 13.7011C9.27058 13.7011 8.61971 12.8496 8.61971 11.5929C8.61971 9.11218 10.8425 8.66194 12.9467 8.66194V9.29235ZM15.8898 16.3947C15.6974 16.5665 15.4109 16.5707 15.194 16.456C14.2279 15.6537 14.056 15.2812 13.5238 14.5157C11.9273 16.145 10.7975 16.632 8.72619 16.632C6.27823 16.632 4.37061 15.1215 4.37061 12.0964C4.37061 9.73448 5.65196 8.12567 7.47348 7.33978C9.05365 6.64379 11.2601 6.521 12.9467 6.32867V5.95203C12.9467 5.26019 12.9998 4.4415 12.5946 3.84388C12.2384 3.30762 11.5589 3.08655 10.9612 3.08655C9.85189 3.08655 8.86123 3.65552 8.61971 4.83445C8.57053 5.0965 8.3782 5.35441 8.11623 5.36667L5.29163 5.0638C5.05426 5.01048 4.79229 4.81814 4.85778 4.45376C5.50864 1.03164 8.59918 0 11.3664 0C12.7828 0 14.633 0.37664 15.7506 1.44918C17.167 2.77135 17.0319 4.53564 17.0319 6.45551V10.9912C17.0319 12.3543 17.5968 12.9519 18.129 13.6888C18.3172 13.9508 18.3582 14.2661 18.1208 14.4625C17.5272 14.9577 16.471 15.8788 15.8898 16.3947Z"
													style={{ fill: '#232F3E' }}
												/>
												<path
													d="M19.0511 18.11C9.97072 22.4314 4.33534 18.8158 0.727964 16.6197C0.50474 16.4813 0.125338 16.6521 0.454522 17.0302C1.65632 18.4874 5.59487 21.9997 10.7358 21.9997C15.8803 21.9997 18.9408 19.1926 19.3237 18.7029C19.7039 18.2174 19.4354 17.9496 19.0511 18.11ZM21.6013 16.7016C21.3574 16.3841 20.1185 16.3249 19.3388 16.4206C18.558 16.5137 17.3858 16.9909 17.4877 17.2775C17.54 17.3848 17.6468 17.3367 18.1832 17.2884C18.7211 17.2348 20.228 17.0446 20.542 17.455C20.8575 17.8684 20.0614 19.8372 19.916 20.1548C19.7755 20.4723 19.9697 20.5542 20.2336 20.3427C20.4938 20.1313 20.9649 19.5838 21.2811 18.809C21.5951 18.0301 21.7866 16.9434 21.6013 16.7016Z"
													style={{ fill: '#FF9900' }}
												/>
											</svg>
											Go to Amazon
										</Button>
									)}
								</div>
								<PortableText
									blocks={props.content}
									className="text-base text-gray-900 text-opacity-60 md:order-4"
								/>

								{details && props.productInfo && (
									<PortableText className="order-5 mt-2 md:mt-4" blocks={props.productInfo} />
								)}
							</>
						)}
					</div>
				</div>
			</StyledSection>
			<Section
				pageWidth="full"
				className="!mt-6 md:!mt-12"
				style={{
					backgroundColor: '#FFFEFD',
					paddingTop: 0,
					paddingBottom: 0
				}}
			>
				<div className="flex flex-col items-center justify-center whitespace-pre-wrap py-6 lg:flex-row lg:py-0">
					<Paragraph type="textMd">
						{country?.name ? (
							<>
								Free express shipping to <b>{country.name}</b>
							</>
						) : (
							<>Free express shipping</>
						)}
					</Paragraph>
					<span className="m-4 inline-block h-14 w-14 rounded-full bg-[#1F1F210A] md:my-6 md:mx-3">
						<StyledIcon className="relative left-4 top-4 h-6 w-6" />
					</span>
					<Paragraph type="textMd">
						<b>2-5 business days</b> delivery available
					</Paragraph>
				</div>
			</Section>
		</>
	)
}
