import { colors, screenSizes, shadows } from '@nomonosound/gravity'
import { captureException } from '@sentry/nextjs'
import type { FormEvent, FunctionComponent } from 'react'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import type { HubspotFormParameters, LegalConsentOptions } from '~/types'
import { cn, getColor, getInputBackground, identify, track, useHubspotForm, useSettings } from '~/utils'

export interface HubspotFormProps extends Partial<HubspotFormParameters> {
	/** Show privacy policy */
	showLegal?: boolean
	className?: string
	formClassName?: string
	submitText?: string
}

export const legalConsentCss = css`
	.legal-consent-container {
		color: ${getColor};
		opacity: 0.7;
		a {
			color: inherit;
			text-decoration: underline;
		}
	}
`

const FormWrapped = styled.div<{ multipleFields: boolean }>`
	margin-bottom: 2rem;
	.hs_email {
		input[type='email'] {
			width: 100%;
			min-width: 12rem;
			padding: 10px 14px;
			background-color: ${getInputBackground};
			box-shadow: ${shadows.elevation2dp};
			line-height: 24px;
			color: ${colors.gray70};
		}
	}

	@media (min-width: ${screenSizes.medium}) {
		max-width: 500px;
	}

	fieldset + fieldset {
		margin-top: 2rem;
	}

	input[type='submit'] {
		width: 100%;
		padding: 10px 1rem;
		border-radius: 0.5rem;
		border: none;
		line-height: 1.5rem;
		cursor: pointer;
		font-size: inherit;
		white-space: nowrap;
		background-color: var(--primary);
		box-shadow: ${shadows.elevation2dp};
		color: var(--text-on-light);
	}
	.hs-error-msg {
		font-size: 1rem;
		color: ${colors.red50};
		display: block;
	}
	// Text input
	.hs-fieldtype-text,
	.hs-fieldtype-textarea {
		input,
		textarea {
			padding: 10px 1rem;
			background-color: ${getInputBackground};
			color: ${getColor};
			@media (max-width: ${screenSizes.small}) {
				font-size: 1rem;
			}
			&.error {
				border-color: ${colors.red50};
			}
			&:focus {
				outline: 2px solid var(--primary);
				border: none;
			}
		}
	}
	${legalConsentCss}
`

export const HubspotForm: FunctionComponent<HubspotFormProps> = ({
	className,
	formClassName,
	showLegal = true,
	...props
}) => {
	// Get default settings from siteSettings and use those if values are not set
	const [message, setMessage] = useState<string | null>(null)
	const { siteSettings } = useSettings()

	const { region, formId, submitText, style = 'signup' } = { ...siteSettings?.hubspotNewsletterForm, ...props }
	const { form: settings, isLoading } = useHubspotForm(formId)
	if (!settings) {
		if (isLoading) return null

		throw new Error(`Could not find form with formId ${formId}`)
	}
	const { portalId, formFieldGroups, submitText: hsSubmitText } = settings
	const legalConsent: LegalConsentOptions | undefined = JSON.parse(
		settings.metaData.find((meta) => meta.name === 'legalConsentOptions')?.value as string
	)

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		setMessage(null)
		const form = e.target as HTMLFormElement
		const data = Object.fromEntries(new FormData(form))
		identify(data)
		track('Newsletter Signup', data)
		track(settings.guid as any, data)

		fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`, {
			method: form.method,
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				fields: Object.entries(data).map(([name, value]) => ({
					objectTypeId: formFieldGroups.flatMap((fg) => fg.fields).find((field) => field.name === name)
						?.objectTypeId,
					name,
					value
				})),
				context: {
					hutk: document.cookie
						.split(';')
						.find((c) => c.trim().startsWith('hubspotutk'))
						?.split('=')[1]
				},
				legalConsentOptions: {
					consent: {
						consentToProcess: true,
						text: legalConsent?.privacyPolicyText,
						communications: legalConsent?.communicationConsentCheckboxes.map((ccc) => ({
							value: true,
							subscriptionTypeId: ccc.communicationTypeId,
							text: ccc.label
						}))
					}
				}
			})
		})
			.then((res) => res.ok && setMessage(settings.inlineMessage))
			.catch((err: Error | null) => {
				captureException(err)
				setMessage(err?.message || 'An unknown error occurred. Please try again.')
			})
	}

	return (
		<FormWrapped className={`${className} style--${style}`} multipleFields>
			<form
				acceptCharset="UTF-8"
				action={`https://forms-${region}.hsforms.com/submissions/v3/public/submit/formsnext/multipart/${portalId}/${formId}`}
				encType="multipart/form-data"
				method="POST"
				className={cn(settings.cssClass, formClassName)}
				onSubmit={handleSubmit}
			>
				{formFieldGroups.flatMap((fieldset, i) => (
					<fieldset key={i} className={`form-columns-${i} ${fieldset.fields.length > 1 && 'flex'} space-x-8`}>
						{fieldset.fields.map((field, j) => {
							const Element = field.fieldType === 'textarea' ? 'textarea' : 'input'
							return (
								<div
									key={j}
									className={`hs_${field.name} hs-fieldtype-${field.fieldType} hs-form-field flex-auto`}
								>
									{field.label && (
										<label
											className="block pb-2 text-xl"
											htmlFor={field.name + j}
											hidden={field.labelHidden}
										>
											{field.label}
										</label>
									)}
									{field.description && (
										<legend className="hs-field-desc">{field.description}</legend>
									)}
									<Element
										id={field.name + j}
										className="hs-input w-full rounded-lg border border-gray-300"
										type={field.name === 'email' ? 'email' : field.fieldType}
										name={field.name}
										required={field.required}
										placeholder={field.placeholder}
										autoComplete="on"
									/>
								</div>
							)
						})}
					</fieldset>
				))}

				{legalConsent && showLegal && (
					<div
						className="legal-consent-container mt-1 text-sm"
						dangerouslySetInnerHTML={{ __html: legalConsent?.privacyPolicyText }}
					/>
				)}
				<div className="hs_submit hs-submit">
					<div className="actions">
						<input type="submit" value={submitText || hsSubmitText || 'Submit'} className="hs-button" />
					</div>
				</div>
			</form>
			{message && <p>{message}</p>}
		</FormWrapped>
	)
}
