import algoliasearch from 'algoliasearch'
import { FunctionComponent, useEffect, useState } from 'react'
import { Configure, InstantSearch, SearchBox } from 'react-instantsearch-hooks-web'
import type { Search as SearchProps } from '~/types'
import { SearchResults } from './SearchResults'

const searchClient = algoliasearch(process.env.NEXT_PUBLIC_ALGOLIA_APP_ID, process.env.NEXT_PUBLIC_ALGOLIA_KEY)

export const Search: FunctionComponent<SearchProps> = () => {
	const [open, setOpen] = useState(false)
	useEffect(() => {
		if (!open) return
		const close = () => setOpen(false)
		window.addEventListener('click', close)
		return () => document.removeEventListener('click', close)
	}, [open])
	return (
		<InstantSearch
			searchClient={{
				...searchClient,
				search(queries) {
					// Don't show results if the query is empty
					if (queries.every(({ params }) => !params?.query)) {
						return Promise.resolve({
							results: queries.map(() => ({
								hits: [],
								nbHits: 0,
								nbPages: 0,
								page: 0,
								processingTimeMS: 0,
								hitsPerPage: 0,
								exhaustiveNbHits: false,
								query: '',
								params: ''
							}))
						})
					}
					return searchClient.search(queries)
				}
			}}
			indexName={process.env.NEXT_PUBLIC_ALGOLIA_INDEX}
		>
			<Configure hitsPerPage={8} />
			<div className="relative inline-block w-full max-w-3xl" onClick={(e) => e.stopPropagation()}>
				<SearchBox
					classNames={{
						root: 'relative z-20 flex h-10 w-full items-center rounded-2xl bg-white text-gray-800',
						form: 'flex w-full h-full px-4',
						submit: 'order-1 mr-4',
						reset: 'hidden',
						loadingIcon: 'hidden',
						input: 'w-full h-full outline-none order-2'
					}}
					onChangeCapture={() => setOpen(true)}
				/>
				{open && (
					<SearchResults className="absolute left-0 top-6 z-10 w-full rounded-b-2xl bg-white p-4 text-left text-black" />
				)}
			</div>
		</InstantSearch>
	)
}
