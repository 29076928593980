import { PortableText } from '@portabletext/react'
import type { FC } from 'react'
import type { Nullable, PriceDetails, PricingText as PricingTextContent } from '~/types'
import { getPriceDetails, useCoupon, useMarket, useProduct } from '~/utils'
import { serializers as defaultSerializers } from '../blocks/PortableText/serializers'
import type { CustomMark, Serializers } from '../blocks/PortableText/types'
import { VariableMark, VariableMarkProps } from './Mark'

export const serializers: (prices: PriceDetails | null) => Serializers = (prices) => ({
	...defaultSerializers,
	marks: {
		...defaultSerializers.marks,
		variable: (props) => (
			<VariableMark {...props} markType={props.markType as CustomMark['_type']} prices={prices} />
		)
	}
})

interface BlockProps extends Partial<Pick<VariableMarkProps, 'prices'>> {
	content: Nullable<PricingTextContent>
}
export const PricingText: FC<BlockProps> = ({ content, ...props }) => {
	const product = useProduct()
	const market = useMarket()
	const coupon = useCoupon()
	const prices =
		props.prices ?? (product ? getPriceDetails({ product, quantity: 1 }, coupon ? [coupon] : [], market) : null)
	if (!content) return null
	return <PortableText value={content} components={serializers(prices)} />
}
