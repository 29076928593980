import { useRef, useState } from 'react'
import type { VideoGallery as VideoGalleryProps } from '~/types'
import { Heading, Paragraph } from './FontStyles'
import { EmbeddedVideo } from './blocks'
import { Container } from './layout/Containers'

export const VideoGallery: React.FC<VideoGalleryProps> = ({ videos, title, subtitle, showMoreText, showLessText }) => {
	const [expanded, setExpanded] = useState(false)
	const [firstVideo, ...hiddenVideos] = videos
	const showLessScrollTargetRef = useRef<HTMLDivElement>(null)
	const showMoreScrollTargetRef = useRef<HTMLDivElement>(null)

	if (!firstVideo) return null

	const ShowMore = () => {
		if (!showMoreText || expanded) return null

		return (
			<div
				onClick={() => {
					setExpanded(true)
					showMoreScrollTargetRef.current?.scrollIntoView({
						behavior: 'smooth'
					})
				}}
				className="cursor-pointer py-6"
			>
				<Paragraph type="bodySm">{showMoreText}</Paragraph>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fillOpacity={0}
					xmlns="http://www.w3.org/2000/svg"
					className="my-4 inline"
				>
					<path
						d="M6 9L12 15L18 9"
						stroke="#1F1F21"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</div>
		)
	}

	const ShowLess = () => {
		if (!showLessText || !expanded) return null

		return (
			<div
				onClick={() => {
					setExpanded(false)
					showLessScrollTargetRef.current?.scrollIntoView({ behavior: 'smooth' })
				}}
				className="cursor-pointer py-6"
			>
				<Paragraph type="bodySm">{showLessText}</Paragraph>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fillOpacity={0}
					xmlns="http://www.w3.org/2000/svg"
					className="my-4 inline"
				>
					<path
						d="M6 15 L12 9 L18 15"
						stroke="#1F1F21"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</div>
		)
	}

	return (
		<div ref={showLessScrollTargetRef}>
			{(title || subtitle) && (
				<Container pageWidth="small" className="pt-8 text-center">
					{title && <Heading type="displaySm">{title}</Heading>}
					<div className="py-2" />
					{subtitle && <Paragraph type="bodyMd">{subtitle}</Paragraph>}
				</Container>
			)}
			<Container pageWidth="standard" className="text-center">
				<div className="py-8">
					<EmbeddedVideo {...firstVideo} />
				</div>

				<div ref={showMoreScrollTargetRef} />
				<ShowMore />
				<ShowLess />

				{expanded &&
					hiddenVideos.map((video) => {
						return (
							<div className="py-6" key={video._key}>
								<EmbeddedVideo {...video} />
							</div>
						)
					})}
				<ShowLess />
			</Container>
		</div>
	)
}
