import type { FunctionComponent } from 'react'
import { isPresent } from 'ts-extras'
import type { FaqArticle as FaqArticleProps } from '~/types'
import { resolve, useMarket } from '~/utils'
import { Heading } from '../FontStyles'
import { PortableText } from './PortableText'

export const FaqArticle: FunctionComponent<FaqArticleProps> = (props) => {
	const market = useMarket()
	const faqGroup = resolve(props.faqGroup)
	if (!isPresent(faqGroup)) return null
	return (
		<article className="space-y-12">
			{faqGroup.questions
				?.map(resolve)
				.filter(isPresent)
				.filter((question) =>
					market && question.markets ? question.markets.some((m) => m._ref === market._id) : true
				)
				.map((question, i) => (
					<div key={i}>
						<Heading type="displayXs">{question.title}</Heading>
						<PortableText blocks={question.content} />
					</div>
				))}
		</article>
	)
}
