import type { FunctionComponent } from 'react'
import type { HTMLElementProps } from '~/types'
import { cn } from '~/utils'

export interface BadgeProps extends HTMLElementProps<'span'> {
	size?: 'sm' | 'md' | 'lg'
}

export const Badge: FunctionComponent<BadgeProps> = ({ className, size = 'md', children, ...props }) => (
	<span
		className={cn(
			'bg-brand-200 inline-block whitespace-nowrap rounded-full text-black',
			getSizeClassNames(size),
			className
		)}
		{...props}
	>
		{children}
	</span>
)

function getSizeClassNames(size: BadgeProps['size']) {
	switch (size) {
		case 'sm':
			return 'px-2 py-0.5 text-sm'
		case 'md':
			return 'py-1 px-3 text-sm'
		case 'lg':
			return 'py-2 px-4 text-base'
		case undefined:
			return undefined
	}
}
