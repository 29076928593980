/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import type { ImageFormat, SanityImageSource } from '@sanity/image-url/lib/types/types'
import { UseNextSanityImageBuilder, useNextSanityImage } from 'next-sanity-image'
import type { ImageProps } from 'next/image'
import NextImage from 'next/image'
import { FunctionComponent, useRef, useState } from 'react'
import styled from 'styled-components'
import type { Except } from 'type-fest'
import { getClient, imageBuilder, useIsomorphicEffect } from '~/utils'

export interface ResponsiveImageProps extends Except<ImageProps, 'src' | 'layout'> {
	image: SanityImageSource
	format?: ImageFormat
	quality?: number
	layout?: ImageProps['layout'] | 'full' | 'auto'
	/** Use a normal img element instead of NextJS' image wrapper */
	native?: boolean
}

interface ImageWrapperProps extends Except<ResponsiveImageProps, 'layout'> {
	builder?: UseNextSanityImageBuilder
	layout: ImageProps['layout']
	/** Use a normal img element instead of NextJS' image wrapper */
	native?: boolean
}

export const SanityImage: FunctionComponent<ImageWrapperProps> = ({ image, objectFit, native, builder, ...props }) => {
	const imageProps = useNextSanityImage(getClient(), image, {
		imageBuilder: builder ?? ((b) => b.quality(props.quality ?? 80))
	})
	return imageProps?.src ? (
		native ? (
			<img
				src={imageProps.src}
				{...props}
				width={props.width || 'auto'}
				style={{
					// Would not get applied units we not added for some reason
					maxHeight:
						Number.parseInt(props.height as string) == props.height ? `${props.height}px` : props.height
				}}
				loading="lazy"
			/>
		) : (
			<NextImage {...imageProps} {...props} objectFit={objectFit} unoptimized loading="lazy" />
		)
	) : (
		<div className="text-center italic">Image not found</div>
	)
}

const Container = styled.span<{ loaded: boolean }>`
	display: ${(p) => (p.loaded ? 'contents' : 'block')};
	width: 100%;
	height: 100%;
`

const getQuality = (quality: number) => {
	return Math.min(quality, 75) // limit the maximum quality so we don't get large download sizes
}

export const ResponsiveImage: FunctionComponent<ResponsiveImageProps> = ({
	image,
	format = 'webp',
	layout = 'responsive',
	placeholder = 'empty',
	quality: qualityProp = 80,
	...props
}) => {
	const quality = getQuality(qualityProp)
	const ref = useRef<HTMLImageElement | null>(null)
	const [{ conf }, setBuilder] = useState<{ conf: UseNextSanityImageBuilder | null }>({ conf: null })

	// Wait until layout is calculated to set image sizes
	useIsomorphicEffect(() => {
		const mult = 1.3 * window.devicePixelRatio // Slightly upscale images
		const imageEl = ref.current
		if (!imageEl) return
		const { clientWidth: width, clientHeight: height } = imageEl

		switch (layout) {
			case 'fill':
			case 'responsive':
				if (width > 30 && height > 30) {
					setBuilder({
						conf: (builder, _) =>
							builder
								.height(Math.round(height * mult))
								.width(Math.round(width * mult))
								.crop('center')
					})
					break
				} else if (width > 30) {
					setBuilder({
						conf: (builder, _) => builder.width(Math.round(width * mult))
					})
					break
				} else if (height > 30) {
					setBuilder({
						conf: (builder, _) => builder.width(Math.round(height * mult)).height(Math.round(height * mult))
					})
					break
				} // Intentional fallthrough

			case 'full':
				if (window?.innerWidth) {
					setBuilder({ conf: (builder, _) => builder.width(Math.round(window.innerWidth * mult)) })
					break
				} // Intentional fallthrough
		}
	}, [layout])

	return layout === 'auto' ? (
		<SanityImage layout="intrinsic" quality={quality} image={image} placeholder={placeholder} {...props} />
	) : (
		<Container ref={ref} loaded={conf !== null} data-layout={layout}>
			{ref.current && conf && (
				<SanityImage
					image={image}
					native={layout === 'fill'}
					builder={(b, o) => conf(b, o).quality(quality).format(format)}
					placeholder={placeholder}
					layout={layout === 'full' ? 'fixed' : layout}
					objectFit="contain"
					{...props}
				/>
			)}
		</Container>
	)
}

export interface ImageUrlOptions {
	quality?: number
	format?: ImageFormat
}

export function imageUrl(image: ResponsiveImageProps['image'], options: ImageUrlOptions = {}) {
	const { quality: qualityProp = 80, format = 'webp' } = options
	const quality = getQuality(qualityProp)

	const wdw = typeof window !== 'undefined' ? window : { devicePixelRatio: 1, innerWidth: 1920 }
	// Slightly upscale images
	const mult =
		(quality === 100 ? 1 : 1.3) *
		(typeof window !== 'undefined' && wdw.innerWidth > 1000 ? Math.max(wdw.devicePixelRatio, 1.5) : 1)

	const builder = imageBuilder(image)
		.width(Math.round(wdw.innerWidth * mult))
		.format(format)
		.quality(quality)

	return builder.url()
}
