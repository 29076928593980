import type { FunctionComponent } from 'react'
import type { InnerSection as InnerSectionProps } from '~/types'
import { PortableText } from './PortableText'
import { Section } from './Section'

export const InnerSection: FunctionComponent<InnerSectionProps> = (props) => {
	return (
		<Section
			isInnerSection={true}
			className="rounded-2xl"
			{...props.settings}
			pageWidth={props.settings?.pageWidth ?? 'none'}
			style={{
				width: 'auto',
				display: 'flex',
				alignItems: props.verticalAlign,
				...(props.position?.type
					? {
							position: props.position.type,
							top: props.position.top,
							bottom: props.position.bottom
					  }
					: {})
			}}
		>
			<PortableText blocks={props.content} />
		</Section>
	)
}
