import type { FunctionComponent, SVGProps } from 'react'

export const Logo: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 520 80" {...props}>
			<path
				fill="currentColor"
				d="M2.18 10.92C0 15.2 0 20.8 0 32v48h15V31c0-5.6 0-8.4 1.09-10.54a10 10 0 014.37-4.37C22.6 15 25.4 15 31 15h8c5.6 0 8.4 0 10.54 1.09a10 10 0 014.37 4.37C55 22.6 55 25.4 55 31v49h15V32c0-11.2 0-16.8-2.18-21.08a20 20 0 00-8.74-8.74C54.8 0 49.2 0 38 0h-6C20.8 0 15.2 0 10.92 2.18a20 20 0 00-8.74 8.74z"
			/>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M87.18 10.92C85 15.2 85 20.8 85 32v16c0 11.2 0 16.8 2.18 21.08a20 20 0 008.74 8.74C100.2 80 105.8 80 117 80h6c11.2 0 16.8 0 21.08-2.18a20 20 0 008.74-8.74C155 64.8 155 59.2 155 48V32c0-11.2 0-16.8-2.18-21.08a20 20 0 00-8.74-8.74C139.8 0 134.2 0 123 0h-6c-11.2 0-16.8 0-21.08 2.18a20 20 0 00-8.74 8.74zm13.91 9.54C100 22.6 100 25.4 100 31v18c0 5.6 0 8.4 1.09 10.54a10 10 0 004.37 4.37C107.6 65 110.4 65 116 65h8c5.6 0 8.4 0 10.54-1.09a10 10 0 004.37-4.37C140 57.4 140 54.6 140 49V31c0-5.6 0-8.4-1.09-10.54a10 10 0 00-4.37-4.37C132.4 15 129.6 15 124 15h-8c-5.6 0-8.4 0-10.54 1.09a10 10 0 00-4.37 4.37z"
				clipRule="evenodd"
			/>
			<path
				fill="currentColor"
				d="M170 27.5c0-6.98 0-10.47.86-13.3A20 20 0 01184.2.85C187.03 0 190.52 0 197.5 0s10.47 0 13.3.86c2.48.75 4.74 1.96 6.7 3.53a19.99 19.99 0 016.7-3.53C227.02 0 230.51 0 237.5 0s10.47 0 13.3.86a20 20 0 0113.34 13.33c.86 2.84.86 6.33.86 13.31V80h-15V27.5c0-2.32 0-3.48-.2-4.45a10 10 0 00-7.85-7.86C241 15 239.82 15 237.5 15c-2.32 0-3.49 0-4.45.2a10 10 0 00-7.86 7.85c-.19.97-.19 2.13-.19 4.45V80h-15V27.5c0-2.32 0-3.48-.2-4.45a10 10 0 00-7.85-7.86C201 15 199.82 15 197.5 15c-2.32 0-3.49 0-4.45.2a10 10 0 00-7.86 7.85c-.19.97-.19 2.13-.19 4.45V80h-15V27.5z"
			/>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M282.18 10.92C280 15.2 280 20.8 280 32v16c0 11.2 0 16.8 2.18 21.08a20 20 0 008.74 8.74C295.2 80 300.8 80 312 80h6c11.2 0 16.8 0 21.08-2.18a20 20 0 008.74-8.74C350 64.8 350 59.2 350 48V32c0-11.2 0-16.8-2.18-21.08a20 20 0 00-8.74-8.74C334.8 0 329.2 0 318 0h-6c-11.2 0-16.8 0-21.08 2.18a20 20 0 00-8.74 8.74zm13.91 9.54C295 22.6 295 25.4 295 31v18c0 5.6 0 8.4 1.09 10.54a10 10 0 004.37 4.37C302.6 65 305.4 65 311 65h8c5.6 0 8.4 0 10.54-1.09a10 10 0 004.37-4.37C335 57.4 335 54.6 335 49V31c0-5.6 0-8.4-1.09-10.54a10 10 0 00-4.37-4.37C327.4 15 324.6 15 319 15h-8c-5.6 0-8.4 0-10.54 1.09a10 10 0 00-4.37 4.37z"
				clipRule="evenodd"
			/>
			<path
				fill="currentColor"
				d="M367.18 10.92C365 15.2 365 20.8 365 32v48h15V31c0-5.6 0-8.4 1.09-10.54a10 10 0 014.37-4.37C387.6 15 390.4 15 396 15h8c5.6 0 8.4 0 10.54 1.09a10 10 0 014.37 4.37C420 22.6 420 25.4 420 31v49h15V32c0-11.2 0-16.8-2.18-21.08a20 20 0 00-8.74-8.74C419.8 0 414.2 0 403 0h-6c-11.2 0-16.8 0-21.08 2.18a20 20 0 00-8.74 8.74z"
			/>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M452.18 10.92C450 15.2 450 20.8 450 32v16c0 11.2 0 16.8 2.18 21.08a20 20 0 008.74 8.74C465.2 80 470.8 80 482 80h6c11.2 0 16.8 0 21.08-2.18a20 20 0 008.74-8.74C520 64.8 520 59.2 520 48V32c0-11.2 0-16.8-2.18-21.08a20 20 0 00-8.74-8.74C504.8 0 499.2 0 488 0h-6c-11.2 0-16.8 0-21.08 2.18a20 20 0 00-8.74 8.74zm13.91 9.54C465 22.6 465 25.4 465 31v18c0 5.6 0 8.4 1.09 10.54a10 10 0 004.37 4.37C472.6 65 475.4 65 481 65h8c5.6 0 8.4 0 10.54-1.09a10 10 0 004.37-4.37C505 57.4 505 54.6 505 49V31c0-5.6 0-8.4-1.09-10.54a10 10 0 00-4.37-4.37C497.4 15 494.6 15 489 15h-8c-5.6 0-8.4 0-10.54 1.09a10 10 0 00-4.37 4.37z"
				clipRule="evenodd"
			/>
		</svg>
	)
}
