import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FunctionComponent, useState } from 'react'
import Countdown, { type CountdownRendererFn } from 'react-countdown'
import { Paragraph } from '~/components/FontStyles'
import { MarketsDocument, MeDocument, ValidateCouponsDocument } from '~/queries'
import { CountryCode, Page } from '~/types'
import { MarketPriceDetails, displaySimplePrice, getColor, getCookie, getMarket, useGQLQuery, useMarket } from '~/utils'

const Banner = dynamic(async () => (await import('../Banner')).Banner, {
	ssr: true
})

export const CouponBanner: FunctionComponent<{ page?: Page }> = ({ page }) => {
	const router = useRouter()
	const code = getCookie('ref')
	let market = useMarket()

	const markets = useGQLQuery(MarketsDocument, undefined, {
		enabled: !!code && !market && !router.asPath.includes('checkout')
	})
	const me = useGQLQuery(MeDocument, undefined, { enabled: !!code && !market && !router.asPath.includes('checkout') })

	market = market ?? getMarket(markets.data || [], me.data?.geo?.country as CountryCode)

	// used if the coupon expires while the banner is visible
	const [expired, setExpired] = useState(false)

	const coupons = useGQLQuery(
		ValidateCouponsDocument,
		{ codes: [code as string], market: market?._id },
		{ enabled: !!code && !router.asPath.includes('checkout') }
	)

	const coupon = coupons.data?.[0]

	if (expired || !coupon || (coupon.redeem_by && coupon.redeem_by * 1000 < Date.now())) {
		if (getCookie('travel') && location && location.pathname !== '/checkout') {
			return (
				<div className="bg-brand-200 p-4 text-center" style={{ color: getColor({ theme: 'light' }) }}>
					<Paragraph type="textMd" className="mt-1">
						<a
							href="/traveldiscount"
							className="cursor-pointer whitespace-normal border-b-2 border-b-transparent leading-tight !underline hover:underline"
						>
							Subscribe to our newsletter and receive 10% off →
						</a>
					</Paragraph>
				</div>
			)
		}

		return <Banner page={page} />
	}

	const shownDiscount = !coupon?.amount_off
		? `${coupon?.percent_off}%`
		: market
		? displaySimplePrice(
				(coupon?.currency_options?.[mapMarketNameToCurrency[market?.name]]?.amount_off || coupon.amount_off) /
					100,
				{ currency: '', ...market }
		  )
		: coupon?.currency_options?.nok.amount_off
		? displaySimplePrice(coupon?.currency_options?.nok.amount_off / 100, {
				thousandSeparator: ' ',
				currencySymbol: ' kr'
		  } as MarketPriceDetails)
		: null

	const renderer: CountdownRendererFn = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			return null
		}

		return (
			<span>
				{days}d {hours}h {minutes}m {seconds}s
			</span>
		)
	}

	return (
		<div className="bg-brand-200 p-4 text-center" style={{ color: getColor({ theme: 'light' }) }}>
			<Paragraph type="overlineSm" className="md:text-2">
				{coupon.name}
			</Paragraph>
			<Paragraph type="textMd" className="mt-1">
				<strong>Save {shownDiscount}</strong> on Sound Capsule.{' '}
				{coupon.redeem_by && (
					<>
						Ends in{' '}
						<strong>
							<Countdown
								date={coupon.redeem_by * 1000}
								renderer={renderer}
								onComplete={() => setExpired(true)}
							/>
						</strong>
					</>
				)}
			</Paragraph>
		</div>
	)
}

const mapMarketNameToCurrency: Record<string, string> = {
	'UK': 'gbp',
	'North America': 'usd',
	'European Union': 'eur',
	'Germany': 'eur',
	'Norway': 'nok',
	'Sweden': 'sek'
}
