import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { cn, useProduct } from '~/utils'
import { Actions } from '~/utils/redux/actions'
import { Button } from './Button'

interface Props {
	text?: string
	className?: string
}

const AddToCartButton: FC<Props> = ({ text, className }) => {
	const product = useProduct()
	const dispatch = useDispatch()

	return (
		<Button
			className={cn('!px-7 !text-base sm:mt-0', className)}
			style={{ minWidth: 'unset', height: 'var(--sizes-5)' }}
			disabled={product === null}
			onClick={() =>
				product ? dispatch(Actions.addToCart({ id: product._id, product, quantity: 1 }, true)) : undefined
			}
		>
			{text ?? 'Order now'}
		</Button>
	)
}

export default AddToCartButton
