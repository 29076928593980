import MuxPlayer from '@mux/mux-player-react/lazy'
import { colors } from '@nomonosound/gravity'
import Hls from 'hls.js'
import type { FunctionComponent } from 'react'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Except } from 'type-fest'
import { Pause, Play } from '~/components/gfx'
import type { EmbeddedVideo, VideoAsset } from '~/types'
import { getClient, useIsomorphicEffect } from '~/utils'

const VideoWrapper = styled.figure`
	position: relative;
	margin: 0;

	video {
		display: block;
		width: 100%;
		border-radius: 1rem;
		overflow: hidden;
	}
`

const Controls = styled.div`
	position: absolute;
	pointer-events: none;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	> * {
		pointer-events: all;
	}

	button {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	progress {
		display: none; // TODO: Use own component instead of native (Why?)
		position: absolute;
		left: 1rem;
		bottom: 1.125rem;
		height: 1.125rem;
		width: calc(100% - 2rem);

		color: ${colors.blue40};
	}
`

const PlaybackButton = styled.button<{ isPlaying: boolean }>`
	border: none;
	border-radius: 9999px;
	font-size: 1.5rem;
	color: ${colors.white};
	background: var(--fill-primary);
	height: 4.25rem;
	width: 4.25rem;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;

	transition: all 0.25s ease-out;
	height: 5rem;
	width: 5rem;

	opacity: ${(p) => (p.isPlaying ? 0 : 1)};
	&:hover {
		opacity: ${(p) => (p.isPlaying ? 1 : 0.9)};
	}
`

type ListenerMap<K extends WindowEventMap | HTMLElementEventMap> = Array<[keyof K, (ev: K[keyof K]) => void]>

export interface VideoPlayerProps extends Except<EmbeddedVideo, 'video' | '_type'> {
	video: VideoAsset
}

interface MuxPlayerProps {
	video: VideoAsset
	autoPlay?: boolean
	muted?: boolean
	loop?: boolean
	playsInline?: boolean
	className?: string
	style?: React.CSSProperties
	aspectRatio?: string
	thumbnailTime?: number
	onError?: () => void
	onAbort?: () => void
}

export const MuxVideoPlayer: FunctionComponent<MuxPlayerProps> = ({
	video,
	aspectRatio,
	thumbnailTime,
	onError,
	onAbort,
	...props
}) => {
	const [videoId, setVideoId] = useState<string>('')

	useIsomorphicEffect(() => {
		const fetchVideoId = async () => {
			if (!video.asset?._ref) {
				return
			}
			try {
				const res = await getClient().fetch(`*[_id == "${video.asset?._ref}"] { playbackId }`)
				setVideoId(res[0].playbackId)
			} catch (e) {
				console.error(e)
				onError?.()
			}
		}
		fetchVideoId()
	}, [video.asset?._ref, onError])

	const style: Record<string, string> = {
		'--seek-backward-button': 'none',
		'--seek-forward-button': 'none',
		'--media-object-fit': 'cover',
		'aspectRatio': aspectRatio ?? '16/9'
	}

	if (aspectRatio === '9/16') {
		style['--media-object-fit'] = 'contain'
		style['--media-object-position'] = 'center'
		style.maxHeight = '85vh'
		style.maxWidth = '46vh'
	}

	return (
		<MuxPlayer
			streamType="on-demand"
			playbackId={videoId}
			metadata={{
				video_id: videoId
			}}
			thumbnailTime={thumbnailTime || 0}
			{...props}
			style={style}
			onError={onError}
			onAbort={onAbort}
		/>
	)
}

const CustomVideoPlayer: FunctionComponent<VideoPlayerProps> = ({ video, player = 'native', ...props }) => {
	const videoRef = useRef<HTMLVideoElement>(null)
	const progressRef = useRef<HTMLProgressElement>(null)
	const [playing, setPlaying] = useState(false)
	const videoId = video?.asset?.data?.playback_ids?.[0]?.id

	const useCustomControls = props.useCustomControls ?? player === 'custom'

	useEffect(() => {
		if (!videoId) return
		const videoElem = videoRef.current
		let hls: Hls

		if (videoElem) {
			const src = `https://stream.mux.com/${videoId}.m3u8`
			if (videoElem.canPlayType('application/vnd.apple.mpegurl')) {
				videoElem.src = src
			} else if (Hls.isSupported()) {
				hls = new Hls()
				hls.loadSource(src)
				hls.attachMedia(videoElem)
			} else {
				console.error('This is a legacy browser that doesnt support MSE')
			}
		}

		return () => {
			if (hls) {
				hls.destroy()
			}
		}
	}, [videoRef, videoId, player])

	useEffect(() => {
		const int = setInterval(() => {
			if (progressRef.current && videoRef.current) {
				progressRef.current.value = Math.round(videoRef.current.currentTime || 0)
			}
		}, 1000 / 60)
		if (!playing) clearInterval(int)
		return () => {
			clearInterval(int)
		}
	}, [playing, player])

	useEffect(() => {
		const ref = videoRef.current
		if (!ref) return
		const listeners: ListenerMap<HTMLVideoElementEventMap> = [
			['playing', () => setPlaying(true)],
			['pause', () => setPlaying(false)]
		]
		listeners.forEach(([event, func]) => ref.addEventListener(event, func))
		return () => {
			listeners.forEach(([event, func]) => ref.removeEventListener(event, func))
		}
	}, [player])

	return (
		<VideoWrapper>
			<video controls={!useCustomControls} ref={videoRef} />
			{useCustomControls && (
				<Controls className="controls">
					<PlaybackButton
						isPlaying={playing}
						onClick={(_) => (playing ? videoRef.current?.pause() : videoRef.current?.play())}
					>
						{playing ? (
							<Pause />
						) : (
							<div className="pl-[0.2rem]">
								<Play />
							</div>
						)}
					</PlaybackButton>
					<progress
						ref={progressRef}
						role="progressbar"
						id="progress"
						max={videoRef.current?.duration ? videoRef.current?.duration : undefined}
						value={0}
					>
						Progress
					</progress>
				</Controls>
			)}
		</VideoWrapper>
	)
}

export const VideoPlayer: FunctionComponent<VideoPlayerProps> = ({
	video,
	player = 'native',
	thumbnailTime,
	...props
}) => {
	if (player === 'mux') {
		return <MuxVideoPlayer video={video} aspectRatio={props.aspectRatio} thumbnailTime={thumbnailTime} />
	}

	return <CustomVideoPlayer video={video} player={player} {...props} />
}
