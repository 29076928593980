import { V2 } from '@nomonosound/gravity'
import { getFileAsset } from '@sanity/asset-utils'
import type { FileAsset } from '@sanity/types'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import WaveSurfer from 'wavesurfer.js'
import { Soundwaves } from '~/components/gfx'
import { AudioPlayerV3 as AudioPlayerV3Props, SanityReference } from '~/types'
import { SanityConfig, cn } from '~/utils'

export const AudioPlayerV3: FunctionComponent<AudioPlayerV3Props> = ({ audioFile }) => {
	const fileAsset = getFileAsset(audioFile?.asset as SanityReference<FileAsset>, SanityConfig)

	const containerRef = useRef<HTMLDivElement>(null)
	const waveSurferRef = useRef<WaveSurfer | null>(null)
	const [isPlaying, setIsPlaying] = useState(false)
	const [isPlayerReady, setIsPlayerReady] = useState(false)

	useEffect(() => {
		if (!containerRef.current) {
			return
		}

		const waveSurfer = WaveSurfer.create({
			container: containerRef.current,
			barWidth: 2,
			dragToSeek: true,
			progressColor: '#FFE46C',
			cursorColor: 'white'
		})
		waveSurfer.load(fileAsset.url)
		waveSurfer.on('ready', () => {
			waveSurferRef.current = waveSurfer
			setIsPlayerReady(true)
		})

		return () => {
			waveSurfer.destroy()
		}
	}, [fileAsset.url])

	return (
		<div className="flex items-center space-x-4">
			{/* Play button */}
			<div className="mb-0">
				<button
					style={{ visibility: isPlayerReady ? 'visible' : 'hidden' }}
					className={cn(
						{ 'fill:fill-current': isPlaying },
						{ 'fill:fill-brand-200': !isPlaying },
						{ 'fill:fill-disabled': !isPlayerReady },
						'h-14 w-14'
					)}
					aria-label={`${isPlaying ? 'Pause' : 'Play'}`}
					onClick={() => {
						if (isPlaying) {
							waveSurferRef.current?.pause()
							setIsPlaying(false)
						} else {
							waveSurferRef.current?.play()
							setIsPlaying(true)
						}
					}}
				>
					{isPlaying ? <V2.PauseCircleFilledIcon /> : <V2.PlayCircleFilledIcon />}
				</button>
			</div>

			{/* Waveforms */}
			<div ref={containerRef} className="flex-1" style={{ display: isPlayerReady ? 'initial' : 'none' }} />

			<div className="flex-1" style={{ display: isPlayerReady ? 'none' : 'initial' }}>
				<div className="flex h-[128px] max-w-md flex-col items-center justify-center gap-2">
					<Soundwaves />
					<AnimatedDots>Loading player</AnimatedDots>
				</div>
			</div>
		</div>
	)
}

const AnimatedDots = styled.div`
	:after {
		content: ' .';
		animation: dots 1s steps(5, end) infinite;
	}

	@keyframes dots {
		0%,
		20% {
			color: rgba(0, 0, 0, 0);
			text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
		}
		40% {
			color: white;
			text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
		}
		60% {
			text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
		}
		80%,
		100% {
			text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
		}
	}
`
