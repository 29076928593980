import { colors, screenSizes, size } from '@nomonosound/gravity'
import { FunctionComponent, useContext } from 'react'
import styled from 'styled-components'
import { H2 } from '~/components/GeneralStyles'
import type { Salepoints as SalepointsProps } from '~/types'
import { DefaultImage } from '../Image'
import { SanityLink } from '../SanityLink'
import { IdContext } from './IdContext'

const SalepointsWrapper = styled.div`
	${H2}:empty {
		display: none;
	}
	${H2} {
		margin-bottom: 3rem;
	}
	@media (min-width: ${screenSizes.small}) {
		background-color: ${colors.white};
		padding: ${size.xxLarge};
		border-radius: 1rem;
		color: ${colors.black};
	}
	margin-bottom: ${size.xxxxLarge};
	text-align: center;
`

const SalepointsStyled = styled.div`
	display: grid;
	grid-gap: 2rem;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	/* border: 2px solid red; */

	p {
		color: ${colors.gray70};
		line-height: 1.5;
	}
`

const H3 = styled.h3`
	font-size: 1.25rem; // 20px
	margin-bottom: 0.25rem; // 10px
`

const Salepoint = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: ${colors.white};
	color: ${colors.black};
	border-radius: 1rem;
	padding: ${size.xxLarge};

	p {
		margin-top: 10px;
	}

	@media (min-width: ${screenSizes.small}) {
		padding: 0;
		background-color: unset;
	}
	.icon {
		margin: 0 auto;
		width: 2rem;
		height: 2rem;
		margin-bottom: 0.25rem;
	}
`

const LinkWrapper = styled.div`
	margin-top: auto;
	margin-left: auto;
	margin-right: auto;
`

export const Salepoints: FunctionComponent<SalepointsProps> = (props) => {
	const id = useContext(IdContext)
	return (
		<SalepointsWrapper id={id}>
			<H2>{props.title}</H2>
			<SalepointsStyled>
				{props.points?.map((point) => {
					const { _key, title, icon, description, link } = point
					if (!title) return
					return (
						<Salepoint key={_key}>
							<div className="icon">
								{icon && title && (
									<DefaultImage image={icon} alt={title} placeholder={false} unoptimized priority />
								)}
							</div>
							<H3>{title}</H3>
							<p>{description}</p>
							<LinkWrapper>{link && <SanityLink link={link} />}</LinkWrapper>
						</Salepoint>
					)
				})}
			</SalepointsStyled>
		</SalepointsWrapper>
	)
}

export default Salepoints
