import { colors, screenSizes, size } from '@nomonosound/gravity'
import type { FunctionComponent } from 'react'
import { useContext } from 'react'
import styled from 'styled-components'
import type { HubspotFormProps } from '~/components/HubspotForm'
import { HubspotForm } from '~/components/HubspotForm'
import { H2, gradientButtonCss } from '../GeneralStyles'
import { IdContext } from './IdContext'
import { Breakout } from '../layout/Containers'

interface NewsletterSignupProps {
	title?: string
	description?: string
	form: HubspotFormProps | undefined
}

const Form = styled(HubspotForm)`
	form {
		margin: 0 auto;
		text-align: left;
		max-width: 24rem;
		width: 100%;
		text-align: center;
	}

	.hs_email {
		label {
			display: none;
		}
		input[type='email'] {
			font-size: 1.125rem;
			appearance: none;
			border: 1px solid ${colors.gray50};
			padding: 1rem;
			border-radius: 1rem;
			background-color: transparent;
			width: 100%;
			color: inherit;

			@media (max-width: ${screenSizes.small}) {
				font-size: 1rem;
			}
		}
		margin: 1rem;
	}

	.legal-consent-container {
		text-align: left;
		margin: 0 1rem;
		ul {
			margin: 0;
			padding: 0;
			li {
				display: block;
				label {
					input {
						display: inline-block;
					}
					p {
						display: inline-block;
					}
				}
			}
		}
	}

	.actions {
		display: flex;
		justify-content: center;
		margin: 1rem;
		/* Extracted from @nomonosound/gravity */
		input[type='submit'] {
			${gradientButtonCss}
			width: 100%;
		}
	}

	.submitted-message {
		font-size: ${size.xxLarge};
		color: inherit;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 3rem;
	}
`

export const NewsletterSignup: FunctionComponent<NewsletterSignupProps> = ({ title, description, form }) => {
	const id = useContext(IdContext)
	// needs to be in function to allow Breakout to be used TODO: not really true
	const StyledNewsletterSignup = styled(Breakout)`
		margin-top: 2rem;
		margin-bottom: 2rem;
		padding: 2rem 0;
		display: flex;
		align-items: center;
		justify-content: center;

		.hs-form-required {
			display: none;
		}

		.top {
			max-width: 24rem;
			text-align: center;
			margin: 0 auto;
		}

		> div {
			margin: 1rem;
		}

		.hs-form-booleancheckbox-display p {
			padding-left: 0.5em;
		}

		.hs-error-msgs {
			text-align: left;
			color: ${colors.red50};
			label {
				display: block;
			}
			padding-bottom: 1rem;
		}
	`

	return (
		<StyledNewsletterSignup id={id}>
			<div>
				<div className="top">
					<H2>{title || 'Signup for newsletter'}</H2>
					{description && <p>{description}</p>}
				</div>
				<Form submitText="Sign up" {...form} />
			</div>
		</StyledNewsletterSignup>
	)
}
