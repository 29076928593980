import Head from 'next/head'
import type { FunctionComponent, PropsWithChildren } from 'react'
import type { BlogPost, Page } from '~/types'
import { BASE_URL, getPageMeta, imageBuilder } from '~/utils'

export interface MetaProps extends PropsWithChildren {
	page: Page | BlogPost | null
}

export const HeadWithMeta: FunctionComponent<MetaProps> = ({ page, children }) => {
	const meta = getPageMeta(page)
	const lastUpdated = page?._updatedAt || page?._createdAt
	return (
		<Head>
			<title key="htmlTitle">{[meta.title, 'Nomono'].filter((s) => !!s).join(' | ')}</title>
			{meta.noIndex === true ? (
				<meta key="robots" name="robots" content="noindex" />
			) : (
				<>
					{meta.description && <meta key="description" property="description" content={meta.description} />}
					{meta.title && <meta key="title" name="title" property="og:title" content={meta.title} />}
					{meta.image && (
						<meta
							key="image"
							name="image"
							property="og:image"
							content={imageBuilder(meta.image).height(630).width(1200).fit('clip').url()}
						/>
					)}
					{meta.canonical && (
						<link
							key="canonical"
							rel="canonical"
							href={[BASE_URL, meta.canonical].filter(Boolean).join('/').replace('//', '/')}
						/>
					)}
					{lastUpdated && (
						<meta key="last-modified" httpEquiv="last-modified" content={lastUpdated.substring(0, 10)} />
					)}
				</>
			)}
			{children}
		</Head>
	)
}
