import { screenSizes } from '@nomonosound/gravity'
import type { FunctionComponent } from 'react'
import styled from 'styled-components'
import { CountrySelector } from '~/components/CountrySelector'
import { SanityLink } from '~/components/SanityLink'
import { SocialIcons } from '~/components/SocialIcons'
import type { CheckoutSettings, SiteSettings } from '~/types'
import { BannerItem } from './BannerItem'
import { MenuButton } from './MenuButton'

export const NavLink = styled(SanityLink)`
	&:not(.link--image) {
		display: none;
		@media (min-width: ${screenSizes.small}) {
			display: inline;
		}
		&:hover {
			text-decoration: none;
		}
	}
`

export interface HeaderSectionProps {
	items: CheckoutSettings['headerItems'] | SiteSettings['headerItems'] | SiteSettings['footerBottom']
	className?: string
}

export const HeaderSection: FunctionComponent<HeaderSectionProps> = ({ className, items }) =>
	items ? (
		<div className={['flex items-center space-x-6', className].join(' ')}>
			{items.map((item) =>
				item._type === 'link' ? (
					<NavLink key={item._key} link={item} useActive className="leading-none" />
				) : item._type === 'headerMenu' ? (
					<MenuButton key={item._key} {...item} />
				) : item._type === 'countrySelector' ? (
					<CountrySelector key={item._key} {...item} />
				) : item._type === 'bannerItem' ? (
					<BannerItem key={item._key} {...item} />
				) : item._type === 'socialIcons' ? (
					<SocialIcons key={item._key} />
				) : null
			)}
		</div>
	) : null
