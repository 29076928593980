import { V2 } from '@nomonosound/gravity'
import { FunctionComponent } from 'react'
import { objectKeys } from 'ts-extras'
import type { Nullable, PaymentIcons as PaymentIconsType, PaymentMethod } from '~/types'
import { getSafariVersion, useCountry, useMarket } from '~/utils'
import { specialKlarnaCountries, unavailableKlarnaCountries } from '~/utils/klarna'
import { KlarnaPlacement } from './KlarnaPlacement'

const _allMethods: Record<PaymentMethod, true> = {
	applePay: true,
	googlePay: true,
	amex: true,
	visa: true,
	klarna: true,
	mastercard: true,
	paypal: true
}
const allMethods = objectKeys(_allMethods)

export interface PaymentIconsProps extends PaymentIconsType {
	hide?: PaymentMethod[]
}

function shouldShow(method: PaymentMethod, methods: PaymentMethod[], hide: Nullable<PaymentMethod[]>): boolean {
	if (hide?.includes(method)) return false
	if (!methods.includes(method)) return false
	if (method === 'applePay' && getSafariVersion() === null) return false
	return true
}

export const PaymentIcons: FunctionComponent<PaymentIconsProps> = ({ hide, showKlarnaText = false }) => {
	const market = useMarket()
	const [country] = useCountry()
	const methods = market?.paymentMethods ?? allMethods

	return (
		<div className="svg:h-7 lg:justify-normal flex flex-wrap items-center justify-center gap-2">
			{shouldShow('mastercard', methods, hide) && <V2.MastercardPaymentMethodsIcon />}
			{shouldShow('visa', methods, hide) && <V2.VisaPaymentMethodsIcon />}
			{shouldShow('googlePay', methods, hide) && <V2.GooglepayPaymentMethodsIcon />}
			{shouldShow('applePay', methods, hide) && <V2.ApplepayPaymentMethodsIcon />}
			{shouldShow('paypal', methods, hide) && <V2.PaypalPaymentMethodsIcon />}
			{shouldShow('amex', methods, hide) && <V2.AmexPaymentMethodsIcon />}
			<InvoiceIcon />
			{shouldShow('klarna', methods, hide) &&
				country?.code &&
				!unavailableKlarnaCountries.includes(country.code) &&
				(showKlarnaText && !specialKlarnaCountries.includes(country.code) ? (
					<KlarnaPlacement />
				) : (
					<V2.KlarnaPaymentMethodsIcon />
				))}
		</div>
	)
}

const InvoiceIcon = () => (
	<svg viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="34" height="24" rx="4" fill="white" />
		<rect x="0.5" y="0.5" width="33" height="23" rx="3.5" stroke="#1F1F21" strokeOpacity="0.08" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.133 10.3845V15H21.2805V10.3845H20.133ZM20.711 8.61649C20.337 8.61649 20.0225 8.87149 20.0225 9.25399C20.0225 9.63649 20.337 9.88299 20.711 9.88299C21.085 9.88299 21.3995 9.63649 21.3995 9.25399C21.3995 8.87149 21.085 8.61649 20.711 8.61649ZM24.2723 15.102C22.8613 15.102 21.8838 14.116 21.8838 12.6795C21.8838 11.2685 22.8613 10.291 24.2638 10.291C25.4028 10.291 26.2358 10.9285 26.4398 11.9825H25.2753C25.1818 11.5575 24.7908 11.294 24.2723 11.294C23.5073 11.294 23.0143 11.8465 23.0143 12.6795C23.0143 13.5295 23.5073 14.0905 24.2723 14.0905C24.7908 14.0905 25.1733 13.8185 25.2753 13.3935H26.4398C26.2358 14.456 25.4028 15.102 24.2723 15.102ZM29.0123 15.102C27.4908 15.102 26.6323 14.014 26.6323 12.688C26.6323 11.3535 27.5163 10.291 28.9783 10.291C30.2448 10.291 31.2648 11.0985 31.2648 12.6285C31.2648 12.6874 31.2624 12.7366 31.2602 12.7826L31.2602 12.7826C31.2582 12.823 31.2563 12.8608 31.2563 12.9005H27.7543C27.8053 13.657 28.3068 14.1075 29.0123 14.1075C29.4798 14.1075 29.8453 13.9035 29.9898 13.5125H31.1628C30.9333 14.4305 30.2023 15.102 29.0123 15.102ZM27.8053 12.0675H30.0918C29.9643 11.5065 29.5563 11.2175 28.9698 11.2175C28.3748 11.2175 27.9583 11.5235 27.8053 12.0675ZM17.1495 15.102C15.73 15.102 14.7695 14.1415 14.7695 12.6795C14.7695 11.226 15.73 10.291 17.141 10.291C18.5605 10.291 19.521 11.226 19.521 12.6795C19.521 14.15 18.569 15.102 17.1495 15.102ZM15.9 12.671C15.9 13.5465 16.376 14.0905 17.141 14.0905C17.906 14.0905 18.382 13.5465 18.382 12.671C18.382 11.8295 17.906 11.294 17.141 11.294C16.376 11.294 15.9 11.8295 15.9 12.671ZM10.2305 10.3845L11.9305 15H13.129L14.829 10.3845H13.6305L12.5255 13.6825L11.429 10.3845H10.2305ZM5.67188 15V10.3845H6.61537L6.74287 10.9285C7.11687 10.478 7.62687 10.2995 8.22188 10.2995C9.29287 10.2995 9.98138 10.8775 9.98138 12.093V15H8.83387V12.4075C8.83387 11.6935 8.52787 11.3025 7.85637 11.3025C7.15088 11.3025 6.81937 11.702 6.81937 12.5095V15H5.67188ZM3.29346 8.75249V15H4.50896V8.75249H3.29346Z"
			fill="black"
		/>
	</svg>
)
