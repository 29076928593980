import { isObject } from 'lodash'
import type { FunctionComponent } from 'react'
import { isPresent } from 'ts-extras'
import type { DefaultPortableText, FaqCollection, Nullable, Theme } from '~/types'
import { getBackgroundColor, resolve, ThemeProvider, useMarket, useTheme } from '~/utils'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, PortableText } from './blocks'
import { Heading } from './FontStyles'

export interface FaqGroupProps {
	title?: string | DefaultPortableText
	faq: Nullable<FaqCollection>
	theme?: Theme
}

export const FaqGroup: FunctionComponent<FaqGroupProps> = ({ title, faq, theme: override }) => {
	const theme = useTheme(override)
	const market = useMarket()

	if (!isPresent(faq)) return null
	return (
		<ThemeProvider theme={theme}>
			<div
				className="space-y-6 rounded-2xl p-4 sm:p-6 md:p-9"
				style={{ background: getBackgroundColor({ theme }) }}
			>
				{isObject(title) ? (
					<PortableText blocks={title} />
				) : (
					<Heading type="displayXs">{title || faq.title}</Heading>
				)}
				<Accordion type="multiple">
					{faq.questions
						?.map(resolve)
						.filter(isPresent)
						.filter((question) =>
							market && question.markets ? question.markets.some((m) => m._ref === market._id) : true
						)
						.map((question, i) => (
							<AccordionItem key={i} value={question._id}>
								<AccordionTrigger>{question.title}</AccordionTrigger>
								<AccordionContent>
									<PortableText blocks={question.content} />
								</AccordionContent>
							</AccordionItem>
						))}
				</Accordion>
			</div>
		</ThemeProvider>
	)
}
