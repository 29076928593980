import { FunctionComponent, useEffect, useRef, useState } from 'react'
import type { HTMLElementProps, MediaType } from '~/types'
import { cn } from '~/utils'
import { imageUrl } from '../Image'
import { Slideshow } from './Slideshow'

type Props = HTMLElementProps<
	'div',
	{
		images: MediaType[]
		mode?: 'inline'
		thumbnails?: boolean
	}
>

export const Gallery: FunctionComponent<Props> = ({
	className,
	images,
	mode = 'dialog',
	thumbnails = true,
	...props
}) => {
	const itemsRef = useRef<Array<HTMLButtonElement | null>>([])
	const [inlineIndex, __setInlineIndex] = useState<number>(0)
	const setInlineIndex = (n: number) => {
		const index = n >= images.length ? 0 : n < 0 ? images.length - 1 : n
		__setInlineIndex(index)

		// do not scroll on load, when both index and inlineIndex are 0
		if (index !== 0 || inlineIndex !== 0) {
			itemsRef.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
		}
	}

	useEffect(() => {
		itemsRef.current = itemsRef.current?.slice(0, images.length)
	}, [images])

	return images.length ? (
		<>
			<Slideshow
				{...props}
				className={cn('cursor-pointer overflow-hidden', className)}
				onClick={(_) => setInlineIndex(inlineIndex + 1)}
				onUpdate={setInlineIndex}
				index={inlineIndex}
				background="cover"
				images={images}
			/>
			{thumbnails && images.length > 0 && (
				<div className="mt-4 flex max-w-full gap-4 overflow-x-auto">
					{images.map((media, i) =>
						mode == 'dialog' && i === 0 ? null : ( // Hide cover image from list when in dialog mode
							<button
								key={i}
								ref={(el) => (itemsRef.current[i] = el)}
								className={cn(
									'button bg-overlay h-[4.5rem] w-[4.5rem] min-w-[4.5rem] rounded bg-cover bg-center md:h-[5.5rem] md:w-[5.5rem] md:min-w-[5.5rem]',
									{
										'border-2 border-solid border-[#A3A29F]': inlineIndex === i
									}
								)}
								style={{ backgroundImage: `url(${imageUrl(media)})` }}
								title={'alt' in media ? media.alt : undefined}
								onClick={(_) => setInlineIndex(i)}
							/>
						)
					)}
				</div>
			)}
		</>
	) : null
}
