import type { FunctionComponent } from 'react'
import { useContext } from 'react'
import styled from 'styled-components'
import type { EmbeddedVideo as EmbeddedVideoProps } from '~/types'
import { useWindowSize } from '~/utils'
import { Container as BaseContainer, Breakout } from '../../layout/Containers'
import { IdContext } from '../IdContext'
import { EmbeddedVideo } from './EmbeddedVideo'

const Container = styled(BaseContainer)`
	> * {
		width: 100%;
	}
`

export const EmbeddedVideoSection: FunctionComponent<EmbeddedVideoProps> = (props) => {
	const id = useContext(IdContext)
	const { bodyWidth } = useWindowSize()

	return (
		<section id={id}>
			{/* workaround to avoid unnecessary horizontal scrollbar */}
			<Breakout style={{ ...(bodyWidth ? { width: `${bodyWidth}px` } : {}) }}>
				<Container>
					<EmbeddedVideo {...props} />
				</Container>
			</Breakout>
		</section>
	)
}
