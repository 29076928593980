import { V2 } from '@nomonosound/gravity'
import { Accordion, AccordionHeader, AccordionItem, AccordionTrigger } from '@radix-ui/react-accordion'
import { FunctionComponent, useState } from 'react'
import { Except } from 'type-fest'
import { PricingText } from '~/components/PricingText'
import type { HTMLElementProps, IconItem as IconItemType, IconList as IconListType } from '~/types'
import { cn } from '~/utils'
import { StyledContent } from '../TechSpecs'
import { PortableText } from './PortableText'

export interface IcomItemProps extends IconItemType, Except<HTMLElementProps<'li'>, 'title'> {
	compact?: boolean
}

export const IconItem: FunctionComponent<IcomItemProps> = ({
	className,
	title,
	subtitle,
	icon,
	compact,
	dropdownContent,
	...props
}) => {
	const [expanded, setExpanded] = useState(false)

	return dropdownContent ? (
		<Accordion type="multiple" onValueChange={([v]) => setExpanded(v === '0')} value={expanded ? ['0'] : []}>
			<AccordionItem value="0">
				<AccordionHeader asChild>
					<AccordionTrigger asChild>
						<div className="flex items-center space-x-2 text-base">
							{icon && <V2.Icon className="!h-6" icon={icon} />}
							<span>{typeof title === 'string' ? title : <PricingText content={title} />}</span>
							<span
								className={cn('svg:h-4 fill:stroke-current inline-flex text-xl transition-transform', {
									'rotate-90': expanded
								})}
							>
								<V2.ChevronRightLineIcon />
							</span>
						</div>
					</AccordionTrigger>
				</AccordionHeader>
				<StyledContent>
					<PortableText className="pt-2" blocks={dropdownContent} />
				</StyledContent>
			</AccordionItem>
		</Accordion>
	) : (
		<li
			className={cn(
				'grid grid-cols-[auto,1fr] grid-rows-[auto,auto] items-center gap-x-3 text-left',
				{ 'gap-y-2 gap-x-6 text-xl': !compact },
				className
			)}
			{...props}
		>
			<div className="col-start-1 row-span-2 self-start">{icon && <V2.Icon icon={icon} />}</div>
			{subtitle ? (
				<>
					<div className="col-start-2 row-start-1">
						{typeof title === 'string' ? title : <PricingText content={title} />}
					</div>
					<div className="col-start-2 row-start-2 text-base">{subtitle}</div>
				</>
			) : (
				<div className="col-start-2 row-span-2">
					{typeof title === 'string' ? title : <PricingText content={title} />}
				</div>
			)}
		</li>
	)
}

export const IconList: FunctionComponent<IconListType> = ({ list, compact = false }) => (
	<ul className={cn('!pl-0', compact ? 'space-y-3' : 'space-y-9')}>
		{list?.map((item) => (
			<IconItem key={item._key} {...item} compact={compact} />
		))}
	</ul>
)
