/* eslint-disable react/jsx-no-target-blank */
import { groupBy } from 'lodash'
import type { FunctionComponent, PropsWithChildren } from 'react'
import { useEffect, useState } from 'react'
import type { CheckoutSettings, Page, SiteSettings, Theme } from '~/types'
import { ThemeProvider, useIsomorphicEffect, useSettings, useTheme } from '~/utils'
import { Container } from '../Containers'
import { StyledHeader } from './containers'
import { CouponBanner } from './CouponBanner'
import { HeaderSection } from './HeaderSection'

let resizeTimer: any
const stopTransitions = () => {
	document.body.classList.add('resize-animation-stopper')
	clearTimeout(resizeTimer)
	resizeTimer = setTimeout(() => {
		document.body.classList.remove('resize-animation-stopper')
	}, 400)
}

export interface HeaderProps extends PropsWithChildren {
	noMargin?: boolean
	theme?: Theme
	page?: Page
	showCountrySelector?: boolean
	headerItems?: CheckoutSettings['headerItems'] | SiteSettings['headerItems']
}

export const Header: FunctionComponent<HeaderProps> = ({
	noMargin,
	children,
	theme: themeOverride,
	headerItems,
	page
}) => {
	const [isOnTop, setIsOnTop] = useState(true)
	const settings = useSettings().siteSettings
	const showHeroTheme =
		settings.heroHeaderTheme && page && 'enableHeroHeaderTheme' in page && page.enableHeroHeaderTheme
	const headerTheme = isOnTop && showHeroTheme ? settings.heroHeaderTheme : settings.headerTheme
	const theme = useTheme({ current: themeOverride ?? settings.theme, settings: headerTheme ?? null })
	const headerHeight = useHeaderHeight()

	const handleScroll = (event: any) => {
		const scrollTop = event?.target?.documentElement?.scrollTop
		if (scrollTop > 0) {
			setIsOnTop(false)
		} else {
			setIsOnTop(true)
		}
	}

	useEffect(() => {
		if (window) {
			window.addEventListener('scroll', handleScroll, { passive: true })
			window.addEventListener('resize', stopTransitions, { passive: true })
		}
		return () => {
			window.removeEventListener('scroll', handleScroll)
			window.removeEventListener('resize', stopTransitions)
		}
	}, [])

	const desktop = groupBy(headerItems, (item) => item.headerGroup)
	const mobile = groupBy(headerItems, (item) =>
		'mobileHeaderGroup' in item ? item.mobileHeaderGroup : item.headerGroup
	)

	return (
		<ThemeProvider theme={theme}>
			<StyledHeader id="nomono-header" className="transition-colors" data-is-on-top={isOnTop && !showHeroTheme}>
				<CouponBanner page={page} />
				{children}
				<Container>
					<nav className="grid grid-cols-[auto,auto,auto] py-5 lg:grid-cols-3">
						<HeaderSection items={mobile.left} className="col-start-1 justify-start lg:hidden" />
						<HeaderSection items={mobile.center} className="col-start-2 justify-center lg:hidden" />
						<HeaderSection items={mobile.right} className="col-start-3 justify-end lg:hidden" />
						<HeaderSection items={desktop.left} className="col-start-1 hidden justify-start lg:flex" />
						<HeaderSection items={desktop.center} className="col-start-2 hidden justify-center lg:flex" />
						<HeaderSection items={desktop.right} className="col-start-3 hidden justify-end lg:flex" />
					</nav>
				</Container>
			</StyledHeader>
			{noMargin || isOnTop || showHeroTheme ? null : <div style={{ paddingTop: `${headerHeight}px` }} />}
		</ThemeProvider>
	)
}

export const useHeaderHeight = () => {
	const [height, setHeight] = useState(0)
	useIsomorphicEffect(() => {
		const header = document.querySelector('#nomono-header')
		setHeight(header?.clientHeight ?? 0)
	}, [])
	return height
}
