import type { FunctionComponent } from 'react'

export const Soundwaves: FunctionComponent = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="246" height="30" fill="currentColor" viewBox="0 0 246 25">
			<rect width="3.8" height="20.8" x="8.7" y="3" rx="1.9" />
			<rect width="3.8" height="22.7" x="88.1" y="2" rx="1.9" />
			<rect width="3.8" height="25.5" x="35.1" y="1.1" rx="1.9" />
			<rect width="3.8" height="21.7" x="114.5" y="3" rx="1.9" />
			<rect width="3.8" height="10.4" x="141" y="8.6" rx="1.9" />
			<rect width="3.8" height="7.6" x="15.3" y="9.6" rx="1.9" />
			<rect width="3.8" height="18" x="94.7" y="4.8" rx="1.9" />
			<rect width="3.8" height="25.5" x="41.8" y="1.1" rx="1.9" />
			<rect width="3.8" height="10.4" x="121.1" y="8.6" rx="1.9" />
			<rect width="3.8" height="6.6" x="147.6" y="10.5" rx="1.9" />
			<rect width="3.8" height="6.6" x="180.7" y="10.5" rx="1.9" />
			<rect width="3.8" height="6.6" x="167.4" y="10.5" rx="1.9" />
			<rect width="3.8" height="21.7" x="200.5" y="3" rx="1.9" />
			<rect width="3.8" height="21.7" x="213.8" y="3" rx="1.9" />
			<rect width="3.8" height="3.8" x="174.1" y="11.5" rx="1.9" />
			<rect width="3.8" height="12.3" x="207.1" y="7.7" rx="1.9" />
			<rect width="3.8" height="18" x="220.4" y="4.8" rx="1.9" />
			<rect width="3.8" height="18" x="227" y="4.8" rx="1.9" />
			<rect width="3.8" height="10.4" x="233.6" y="8.6" rx="1.9" />
			<rect width="3.8" height="4.7" x="240.2" y="11.5" rx="1.9" />
			<rect width="3.8" height="7.6" x="21.9" y="9.6" rx="1.9" />
			<rect width="3.8" height="14.2" x="101.3" y="6.7" rx="1.9" />
			<rect width="3.8" height="11.3" x="48.4" y="7.7" rx="1.9" />
			<rect width="3.8" height="21.7" x="127.8" y="3" rx="1.9" />
			<rect width="3.8" height="14.2" x="74.8" y="6.7" rx="1.9" />
			<rect width="3.8" height="14.2" x="154.2" y="6.7" rx="1.9" />
			<rect width="3.8" height="14.2" x="187.3" y="6.7" rx="1.9" />
			<rect width="3.8" height="25.5" x="28.5" y="1.1" rx="1.9" />
			<rect width="3.8" height="14.2" x="107.9" y="6.7" rx="1.9" />
			<rect width="3.8" height="4.7" x="55" y="11.5" rx="1.9" />
			<rect width="3.8" height="4.7" x="61.6" y="11.5" rx="1.9" />
			<rect width="3.8" height="8.5" x="68.2" y="9.6" rx="1.9" />
			<rect width="3.8" height="14.2" x="2.1" y="6.7" rx="1.9" />
			<rect width="3.8" height="14.2" x="134.4" y="6.7" rx="1.9" />
			<rect width="3.8" height="14.2" x="81.4" y="6.7" rx="1.9" />
			<rect width="3.8" height="18" x="160.8" y="4.8" rx="1.9" />
			<rect width="3.8" height="10.4" x="193.9" y="8.6" rx="1.9" />
		</svg>
	)
}

export default Soundwaves
