import { colors, font, screenSizes } from '@nomonosound/gravity'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Arrow } from '~/components/gfx'
import type { Link as SanityLink } from '~/types'
import { resolve } from '~/utils'

const CtaLink = styled.a`
	text-decoration: none;
	font-weight: 500;
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.5rem;
	display: flex;
	color: ${colors.black};
	align-items: center;
	justify-content: center;
	margin-top: 2rem;
	svg {
		width: 20px;
		height: 20px;
		transform: rotate(-90deg);
		margin-left: 0.5rem;
	}

	@media (min-width: ${screenSizes.small}) {
		margin-top: 4rem;
		font-size: ${font.size.title.xSmall};
	}
`

export const CTA: FunctionComponent<SanityLink> = (link) => {
	const { text, externalUrl, openInNewWindow, pageReference, staticPath } = link
	return (
		<>
			{externalUrl ? (
				<a href={externalUrl} target={openInNewWindow ? '_blank' : '_self'} rel="noreferrer">
					{text || 'Read more'}
				</a>
			) : (
				<Link href={resolve(pageReference)?.slug?.current || staticPath || '/'} passHref prefetch={false}>
					<CtaLink>
						{text || 'Learn more'}
						<Arrow />
					</CtaLink>
				</Link>
			)}
		</>
	)
}
