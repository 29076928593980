/* eslint-disable jsx-a11y/alt-text */
import { colors, screenSizes } from '@nomonosound/gravity'
import { FunctionComponent, useRef } from 'react'
import { useInView } from 'react-cool-inview'
import styled from 'styled-components'
import type { PageWidth, TextAndImage as TextAndImageType } from '~/types'
import { styles } from '~/utils'
import { ResponsiveImage } from '../Image'
import { PortableText } from './PortableText'
import { Section } from './Section'

const Wrapper = styled.div<{ pageWidth?: PageWidth }>`
	width: ${(p) => (p.pageWidth === 'standard' ? '90rem' : p.pageWidth === 'medium' ? '75rem' : 'auto')};
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;

	h2 {
		${colors.gray90};
		font-size: 3rem;
		line-height: 3.5rem;
		white-space: pre-wrap;
		margin: 0 auto 1.25rem auto;
	}
	p {
		font-size: 2rem;
		line-height: 2.5rem;
		max-width: 680px;
		margin: 0 auto 6rem auto;
		color: #667085;
	}
	@media (max-width: ${screenSizes.small}) {
		padding: 2.4rem ${styles.spacingMobile} 0;
		h2 {
			font-size: 2rem;
			line-height: 2.5rem;
		}
		p {
			font-size: 1rem;
			line-height: 1.5rem;
			margin-bottom: 1.75rem;
		}
	}
`

const ImageWrapper = styled.div`
	span {
		display: block !important;
	}
`

const TextAndImage: FunctionComponent<TextAndImageType> = ({ settings, image, content, animate = false }) => {
	const StyledPortableText = styled(PortableText)`
		padding: 2.4rem ${styles.spacingMobile} 0;
		@media (min-width: ${screenSizes.small}) {
			padding: 0;
		}
	`

	const wrapperRef = useRef<HTMLDivElement>(null)
	const { observe, unobserve } = useInView({
		threshold: 0.15,
		onEnter: () => {
			if (wrapperRef.current && animate) {
				wrapperRef.current.classList.add('cmp-in')
			}
			unobserve()
		}
	})

	return (
		<Section {...settings} ref={observe}>
			<Wrapper pageWidth={settings?.pageWidth}>
				<div ref={wrapperRef} className={animate !== false ? 'cmp-out' : ''}>
					<StyledPortableText blocks={content} />
					{image && (
						<ImageWrapper>
							<ResponsiveImage image={image} />
						</ImageWrapper>
					)}
				</div>
			</Wrapper>
		</Section>
	)
}

export default TextAndImage
