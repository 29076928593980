import type { UseNextSanityImageOptions } from 'next-sanity-image'
import type { FunctionComponent } from 'react'
import { useInView } from 'react-cool-inview'
import { Heading } from '~/components/FontStyles'
import { DefaultImage } from '~/components/Image'
import type { ContentBox as ContentBoxType, TextAlign } from '~/types'
import { CTA } from './CTA'

interface ContentBoxProps extends ContentBoxType {
	textAlign: TextAlign
	animate?: boolean
}

export const ContentBox: FunctionComponent<ContentBoxProps> = ({ title, animate = true, description, image, cta }) => {
	const options: UseNextSanityImageOptions = {
		imageBuilder: (imageUrlBuilder, opts) => {
			const newAspect = (imageUrlBuilder.options?.source as any)?.newAspect
			const { width } = opts

			const height = width && Math.round(width / newAspect)
			if (width && height) {
				return imageUrlBuilder.width(width).height(height)
			}
			return imageUrlBuilder
		}
	}
	const { observe, inView } = useInView({ unobserveOnEnter: true, threshold: 0.05 })

	return (
		<article
			ref={observe}
			className={`grid grid-rows-[auto,auto,1fr] gap-4 ${animate ? (inView ? 'cmp-in' : 'cmp-out') : ''}`}
		>
			<Heading type="headingSm" className="text-7">
				{title}
			</Heading>
			<p className="text-gray-700">{description}</p>
			{image && (
				<figure className="self-center overflow-hidden rounded-2xl">
					<DefaultImage
						image={image}
						aspectRatio={3 / 4}
						alt="Image"
						objectFit="cover"
						options={options}
						sizes="(max-width: 400px) 100vw, 400px"
						unoptimized={true}
					/>
				</figure>
			)}
			{cta && <CTA {...cta} />}
		</article>
	)
}
