import { colors, screenSizes, size } from '@nomonosound/gravity'
import * as Accordion from '@radix-ui/react-accordion'
import type { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'
import { PortableText } from '~/components/blocks'
import { defaultSectionMargin } from '~/components/GeneralStyles'
import type { Faq as FaqType } from '~/types'
import { styles } from '~/utils'

function Arrow() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 10">
			<path
				fill="currentColor"
				d="M15.55.47c-.28-.3-.67-.47-1.07-.47s-.78.17-1.07.47L7.96 6.13 2.6.47C2.3.17 1.92 0 1.52 0S.73.17.45.47A1.6 1.6 0 000 1.6a1.68 1.68 0 00.45 1.14l6.43 6.79a1.52 1.52 0 001.08.47 1.45 1.45 0 001.08-.47l6.51-6.8A1.6 1.6 0 0016 1.6a1.68 1.68 0 00-.45-1.13z"
			/>
		</svg>
	)
}

interface FaqProps {
	faq: FaqType[]
}

const open = keyframes({
	from: { height: 0 },
	to: { height: 'var(--radix-accordion-content-height)' }
})

const close = keyframes({
	from: { height: 'var(--radix-accordion-content-height)' },
	to: { height: 0 }
})

const Wrapper = styled.div`
	background-color: ${colors.white};
	color: ${colors.black};
	padding: ${size.xxxLarge} 0;
	padding-top: 0;
	border-radius: 1rem;
	${defaultSectionMargin}

	@media (max-width: ${screenSizes.small}) {
		border-radius: 0;
		width: 100vw;
		margin-left: -${styles.spacingMobile};
	}
`

const Title = styled.h2`
	font-size: ${size.xxLarge};
	text-align: center;
	margin: 0;
	padding: 3rem;
`

const Root = styled(Accordion.Root)`
	max-width: 55rem;
	width: 100%;
	margin: 0 auto;
`

const Item = styled(Accordion.Item)`
	border-bottom: 1px solid ${colors.gray10};
	&:last-child {
		border-bottom: none;
	}
`

const Header = styled(Accordion.Header)`
	padding: 0;
	margin: 0;
	text-align: left;
	@media (min-width: ${screenSizes.small}) {
		font-size: ${size.large};
	}
`

const Content = styled(Accordion.Content)`
	overflow: hidden;
	line-height: 1.5;
	padding: 0 1rem;
	max-width: 45rem;

	&[data-state='open'] {
		animation: ${open} 150ms ease-out;
	}

	&[data-state='closed'] {
		animation: ${close} 150ms ease-out;
	}
	p {
		color: #171717;
	}
`

const ArrowContainer = styled.div`
	width: 2rem;
	height: 2rem;
	margin-left: auto;
	display: flex;
	justify-content: center;
	align-items: center;

	[data-state='open'] & {
		transform: rotate(180deg);
	}
	transition: transform 100ms;

	svg {
		height: 0.5rem;
	}
`

const Trigger = styled(Accordion.Trigger)`
	appearance: none;
	border: none;
	background-color: transparent;
	color: inherit;
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin: 0;
	padding: 1rem;
	cursor: pointer;
`

const Faq: FunctionComponent<FaqProps> = ({ faq }) => {
	return (
		<Wrapper>
			<Title>FAQs</Title>
			<Root type="multiple">
				<>
					{faq.map((item) => {
						const { title, _id, content } = item
						if (!title) return
						return (
							<Item value={_id} key={_id}>
								<Trigger>
									<Header>{title}</Header>
									<ArrowContainer>
										<span style={{ display: 'none' }}>Open</span>
										<Arrow aria-hidden />
									</ArrowContainer>
								</Trigger>
								<Content>
									<PortableText blocks={content} />
								</Content>
							</Item>
						)
					})}
				</>
			</Root>
		</Wrapper>
	)
}

export default Faq
