import type { FunctionComponent } from 'react'

export const Arrow: FunctionComponent = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
			<path
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M6 2.5v7m0 0L9.5 6M6 9.5L2.5 6"
			/>
		</svg>
	)
}

export default Arrow
