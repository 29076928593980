import type { FunctionComponent } from 'react'
import styled from 'styled-components'
import type { IterableElement } from 'type-fest'
import { PricingText } from '~/components/PricingText'
import type { BoxList as BoxListType } from '~/types'
import { cn, getBackgroundColor, getColor, useTheme } from '~/utils'

const PortableWrapper = styled.div<{ itemStyle: IterableElement<BoxListType['list']>['style'] }>`
	opacity: ${(p) => (p.itemStyle === 'active' ? 1 : 0.6)};

	h6 {
		font-size: 0.75em;
	}

	* + * {
		margin-top: 1rem;
	}
`

export const BoxList: FunctionComponent<BoxListType> = ({ list, theme: override }) => {
	const theme = useTheme(override)
	return (
		<ol className="list grid gap-8 md:auto-cols-fr md:grid-flow-col" style={{ color: getColor({ theme }) }}>
			{list.map(({ _key, style, text }) => (
				<li
					key={_key}
					className={cn('w-full rounded-xl border p-8 text-center md:w-auto', {
						'border-black': style === 'active',
						'border-stone-200 drop-shadow-sm': style === 'faded'
					})}
					style={{ backgroundColor: getBackgroundColor({ theme }) }}
				>
					<PortableWrapper itemStyle={style}>
						<PricingText content={text} />
					</PortableWrapper>
				</li>
			))}
		</ol>
	)
}
