import { FunctionComponent } from 'react'
import type { HTMLElementProps } from '~/types'
import type { PaymentIconProps } from './common'

export const VippsIcon: FunctionComponent<PaymentIconProps> = ({ size = 'normal', ...props }) =>
	size === 'small' ? <VippsIconSmall {...props} /> : <VippsIconMedium {...props} />

export const VippsIconMedium: FunctionComponent<HTMLElementProps<'svg'>> = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 163.5 66.1" role="img" {...props}>
		<title id="">Vipps</title>
		<g fill="#FF5B24">
			<path d="m28 22 5.1 14.9 5-14.9H44l-8.8 22.1h-4.4L22 22h6z" />
			<path d="M57.3 40.6c3.7 0 5.8-1.8 7.8-4.4 1.1-1.4 2.5-1.7 3.5-.9s1.1 2.3 0 3.7a13.9 13.9 0 0 1-11.3 6.1c-5.1 0-9.6-2.8-12.7-7.7-.9-1.3-.7-2.7.3-3.4s2.5-.4 3.4 1c2.2 3.3 5.2 5.6 9 5.6zm6.9-12.3c0 1.8-1.4 3-3 3s-3-1.2-3-3 1.4-3 3-3a3 3 0 0 1 3 3zM78.3 22v3a8.4 8.4 0 0 1 7.2-3.6c4.3 0 9.3 3.6 9.3 11.3 0 8.1-4.8 12-9.8 12a8 8 0 0 1-6.8-3.5v10.6h-5.4V22h5.5zm0 11c0 4.5 2.6 6.9 5.5 6.9 2.8 0 5.6-2.2 5.6-6.9 0-4.6-2.8-6.8-5.6-6.8s-5.5 2.1-5.5 6.8zm26-11v3a8.4 8.4 0 0 1 7.2-3.6c4.3 0 9.3 3.6 9.3 11.3 0 8.1-4.8 12-9.8 12a8 8 0 0 1-6.8-3.5v10.6h-5.4V22h5.5zm0 11c0 4.5 2.6 6.9 5.5 6.9 2.8 0 5.6-2.2 5.6-6.9 0-4.6-2.8-6.8-5.6-6.8-2.9 0-5.5 2.1-5.5 6.8zm28-11.6c4.5 0 7.7 2.1 9.1 7.3l-4.9.8c-.1-2.6-1.7-3.5-4.1-3.5-1.8 0-3.2.8-3.2 2.1 0 1 .7 2 2.8 2.4l3.7.7c3.6.7 5.6 3.1 5.6 6.3 0 4.8-4.3 7.2-8.4 7.2-4.3 0-9.1-2.2-9.8-7.6l4.9-.8c.3 2.8 2 3.8 4.8 3.8 2.1 0 3.5-.8 3.5-2.1 0-1.2-.7-2.1-3-2.5l-3.4-.6c-3.6-.7-5.8-3.2-5.8-6.4.1-5 4.6-7.1 8.2-7.1z" />
		</g>
	</svg>
)

export const VippsIconSmall: FunctionComponent<HTMLElementProps<'svg'>> = (props) => (
	<svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" role="img" width="38" {...props}>
		<title id="pi-vipps">Vipps</title>
		<path opacity=".07" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z" />
		<path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			fill="#FF5B24"
			d="M27.414 12.07c.357.281.545.667.564 1.155 0 .487-.188.977-.564 1.464C25.218 17.563 22.551 19 19.415 19a9.454 9.454 0 01-5.042-1.437c-1.521-.939-2.836-2.273-3.944-4.001-.319-.469-.451-.929-.394-1.38.038-.432.244-.779.62-1.042.356-.244.76-.31 1.211-.197.469.132.864.432 1.183.901.827 1.239 1.747 2.197 2.761 2.873 1.127.732 2.329 1.099 3.606 1.099 1.22 0 2.29-.291 3.211-.873.751-.47 1.521-1.221 2.31-2.254.395-.47.816-.761 1.268-.873s.852-.028 1.209.254zm-3.746-6.451c.432.413.648.911.648 1.493 0 .639-.217 1.155-.648 1.55-.413.375-.91.563-1.492.563s-1.08-.188-1.494-.563c-.412-.395-.619-.911-.619-1.55 0-.62.207-1.127.619-1.521.414-.394.912-.591 1.494-.591s1.079.206 1.492.619z"
		/>
	</svg>
)
