import { screenSizes } from '@nomonosound/gravity'
import type { FunctionComponent } from 'react'
import { useContext, useRef } from 'react'
import { useInView } from 'react-cool-inview'
import styled from 'styled-components'
import type { Centerfold as CenterfoldProps, TextAlign } from '~/types'
import { ResponsiveImage } from '../Image'
import { IdContext } from './IdContext'
import { PortableText } from './PortableText'
import { Section } from './Section'
import { Container } from '../layout/Containers'

const Content = styled.div<{ textAlign?: TextAlign }>`
	display: flex;
	flex-flow: column nowrap;

	.portableText {
		text-align: ${(p) => p.textAlign ?? 'inherit'};
		font-size: 1.25rem;
		@media (min-width: ${screenSizes.small}) {
			font-size: 1.5rem;
		}
		> * {
			margin-left: auto;
			margin-right: auto;
		}
		> * + * {
			margin-top: 1rem;
		}

		> h2 {
			font-size: 2em;
			line-height: 1.3;

			white-space: pre-wrap;
			margin: 0 auto 1.25rem auto;
		}
		> p {
			line-height: 1.5;
			max-width: 650px;
		}
		> table {
			margin-top: 3rem;
			margin-bottom: 3rem;
			width: 100%;
			table-layout: fixed;
			td {
				font-weight: 600;
				font-size: 0.75em;
				@media (min-width: ${screenSizes.small}) {
					font-size: 1.25rem;
				}
				padding: 0.5rem;
			}

			@media (max-width: ${screenSizes.small}) {
				&:not([class]) {
					font-weight: 500;
					display: contents;
					tr {
						display: contents;
					}
					tbody {
						display: flex;
						flex-flow: column nowrap;
						align-items: center;
					}
				}
			}
		}
		.hubspotForm {
			width: fit-content;
		}
		.hs-form {
			text-align: left;
			border-radius: 1rem;
			font-size: 1rem;
			p {
				margin: 0.5rem;
			}
		}
	}
`

export const Centerfold: FunctionComponent<CenterfoldProps> = ({ image, content, animate, settings }) => {
	const ref = useRef<HTMLDivElement>(null)
	const { observe, unobserve } = useInView({
		threshold: 0.15,
		onEnter: () => {
			if (animate !== true) return
			ref.current?.classList.add('cmp-in')
			unobserve()
		}
	})
	const id = useContext(IdContext)

	return (
		<Section ref={observe} id={id} {...settings}>
			<Container className="!px-0">
				<Content ref={ref} className={animate ? 'cmp-out' : ''}>
					<PortableText blocks={content} />
					{image && <ResponsiveImage image={image} />}
				</Content>
			</Container>
		</Section>
	)
}
