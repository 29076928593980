import styled from 'styled-components'

export const PreviewMessage = styled.div`
	position: fixed;
	background-color: rgba(224, 224, 56, 0.7);
	bottom: 0;
	padding: 0.5rem 1rem;
	pointer-events: none;
	opacity: 0.95;
	z-index: 5000;
	border-radius: 0 1rem 0 0;

	a {
		pointer-events: all;
		color: inherit;
		text-decoration: underline;
	}
`
