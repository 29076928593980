import { groupBy, startCase } from 'lodash'
import { Fragment, FunctionComponent } from 'react'
import { useHits } from 'react-instantsearch-hooks-web'
import { Heading } from '~/components/FontStyles'
import type { AlgoliaHit } from '~/types'
import { SearchResult } from './SearchResult'

export interface SearchResultsProps {
	className?: string
}

export const SearchResults: FunctionComponent<SearchResultsProps> = ({ className }) => {
	const hook = useHits<AlgoliaHit>()
	const groups = groupBy(
		hook.hits.map((hit) => ({ ...hit, group: getGroup(hit) })),
		(hit) => hit.group
	)

	return (
		<div className={className}>
			{hook.hits.length ? (
				Object.entries(groups).map(([group, hits]) => (
					<Fragment key={group}>
						<Heading type="headingSm">{group}</Heading>
						{hits.map((hit) => (
							<SearchResult key={hit.objectID} hit={hit} group={group} />
						))}
					</Fragment>
				))
			) : (
				<Heading type="titleLg" className="mx-auto">
					No results found
				</Heading>
			)}
		</div>
	)
}

function getGroup(hit: AlgoliaHit): string {
	switch (hit.type) {
		case 'frontPage':
		case 'contactPage':
			return 'Page'

		case 'kbArticle':
			return 'Knowledge Base Article'

		default:
			return startCase(hit.type)
	}
}
