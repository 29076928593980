import * as AccordionPrimitive from '@radix-ui/react-accordion'
import type { FunctionComponent, ReactNode } from 'react'
import { useCallback, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { getSafariVersion, getSeparatorColor, useIsomorphicEffect } from '~/utils'
import { IconPlusMinusCircle } from './blocks/TechSpecs/Icon'

// Accordion Styles
const slideDown = keyframes`
from {
    height: 0;
}
to {
    height: var(--radix-accordion-content-height);
}
`

const slideUp = keyframes`
from {
    height: var(--radix-accordion-content-height);
}
to {
    height: 0;
}
`

const StyledAccordion = styled(AccordionPrimitive.Root)`
	border-top: 1px solid ${getSeparatorColor};
`

const StyledItem = styled(AccordionPrimitive.Item)`
	overflow: hidden;
	border-bottom: 1px solid ${getSeparatorColor};
`

const StyledHeader = styled(AccordionPrimitive.Header)`
	all: unset;
	display: flex;
`

export const StyledChevron = styled(IconPlusMinusCircle)`
	width: 24px;
	height: 24px;
	display: block;
	flex-shrink: 0;
	transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);

	.line {
		transform-origin: center center;
		transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
	}

	[data-state='open'] & {
		transform: rotate(180deg);
		.line {
			transform: rotate(90deg);
		}
	}
`

const StyledTrigger = styled(AccordionPrimitive.Trigger)`
	all: unset;
	font-family: inherit;
	background-color: transparent;
	cursor: pointer;

	display: flex;
	flex: 1;
	align-items: center;
	justify-content: space-between;

	font-size: 24px;
	line-height: 1.1;
	padding: 1.5rem 0;

	&:hover,
	&:focus-visible {
		&:hover {
			opacity: 0.8;
		}
		${StyledChevron} {
			&:hover {
				opacity: 0.8;
			}
		}
	}

	&[data-state='open'] {
		color: inherit;
	}
`

export const StyledContent = styled(AccordionPrimitive.Content)<{ '$height'?: number }>`
	overflow: hidden;

	animation-duration: 0.3s;
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.87, 0, 0.13, 1);

	&[data-state='open'] {
		animation-name: ${slideDown};
	}

	&[data-state='closed'] {
		animation-name: ${slideUp};
	}
`

const slideDownCompat = (height: number | undefined) => keyframes`
	0% {
		height: 0;
	}
	100% {
		height: ${height ?? 0}px;
	}
`

const slideUpCompat = (height: number | undefined) => keyframes`
	0% {
		height: ${height ?? 0}px;
	}
	100% {
		height: 0;
	}
`

const CompatContent = styled(StyledContent)`
	&[data-state='open'] {
		animation-name: ${(p) => slideDownCompat(p.$height)};
	}

	&[data-state='closed'] {
		animation-name: ${(p) => slideUpCompat(p.$height)};
	}
`

const StyledContentText = styled('div')`
	padding: 0.5rem 0 2.25rem;
`

// Accordion Exports
export const Accordion = StyledAccordion
export const AccordionItem = StyledItem
export const AccordionTrigger: FunctionComponent<AccordionPrimitive.AccordionHeaderProps & { trigger?: ReactNode }> = ({
	trigger,
	children
}) => (
	<StyledHeader>
		<StyledTrigger>
			{children}
			{trigger !== undefined ? trigger : <StyledChevron aria-hidden />}
		</StyledTrigger>
	</StyledHeader>
)

export interface AccordionContentProps extends AccordionPrimitive.AccordionContentProps {
	/**
	 * @brief Whether to use the compatibility mode for the content animation.
	 * Safari doesn't support css variables in animations until 16, so this replaces the css variables
	 * @see https://stackoverflow.com/a/72998813
	 */
	compat?: boolean
}

export const AccordionContent: FunctionComponent<AccordionContentProps> = ({ children, compat = false, ...props }) => {
	const ref = useRef<HTMLDivElement>(null)
	const [height, setHeight] = useState<number>()

	const onChange = useCallback(() => {
		setHeight(ref.current?.clientHeight)
	}, [])
	useIsomorphicEffect(() => {
		if (!ref.current) return
		onChange()
		window.addEventListener('resize', onChange, { passive: true })
		const observer = new MutationObserver((_) => onChange())
		observer.observe(ref.current, { attributes: true, childList: true, subtree: true })
		return () => {
			observer.disconnect()
			window.removeEventListener('resize', onChange)
		}
	}, [onChange, children, props])

	const compatMode = compat && (getSafariVersion()?.major ?? 0) > 15
	const Content = compatMode ? CompatContent : StyledContent

	return (
		<Content
			{...props}
			$height={compatMode ? height : undefined}
			style={{
				...props.style,
				...(!compatMode && typeof height === 'number'
					? { '--radix-accordion-content-height': `${height}px` }
					: {})
			}}
		>
			<StyledContentText ref={ref} className="mx-px">
				{children}
			</StyledContentText>
		</Content>
	)
}
