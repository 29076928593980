import { startCase } from 'lodash'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { Highlight, Snippet } from 'react-instantsearch-hooks-web'
import type { AlgoliaHit } from '~/types'

import { getPageSlug, usePage } from '~/utils'

export interface SearchResultProps {
	hit: AlgoliaHit
	group: string
}

export const SearchResult: FunctionComponent<SearchResultProps> = ({ hit, group }) => {
	const page = usePage({ ...hit, _type: hit.type, _id: hit.objectID })
	const category = page?.category?._type === 'pageCategory' ? page?.category?.title : startCase(page?.category?._type)
	if (!hit._highlightResult) return null
	return (
		<Link href={getPageSlug(page as any).path} passHref prefetch={false}>
			<a>
				<article className="group grid cursor-pointer grid-cols-[5rem,auto,1fr] grid-rows-[auto,auto] items-center gap-x-2 py-2">
					<div className="col-start-1 row-span-full overflow-hidden text-ellipsis text-sm font-semibold">
						{category?.toLocaleLowerCase() !== group.toLocaleLowerCase() ? category : null}
					</div>
					<div className="col-start-2 row-span-full h-full w-px">
						<div className="h-full w-full bg-stone-400" />
					</div>
					<h1 className="col-start-3 row-start-1 group-hover:underline">
						{'title' in hit._highlightResult ? <Highlight attribute="title" hit={hit} /> : hit.title}
					</h1>
					<Snippet className="col-start-3 row-start-2 text-sm opacity-50" attribute="text" hit={hit} />
				</article>
			</a>
		</Link>
	)
}
