/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @next/next/no-img-element */
import { V2 } from '@nomonosound/gravity'
import { startCase } from 'lodash'
import type { FunctionComponent } from 'react'
import type { HTMLElementProps } from '~/types'
import { cn, useSettings, useTheme } from '~/utils'

export const SocialIcons: FunctionComponent<HTMLElementProps<'ul'>> = ({ className, ...props }) => {
	const links = useSettings().siteSettings.socialLinks
	const theme = useTheme()
	return (
		<ul className={cn('flex flex-row flex-wrap items-center opacity-50', className)} {...props}>
			{links?.map((link) => (
				<li key={link._key}>
					<a href={link.url} target="_blank" className="p-1">
						<span className="sr-only">{`Nomono on ${startCase(link.icon)}`}</span>
						<V2.Icon icon={`${link.icon}-brands-${theme.current === 'dark' ? 'light' : 'dark'}`} />
					</a>
				</li>
			))}
		</ul>
	)
}
