/* eslint-disable react/no-children-prop */
/* eslint-disable react-hooks/rules-of-hooks */
import { V2 } from '@nomonosound/gravity'
import styled from 'styled-components'
import { isPresent } from 'ts-extras'
import AddToCartButton from '~/components/AddToCartButton'
import AmazonButtons from '~/components/AmazonButtons'
import { Fade } from '~/components/Fade'
import Faq from '~/components/Faq'
import { FaqGroup } from '~/components/FaqGroup'
import { Body, Heading, Paragraph, Small } from '~/components/FontStyles'
import { Word } from '~/components/Highlight'
import { HubspotContactForm } from '~/components/HubspotContactForm'
import { HubspotForm } from '~/components/HubspotForm'
import { ResponsiveImage } from '~/components/Image'
import { VariableMark } from '~/components/PricingText'
import { StyledSanityLink } from '~/components/SanityLink'
import { VideoGallery } from '~/components/VideoGallery'
import {
	CustomizableTable,
	DefaultSegment,
	FaqArticle,
	FeatureSection,
	InnerSection,
	LogoSection,
	NewsletterSignup,
	Salepoints
} from '~/components/blocks'
import { PaymentIcons } from '~/components/gfx/PaymentIcons'
import type { TextAlign } from '~/types'
import { getSecondaryColor, resolve, useSettings } from '~/utils'
import { AudioPlayerV2 } from '../AudioPlayer/AudioPlayerV2'
import { Cards } from '../Cards'
import { EmbeddedVideo, ExternalVideo, YoutubeVideo } from '../EmbeddedVideo'
import { FunctionalImage } from '../FunctionalImage'
import { Grid } from '../Grid'
import { KnowledgeBaseOverview } from '../KnowledgeBaseOverview'
import { Search } from '../Search'
import { Spacer } from '../Spacer'
import { BoxList } from './BoxList'
import { IconList } from './IconList'
import { PortableAccordion } from './PortableAccordion'
import { PortableText } from './PortableText'
import { numberList } from './numberList'
import type { CustomMark, Serializers } from './types'
import { AudioPlayerV3 } from '../AudioPlayer/AudioPlayerV3'

const Subtitle = styled.h6`
	font-size: 1.25em;
	color: ${getSecondaryColor};
`

const TextAlignAnnotation = styled.p<{ value?: TextAlign }>`
	display: inline-block;
	text-align: ${(p) => p.value};
`

export const serializers: Serializers = {
	...numberList,
	block: {
		// expressive titles/headings
		displayXxl: ({ value, children }) => <Heading type="displayXxl" id={value._key} children={children} />,
		displayXl: ({ value, children }) => <Heading type="displayXl" id={value._key} children={children} />,
		displayLg: ({ value, children }) => <Heading type="displayLg" id={value._key} children={children} />,
		displayMd: ({ value, children }) => <Heading type="displayMd" id={value._key} children={children} />,
		displaySm: ({ value, children }) => <Heading type="displaySm" id={value._key} children={children} />,
		displayXs: ({ value, children }) => <Heading type="displayXs" id={value._key} children={children} />,
		// main headings
		headingLg: ({ value, children }) => <Heading type="headingLg" id={value._key} children={children} />,
		headingMd: ({ value, children }) => <Heading type="headingMd" id={value._key} children={children} />,
		headingSm: ({ value, children }) => <Heading type="headingSm" id={value._key} children={children} />,
		// subsection headings
		titleLg: ({ value, children }) => <Heading type="titleLg" id={value._key} children={children} />,
		titleMd: ({ value, children }) => <Heading type="titleMd" id={value._key} children={children} />,
		titleSm: ({ value, children }) => <Heading type="titleSm" id={value._key} children={children} />,

		// small standalone paragraphs
		textXl: ({ children }) => <Paragraph type="textXl">{children}</Paragraph>,
		textLg: ({ children }) => <Paragraph type="textLg">{children}</Paragraph>,
		textMd: ({ children }) => <Paragraph type="textMd">{children}</Paragraph>,
		textSm: ({ children }) => <Paragraph type="textSm">{children}</Paragraph>,

		// multi-paragraph articles
		bodyXl: ({ children }) => <Paragraph type="bodyXl">{children}</Paragraph>,
		bodyLg: ({ children }) => <Paragraph type="bodyLg">{children}</Paragraph>,
		bodyMd: ({ children }) => <Paragraph type="bodyMd">{children}</Paragraph>,
		bodySm: ({ children }) => <Paragraph type="bodySm">{children}</Paragraph>,

		// small titles above headings or inline titles such as table headers
		overlineMd: ({ children }) => <Paragraph type="overlineMd">{children}</Paragraph>,
		overlineSm: ({ children }) => <Paragraph type="overlineSm">{children}</Paragraph>,
		lead: ({ children }) => <Paragraph type="lead">{children}</Paragraph>,
		footnote: ({ children }) => <Paragraph type="footnote">{children}</Paragraph>,
		subtitle: (props) => (
			<Subtitle role="doc-subtitle" className="subtitle">
				{props.children}
			</Subtitle>
		),

		/**
		 * @deprecated When all pages and (especially) knowledge articles are updated in Sanity Studio to use new fonts, these could be removed.
		 */
		display1: ({ value, children }) => <Heading type="display1" id={value._key} children={children} />,
		display2: ({ value, children }) => <Heading type="display2" id={value._key} children={children} />,
		display3: ({ value, children }) => <Heading type="display3" id={value._key} children={children} />,
		h1: ({ value, children }) => <Heading type="h1" id={value._key} children={children} />,
		h2: ({ value, children }) => <Heading type="h2" id={value._key} children={children} />,
		h3: ({ value, children }) => <Heading type="h3" id={value._key} children={children} />,
		h4: ({ value, children }) => <Heading type="h4" id={value._key} children={children} />,
		h5: ({ value, children }) => <Heading type="h5" id={value._key} children={children} />,
		h6: ({ value, children }) => <Heading type="h6" id={value._key} children={children} />,
		body: ({ children }) => <Body>{children}</Body>,
		small: ({ children }) => <Small>{children}</Small>
		// End Deprecated
		// ----------
	},
	marks: {
		textAlignAnnotation: (props) => <TextAlignAnnotation {...props} />,
		link: ({ value: mark, children }) => <StyledSanityLink link={mark}>{children}</StyledSanityLink>,
		highlight: (props) => <Word>{props.children}</Word>,
		fade: (props) => <Fade>{props.children}</Fade>,
		iconAnnotation: ({ value: mark }) => <V2.Icon className="mb-[0.125em]" icon={mark.icon} />,
		variable: (props) => <VariableMark {...props} markType={props.markType as CustomMark['_type']} prices={null} />
	},
	types: {
		accordions: ({ value }) => <PortableAccordion {...value} />,
		hubspotForm: ({ value }) => {
			switch (value.style) {
				case 'contact':
					return <HubspotContactForm {...value} />

				case 'signup':
				case undefined:
					return <HubspotForm className={value._type} {...(value as Required<typeof value>)} />
			}
		},
		faqArticle: ({ value }) => <FaqArticle {...value} />,
		faqBlock: ({ value }) => {
			const faq = resolve(value.faq)
			return faq ? <FaqGroup title={value.title} theme={value.theme} faq={faq} /> : null
		},
		cards: ({ value }) => <Cards {...value} />,
		segment: ({ value }) => (
			<DefaultSegment {...value}>
				<PortableText blocks={value.description} />
			</DefaultSegment>
		),
		boxList: ({ value }) => <BoxList {...value} />,
		grid: ({ value }) => <Grid {...value} />,
		iconList: ({ value }) => <IconList {...value} />,
		innerSection: ({ value }) => <InnerSection {...value} />,
		featureSection: ({ value }) => <FeatureSection {...value} />,
		customizableTable: ({ value }) => <CustomizableTable {...value} />,
		image: ({ value }) => <ResponsiveImage layout={value.layout ?? 'responsive'} image={value} alt={value.alt} />,
		functionalImage: ({ value }) => <FunctionalImage {...value} />,
		logoSection: ({ value }) => <LogoSection {...value} />,
		customSection: ({ value: { title, description, component, faq } }) => {
			const { siteSettings } = useSettings()
			switch (component) {
				case 'newsletterSignup':
					return (
						<NewsletterSignup
							title={title}
							description={description}
							form={siteSettings?.hubspotNewsletterForm}
						/>
					)

				case 'allPlans': {
					throw new Error('plans not implemented yet')
				}
				case 'faq': {
					const questions = resolve(faq)?.questions?.map(resolve).filter(isPresent)
					return questions ? <Faq faq={questions} /> : null
				}
			}
		},

		externalVideo: ({ value }) => {
			const isResponsive = value.width === undefined && value.height === undefined
			return isResponsive ? (
				<div>
					<ExternalVideo {...value} />
				</div>
			) : (
				<ExternalVideo {...value} />
			)
		},
		youtubeVideo: ({ value }) => {
			const isResponsive = value.width === undefined && value.height === undefined
			return isResponsive ? (
				<div>
					<YoutubeVideo {...value} />
				</div>
			) : (
				<YoutubeVideo {...value} />
			)
		},
		embeddedVideo: ({ value }) => <EmbeddedVideo {...value} />,
		videoGallery: ({ value }) => <VideoGallery {...value} />,
		kbOverview: ({ value }) => <KnowledgeBaseOverview {...value} />,
		paymentIcons: ({ value }) => <PaymentIcons {...value} />,
		pricingContent: () => <></>,
		salepoints: ({ value }) => <Salepoints {...value} />,
		search: ({ value }) => <Search {...value} />,
		spacer: ({ value }) => <Spacer wrapper={false} {...value} />,
		amazonButtons: ({ value }) => <AmazonButtons {...value} />,
		addToCartButton: ({ value }) => <AddToCartButton {...value} />,
		audioPlayerV2: ({ value }) => <AudioPlayerV2 {...value} />,
		audioPlayerV3: ({ value }) => <AudioPlayerV3 {...value} />
	}
}
