import { colors, screenSizes } from '@nomonosound/gravity'
import useEmblaCarousel from 'embla-carousel-react'
import { useCallback, useContext, useRef } from 'react'
import { useInView } from 'react-cool-inview'
import styled from 'styled-components'
import { FeatureSection } from '~/components/blocks'
import { ArrowLong } from '~/components/gfx'
import { IdContext } from '../IdContext'
import { Testimonial } from './Testimonial'
import type { CarouselProps } from './common'

const Wrapper = styled.div`
	border-radius: 1rem;
	padding: 6rem 4.6rem;
	color: ${colors.black};
	margin: 2.5rem 0;

	background: linear-gradient(109.94deg, rgba(163, 172, 255, 0.5) 0%, rgba(163, 172, 255, 0.5) 105.2%);

	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04), 0px 12px 24px rgba(0, 0, 0, 0.16);

	.embla {
		position: relative;
		margin-left: auto;
		margin-right: auto;
	}

	.embla__viewport {
		overflow: hidden;
		width: 100%;
	}

	.embla__viewport {
		cursor: move;
		cursor: grab;
	}

	.embla__viewport.is-dragging {
		cursor: grabbing;
	}

	.embla__container {
		display: flex;
		user-select: none;
		-webkit-touch-callout: none;
		-khtml-user-select: none;
		-webkit-tap-highlight-color: transparent;
		margin-left: -10px;
	}

	.embla__slide {
		position: relative;
		min-width: 100%;
		padding-left: 10px;
	}

	@media (max-width: ${screenSizes.small}) {
		padding: 4rem 1rem;
		width: 100vw;
		position: relative;
		left: 50%;
		right: 50%;
		z-index: 1;
		background: linear-gradient(179.97deg, rgba(255, 255, 255, 0.27) 36.31%, rgba(255, 255, 255, 0) 99.97%),
			linear-gradient(
				14.98deg,
				rgba(1, 6, 48, 0.05) 13.83%,
				rgba(98, 71, 128, 0.05) 71.07%,
				rgba(98, 71, 128, 0) 96.46%
			),
			#b5bcff;
		box-shadow: none;
		margin: -1rem -50vw -1rem -50vw;

		.embla__slide {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}
`

const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 4rem;

	button {
		border-radius: 999px;
		background: ${colors.white};
		height: 3.5rem;
		width: 3.5rem;
		border: none;
		cursor: pointer;
		box-shadow: 0px 15px 18px -1px rgba(0, 0, 0, 0.14);
		:first-child {
			margin-right: 1rem;
		}
		:last-child {
			margin-left: 1rem;
			svg {
				transform: rotate(180deg);
			}
		}
	}
	@media (max-width: ${screenSizes.medium}) {
		button {
			height: 3rem;
			width: 3rem;
		}
		margin-top: 3rem;
	}
`

export const TestimonialCarousel: React.FC<CarouselProps> = ({ testimonials }) => {
	const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false })

	const wrapperRef = useRef<HTMLDivElement>(null)
	const { observe } = useInView({
		threshold: 0.05,
		onEnter: ({ unobserve }) => {
			if (wrapperRef.current) {
				wrapperRef.current.classList.add('cmp-in')
			}
			unobserve()
		}
	})

	const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

	const id = useContext(IdContext)
	return (
		<section id={id} ref={observe}>
			<Wrapper>
				<div ref={wrapperRef} className="embla cmp-out">
					<div className="embla__viewport" ref={viewportRef}>
						<div className="embla__container">
							{testimonials?.map((slide) => (
								<div className="embla__slide" key={slide._key}>
									{slide._type === 'testamonial' ? (
										<Testimonial {...slide} />
									) : (
										<FeatureSection {...slide} />
									)}
								</div>
							))}
						</div>
					</div>
				</div>
				<ButtonsWrapper>
					<button onClick={scrollPrev} aria-label="Scroll to previous slide">
						<ArrowLong />
					</button>
					<button onClick={scrollNext} aria-label="Scroll to next slide">
						<ArrowLong />
					</button>
				</ButtonsWrapper>
			</Wrapper>
		</section>
	)
}
