/* eslint-disable @next/next/no-img-element */
import { screenSizes } from '@nomonosound/gravity'
import { groupBy } from 'lodash'
import type { FunctionComponent } from 'react'
import styled from 'styled-components'
import { StyledSanityLink } from '~/components/SanityLink'
import type { Theme } from '~/types'
import { getBackgroundColor, getBorderColor, getColor, ThemeProvider, useSettings, useTheme } from '~/utils'
import { Container as BaseContainer } from '../Containers'
import { HeaderSection } from '../Header'
import { FooterBottom } from './FooterBottom'
import { NavSections } from './NavSections'

const FooterContainer = styled.footer<FooterProps & { showBorder?: boolean }>`
	color: ${getColor};
	background: ${(props) =>
		props.theme.current !== 'dark'
			? getBackgroundColor(props)
			: `linear-gradient(180deg, rgba(255, 255, 255, 0.06) 48.27%, rgba(255, 255, 255, 0) 100%),
		linear-gradient(11.23deg, rgba(1, 6, 48, 0.2) 0.27%, rgba(98, 71, 128, 0.2) 146.63%), #1d1d1f`};
	hr {
		border: none;
		border-top: 1px solid ${(p) => (p.showBorder ? getBorderColor(p) : 'transparent')};
		margin-bottom: 4rem;
	}

	@media (min-width: ${screenSizes.medium}) {
		background: ${(props) =>
			props.theme.current !== 'dark'
				? getBackgroundColor(props)
				: `linear-gradient(180deg, rgba(255, 255, 255, 0.06) 44.9%, rgba(255, 255, 255, 0) 96.47%),
				   linear-gradient(85.2deg, rgba(1, 6, 48, 0.2) 0%, rgba(98, 71, 128, 0.2) 90.7%), #000000`};
	}
`

const Container = styled(BaseContainer)`
	display: grid;
	gap: 2rem 6rem;
	grid-template-rows: repeat(3, auto);
	justify-content: space-between;
	grid-template-columns: 1fr;

	padding-top: 1rem;
	@media (min-width: ${screenSizes.small}) {
		grid-template-columns: 1fr repeat(${(props: { navSectionCount: number }) => props.navSectionCount}, auto);
		padding-top: 0;
	}

	> hr {
		margin: 0;
		width: 100%;
		grid-row-start: 1;
		grid-column: 1 / -1;
	}
`

const NavSectionWrapper = styled(NavSections)`
	@media (min-width: ${screenSizes.small}) {
		justify-self: flex-end;
	}
`

interface FooterProps {
	theme?: Theme
}

export const Footer: FunctionComponent<FooterProps> = ({ theme: themeOverride }) => {
	const { siteSettings } = useSettings()

	const theme = useTheme(themeOverride ?? siteSettings?.theme)

	const desktop = groupBy(siteSettings?.footerBottom, (item) => item.headerGroup)
	const mobile = groupBy(siteSettings?.footerBottom, (item) => item.mobileHeaderGroup)

	return (
		<>
			<ThemeProvider theme={theme}>
				<FooterContainer id="footer" showBorder={siteSettings?.showBorder}>
					<Container navSectionCount={siteSettings?.footerItems?.length ?? 0}>
						<hr />
						<div className="col-start-1 row-start-2 grid">
							{siteSettings.footerLogo && (
								<StyledSanityLink
									link={siteSettings.footerLogo}
									useActive
									className="self-start justify-self-start leading-none"
								/>
							)}
						</div>
						<NavSectionWrapper />
						{siteSettings?.footerBottom && (
							<FooterBottom className="grid grid-cols-1 items-center py-2 sm:grid-cols-2 lg:grid-cols-3 ">
								<HeaderSection items={mobile.left} className="justify-start lg:hidden" />
								<HeaderSection items={mobile.center} className="sm:justify-center lg:hidden" />
								<HeaderSection items={mobile.right} className="sm:justify-end lg:hidden" />

								<HeaderSection items={desktop.left} className="hidden justify-start lg:flex" />
								<HeaderSection items={desktop.center} className="hidden justify-center lg:flex" />
								<HeaderSection items={desktop.right} className="hidden justify-end lg:flex" />
							</FooterBottom>
						)}
					</Container>
				</FooterContainer>
			</ThemeProvider>
		</>
	)
}
