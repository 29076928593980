import { screenSizes } from '@nomonosound/gravity'
import styled, { css } from 'styled-components'
import type { PageWidth } from '~/types'
import { getBackgroundColor, styles } from '~/utils'

export interface ContainerProps {
	pageWidth?: PageWidth

	// TODO: IMPLEMENT THIS
	withBreakout?: boolean
}

export const Container = styled.div<ContainerProps>`
	--max-width: 90rem;
	--default-margin: ${styles.spacing};
	max-width: 100%;
	width: ${(p) => (p.pageWidth ? styles.sectionWidth[p.pageWidth] : styles.sectionWidth.standard)};
	margin: 0 auto;
	padding-left: var(--default-margin);
	padding-right: var(--default-margin);

	position: relative;

	@media (max-width: ${screenSizes.small}) {
		width: 100%;
		padding: 0 ${styles.spacingMobile};
	}
`

export const BreakoutCss = css`
	background-color: ${getBackgroundColor};
	//width: 100vw; // messes up our effort on removing unnecessary horizontal scrollbar
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
`

export const Breakout = styled.div`
	${BreakoutCss}
`
