import styled, { css } from 'styled-components'
import { getBackgroundColor, getColor } from '~/utils'

export const HeaderButtonCss = css`
	font-size: 17px;
	font-weight: normal;
	line-height: 1.3;
	padding: 0.4375rem 1rem;
	height: auto;
`

export const StyledHeader = styled.div`
	z-index: 30;
	position: fixed;
	&[data-is-on-top='true'] {
		position: relative;
	}
	top: 0;
	width: 100%;

	color: ${getColor};
	background: ${getBackgroundColor};

	.link--image[href='/'] img {
		filter: brightness(20);
		mix-blend-mode: difference;
	}

	a {
		text-decoration: none;
		color: inherit;
		&.link--button,
		&.link--secondaryButton,
		&.link--outline,
		&.link--customButton {
			${HeaderButtonCss};
		}

		&.link--button {
			color: var(--text-on-light);
		}
	}
`
