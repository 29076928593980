// import type { useNextSanityImage } from 'next-sanity-image';
import { useNextSanityImage, UseNextSanityImageOptions } from 'next-sanity-image'
import NextImage, { ImageProps } from 'next/image'
import type { SanityImageAsset, SanityImageCrop, SanityImageHotspot, SanityReference } from '~/types'
import { getClient } from '~/utils'

interface DefaultImageProps {
	image: {
		_type?: 'image'
		asset: SanityReference<SanityImageAsset>
		crop?: SanityImageCrop
		hotspot?: SanityImageHotspot
	}
	priority?: ImageProps['priority']
	layout?: ImageProps['layout']
	alt: string
	aspectRatio?: number
	placeholder?: boolean
	width?: number
	height?: number
	options?: UseNextSanityImageOptions
	objectFit?: ImageProps['objectFit']
	sizes?: ImageProps['sizes']
	unoptimized?: boolean
}

export const DefaultImage = ({
	image,
	alt,
	priority = false,
	aspectRatio,
	options,
	layout = 'responsive',
	objectFit = 'cover',
	placeholder = true,
	sizes = '(max-width: 768px) 100vw, 1440px',
	unoptimized = true
}: DefaultImageProps) => {
	const defaultOptions: UseNextSanityImageOptions = {
		imageBuilder: (imageUrlBuilder, opts) => {
			const { width } = opts
			return imageUrlBuilder.width(width ?? 400)
		}
	}
	const imageProps = useNextSanityImage(
		getClient(),
		{ ...image, newAspect: aspectRatio },
		(options = options || defaultOptions)
	)

	return imageProps.src ? (
		<NextImage
			{...imageProps}
			width={layout !== 'fill' ? imageProps.width : undefined}
			height={layout !== 'fill' ? imageProps.height : undefined}
			placeholder={placeholder && imageProps.placeholder ? 'blur' : undefined}
			priority={priority}
			layout={layout}
			alt={alt}
			objectFit={objectFit}
			sizes={layout === 'fill' || layout === 'responsive' ? sizes : undefined}
			unoptimized={unoptimized}
		/>
	) : null
}

export default DefaultImage
