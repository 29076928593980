import { V2 } from '@nomonosound/gravity'
import { useRouter } from 'next/router'
import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isPresent } from 'ts-extras'
import { Cross } from '~/components/gfx'
import { Portal } from '~/components/Portal'
import type { CountryCode, CountrySelector as CountrySelectorSettings, State } from '~/types'
import { cn, ThemeProvider, useCountries, useCountry, useIsomorphicEffect, useTheme } from '~/utils'
import { Actions } from '~/utils/redux'

interface Props extends CountrySelectorSettings {
	readOnly?: boolean
}

export const CountrySelector: FunctionComponent<Props> = ({
	title,
	iconPosition = 'left',
	autoShow = false,
	readOnly = false,
	...props
}) => {
	const dispatch = useDispatch()
	const [current, setCountry] = useCountry()
	const open = useSelector((state: State) => state.market.selectorOpen)
	const setOpen = useCallback((next: boolean) => dispatch(Actions.setSelectorOpen(next)), [dispatch])
	const countries = useCountries()
	const theme = useTheme(props.theme)
	const [icon, setIcon] = useState<V2.IconProps['icon'] | null>(null)
	const router = useRouter()

	useEffect(() => {
		if (autoShow && !isPresent(current)) {
			setOpen(true)
		}
	}, [current, autoShow, setOpen])

	// Needs effect as the icon doesn't correctly update on the first render TODO: Figure out why
	useIsomorphicEffect(() => {
		setIcon(`${getIconCode(current?.code ?? 'US')}-flags`)
	}, [current?.code])

	const unsupportedCountryRedirect = useCallback(() => {
		router.push('/other-countries')
	}, [router])

	return (
		<>
			<button
				className={cn(
					'button flex items-center gap-x-1.5 rounded-full',
					{ 'hocus:shadow hocus:shadow-black/20 transition-shadow': !readOnly },
					iconPosition === 'left' ? 'flex-row' : 'flex-row-reverse',
					{ 'cursor-default': readOnly }
				)}
				onClick={(_) => (readOnly ? null : setOpen(true))}
				aria-label={title}
			>
				<span className={`inline-block w-5 brightness-[0.15] ${readOnly ? 'opacity-0' : 'opacity-60'}`}>
					<V2.ChevronDownLineIcon />
				</span>

				{icon && <V2.Icon icon={icon} className="!h-6" />}
			</button>
			<ThemeProvider theme={theme}>
				<Portal
					open={open}
					modal
					onClick={(_) => current !== null && setOpen(false)}
					onClose={() => current !== null && setOpen(false)}
					className="relative flex max-h-[80vh] flex-col px-4 pt-8 lg:pb-8 xl:p-6"
				>
					<h2 className="mb-6 text-center font-serif text-5xl md:mb-10">{title}</h2>
					<ul
						className="grid w-full grid-cols-1 gap-y-4 gap-x-8 overflow-scroll sm:grid-cols-2 md:grid-cols-3 md:gap-y-6 xl:grid-cols-4"
						role="listbox"
					>
						{countries
							.slice()
							.sort((a, b) => a.name.localeCompare(b.name))
							.map((country, i) => (
								<li
									key={i}
									role="option"
									data-country={country.code}
									aria-selected={country.code === current?.code}
								>
									<button
										className={
											'button w-full rounded-2xl bg-stone-100 px-8 py-5 dark:bg-gray-100 dark:bg-opacity-5 ' +
											'flex items-center space-x-4 whitespace-nowrap text-left hover:opacity-60'
										}
										tabIndex={0}
										onClick={() => setCountry(country.code)}
										autoFocus={i == 0}
									>
										<V2.Icon icon={`${getIconCode(country.code)}-flags`} />
										<span className="text-xl" role="presentation">
											{country.name}
										</span>
									</button>
								</li>
							))}
						<li key="other" role="option">
							<button
								className={
									'button w-full rounded-2xl bg-stone-100 px-8 py-5 dark:bg-gray-100 dark:bg-opacity-5 ' +
									'flex items-center justify-center space-x-4 whitespace-nowrap outline-none hover:opacity-60'
								}
								onClick={unsupportedCountryRedirect}
							>
								<span className="text-xl" role="presentation">
									Other
								</span>
							</button>
						</li>
					</ul>

					{current !== null && (
						<button
							className="absolute top-0 right-0 p-4 sm:top-5 sm:right-5"
							onClick={(_) => setOpen(false)}
						>
							<Cross />
						</button>
					)}
				</Portal>
			</ThemeProvider>
		</>
	)
}

function getIconCode(cc: CountryCode) {
	switch (cc) {
		case 'BV': // Bouvet Island
		case 'SJ': // Svalbard and Jan Mayen
			return 'no'

		case 'UM': // United States Minor Outlying Islands
			return 'us'

		case 'PM': // Saint Pierre and Miquelon
		case 'WF': // Wallis and Futuna
			return 'fr'

		case 'GB':
			return 'uk'

		case 'AQ': // Antartica
		case 'CG': // Congo
		case 'CI': // Ivory Coast
		case 'CV': // Cape Verde
		case 'GF': // French Guiana
		case 'GP': // Guadeloupe
		case 'GS': // South Georgia and the South Sandwich Islands
		case 'RE': // Réunion
		case 'NC': // New Caledonia
		case 'SD': // Sudan
		case 'SH': // Saint Helena
		case 'VA': // Vatican'
		case 'YT': // Mayotte
			throw new Error('Unsupported country icon')

		default: {
			const rest = cc
			return rest.toLowerCase() as Lowercase<typeof rest>
		}
	}
}
