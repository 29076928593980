import { useState, type FunctionComponent } from 'react'
import { SanityImage } from '~/components/Image'
import type { FunctionalImage as FunctionalImageProps } from '~/types'
import { useIsomorphicEffect, useWindowSize } from '~/utils'

export const FunctionalImage: FunctionComponent<FunctionalImageProps> = ({
	image: desktopImage,
	mobileImage,
	url,
	inline,
	alt
}) => {
	const [image, setImage] = useState<FunctionalImageProps['image']>()
	const { isMobile } = useWindowSize()
	useIsomorphicEffect(() => {
		if (isMobile && mobileImage) {
			setImage(mobileImage)
		} else {
			setImage(desktopImage)
		}
	}, [desktopImage, mobileImage, isMobile])

	if (!image) {
		return null
	}

	return (
		<div className={`overflow-hidden ${inline ? 'inline-block' : 'block'}`}>
			{url ? (
				<a className="block" href={url} target="_blank" rel="noreferrer">
					<SanityImage layout="fixed" image={image} alt={alt || ''} />
				</a>
			) : (
				<SanityImage layout="fixed" image={image} alt={alt || ''} />
			)}
		</div>
	)
}
