import { screenSizes, size } from '@nomonosound/gravity'
import type { FunctionComponent } from 'react'
import { useContext } from 'react'
import styled from 'styled-components'
import { Ingres, defaultSectionMargin } from '~/components/GeneralStyles'
import { Breakout, Container } from '~/components/layout/Containers'
import type { Segment } from '~/types'
import { DefaultImage } from '../../Image'
import { IdContext } from '../IdContext'

interface DefaultSegmentProps extends Segment {
	children: any
}
const ImagePart = styled.div`
	position: relative;
	border-radius: 1rem;
	overflow: hidden;
`

const TextPart = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	align-items: flex-start;
`

const Section = styled.section`
	display: flex;
	flex-direction: column;
	padding-top: 2rem;
	padding-bottom: 2rem;

	@media (min-width: ${screenSizes.small}) {
		flex-direction: row;
		align-items: center;
		&[data-layout='right'] {
			& ${ImagePart} {
				@media (min-width: ${screenSizes.small}) {
					min-width: 70%;
					margin-right: 3rem;
					order: 1;
				}
				/* margin-left: -3rem; */
			}
			& ${TextPart} {
				@media (min-width: ${screenSizes.small}) {
					order: 2;
				}
			}
		}

		&[data-layout='left'] {
			& ${ImagePart} {
				@media (min-width: ${screenSizes.small}) {
					order: 2;
					min-width: 70%;
				}
			}
			& ${TextPart} {
				@media (min-width: ${screenSizes.small}) {
					order: 1;
					margin-right: 3rem;
				}
			}
		}

		& ${ImagePart} {
			order: 1;
		}

		& ${TextPart} {
			order: 2;
		}
	}

	> div {
		position: relative;
	}
`

const Heading = styled.h2`
	font-size: clamp(2rem, ${size.xLarge}, 4vw);
	line-height: 1;
	margin-bottom: ${size.large};
`

const Paragraph = styled(Ingres)`
	margin-bottom: ${size.large};
	p {
		line-height: 1.25;
	}
`

export const DefaultSegment: FunctionComponent<DefaultSegmentProps> = ({
	title,
	image,
	backgroundImage,
	layout,
	children
}) => {
	const id = useContext(IdContext)
	if (!title) return null

	// Needs to be in function to allow Breakout to be used and backgroundImage to ble loaded
	const Wrapper = styled(Breakout)`
		${defaultSectionMargin}
		background-image: ${backgroundImage || ''};
	`

	return (
		<Wrapper id={id}>
			<Container>
				<Section data-layout={layout || 'left'}>
					<TextPart>
						<Heading>{title}</Heading>
						<Paragraph>{children}</Paragraph>
						{/* <DefaultButton>Call to</DefaultButton> */}
					</TextPart>
					<ImagePart>
						{image && (
							<DefaultImage
								image={image}
								alt={title}
								priority
								layout="responsive"
								sizes="(max-width: 768px) 100vw, 1440px"
							/>
						)}
					</ImagePart>
				</Section>
			</Container>
		</Wrapper>
	)
}
