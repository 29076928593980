import type { FunctionComponent } from 'react'
import type { SimpleSection as SimpleSectionProps } from '~/types'
import { PortableText } from './PortableText'
import { Section } from './Section'

export const SimpleSection: FunctionComponent<SimpleSectionProps> = ({ content, settings = {} }) => {
	return (
		<Section {...settings}>
			<PortableText blocks={content} />
		</Section>
	)
}
