import { V2 } from '@nomonosound/gravity'
import Link from 'next/link'
import type { AnchorHTMLAttributes, FunctionComponent, PropsWithChildren } from 'react'
import type { Link as SanityLink, StyledProps } from '~/types'
import { cn, getLinkPath, getLinkText } from '~/utils'

export interface MaterializedSanityLink extends SanityLink {
	page: {
		_id: string
		title: string
		slug?: { _type: 'slug'; current: string }
	}
}

export interface ButtonProps extends PropsWithChildren, StyledProps<'button'>, V2.ButtonParams {
	to?: MaterializedSanityLink | string
	target?: AnchorHTMLAttributes<HTMLAnchorElement>['target']
	variant?: V2.ButtonParams['variant']
}

export const Button: FunctionComponent<ButtonProps> = ({ to, variant = 'primary', children, className, ...props }) => {
	if (to) {
		const link = typeof to === 'string' ? { text: children as string, staticPath: to } : to
		const url = getLinkPath(link)
		if (url) {
			// TODO: Remove ! from disabled classname when button properly supports disabled state
			return (
				<Link href={url} aria-disabled={props.disabled} passHref prefetch={false}>
					<V2.Button
						onClick={(e) => (props.disabled ? e.stopPropagation() : props.onClick && props.onClick(e))}
						className={cn({ '!cursor-not-allowed !bg-neutral-300 !text-white': props.disabled }, className)}
						variant={variant}
						as={props.disabled ? 'button' : 'a'}
						{...(props as any)}
					>
						{children ?? getLinkText(link)}
					</V2.Button>
				</Link>
			)
		}
	}
	return (
		<V2.Button
			onClick={(e) => (!props.disabled && props.onClick ? props.onClick(e) : undefined)}
			className={cn({ '!cursor-not-allowed !bg-neutral-300': props.disabled }, className)}
			variant={variant}
			{...props}
		>
			{children}
		</V2.Button>
	)
}
