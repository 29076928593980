import { screenSizes } from '@nomonosound/gravity'
import { forwardRef, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import type { Except } from 'type-fest'
import type { VideoAsset } from '~/types'
import { type HTMLElementProps, type Media, type Nullable, type PageWidth, type SectionSettings } from '~/types'
import { ThemeProvider, getBackgroundColor, getColor, useTheme, useWindowSize } from '~/utils'
import type { ImageUrlOptions } from '../Image'
import { imageUrl } from '../Image'
import { useMedia } from '../Media'
import { ScrollDownButton } from '../ScrollDownButton'
import { BreakoutCss, Container } from '../layout/Containers'
import { useHeaderHeight } from '../layout/Header'
import { MuxVideoPlayer } from './EmbeddedVideo/VideoPlayer'
import { IdContext } from './IdContext'

function toSpacingUnit(value: Nullable<string | number>) {
	if (value == null) return '4rem'
	if (value.toString().match(/[a-z]/)) return value
	else return `${value}rem`
}

function getMobileImage(media: Media | undefined, options?: ImageUrlOptions): string {
	if (!media?.length) return ''
	if (media[1]?._type === 'image') return imageUrl(media[1].asset, options)
	if (media[3]?._type === 'image') return imageUrl(media[3].asset, options)
	if (media[0]?._type === 'image') return imageUrl(media[0].asset, options)
	if (media[2]?._type === 'image') return imageUrl(media[2].asset, options)
	return ''
}

const useCss = (p: SectionProps) => p.pageWidth !== 'none'

const StyledSection = styled.section<SectionProps>`
	${(p) => (useCss(p) ? BreakoutCss : '')};
	color: ${getColor};
	background-color: ${getBackgroundColor};
	text-align: ${(p) => p.textAlign ?? 'inherit'};

	${(p) => {
		return {
			...getPaddings(p.padding?.top, p.padding?.bottom, p.padding?.left, p.padding?.right),
			...getCalculatedMarginsAndWidth(
				p.margin?.top,
				p.margin?.bottom,
				p.margin?.left,
				p.margin?.right,
				p.windowInnerWidth
			),
			minHeight:
				p.height?.desktopHeight === 'windowHeight'
					? `calc(100vh - ${p.headerHeight}px)`
					: p.height?.customDesktopHeight,
			borderRadius: p.roundedCorners?.desktop ? '1rem' : '0'
		}
	}};

	// mobile
	// ------

	// mobile defaults (when desktop values are not to be used)
	@media (max-width: ${() => screenSizes.small}) {
		${() => {
			return {
				paddingLeft: 0,
				paddingRight: 0
			}
		}};
	}

	// mobile specifics
	@media (max-width: ${(p) => (p.height?.mobileHeight ? screenSizes.small : 0)}) {
		${(p) => {
			return {
				minHeight:
					p.height?.mobileHeight === 'windowHeight'
						? `calc(100svh - ${p.headerHeight}px)`
						: p.height?.customMobileHeight
			}
		}};
	}
	@media (max-width: ${(p) => (p.padding?.mobile ? screenSizes.small : 0)}) {
		${(p) => {
			return getPaddings(
				p.padding?.topMobile ?? p.padding?.top,
				p.padding?.bottomMobile ?? p.padding?.bottom,
				p.padding?.leftMobile ?? p.padding?.left,
				p.padding?.rightMobile ?? p.padding?.right
			)
		}};
	}
	@media (max-width: ${(p) => (p.margin?.mobile ? screenSizes.small : 0)}) {
		${(p) => {
			return getCalculatedMarginsAndWidth(
				p.margin?.topMobile ?? p.margin?.top,
				p.margin?.bottomMobile ?? p.margin?.bottom,
				p.margin?.leftMobile ?? p.margin?.left,
				p.margin?.rightMobile ?? p.margin?.right,
				p.windowInnerWidth
			)
		}};
	}
	@media (max-width: ${() => screenSizes.small}) {
		${(p) => {
			return {
				borderRadius: p.roundedCorners?.mobile ? '1rem' : '0'
			}
		}};
	}

	background-image: url(${({ backgroundMedia: bm, showBackgroundImage }) =>
		showBackgroundImage
			? ''
			: bm && bm[0]?._type === 'image'
			? imageUrl(bm[0].asset)
			: bm && bm[2]?._type === 'image'
			? imageUrl(bm[2].asset)
			: ''});

	@media (max-width: ${() => screenSizes.small}) {
		background-image: url(${(p) => (p.showBackgroundImage ? '' : getMobileImage(p.backgroundMedia))});
	}

	// Inner sectiom styles workaround
	// -------------------------------
	// TODO: move these to InnerSection (had problems overriding a ForwardRef<styled component> (Section) with a styled component (InnerSection))
	${(p) => {
		if (p.isInnerSection) {
			return {
				// no left/right-auto center-magic on inner sections
				left: 0,
				right: 0,
				marginLeft: p.margin?.left ?? 0,
				marginRight: p.margin?.right ?? 0,

				// parent element takes care of the spacing (padding)
				marginTop: p.margin?.top ?? 0,
				marginBottom: p.margin?.bottom ?? 0
			}
		}
	}}
	@media (max-width: ${(p) => (p.isInnerSection ? screenSizes.small : 0)}) {
		${(p) => {
			return {
				// no left/right-auto center-magic on inner sections
				marginLeft: p.margin?.leftMobile ?? 0,
				marginRight: p.margin?.rightMobile ?? 0,

				// parent element takes care of the spacing (padding)
				marginTop: p.margin?.topMobile ?? 0,
				marginBottom: p.margin?.bottomMobile ?? 0
			}
		}};
	}
`

const getCalculatedMarginsAndWidth = (
	top: string | undefined,
	bottom: string | undefined,
	left: string | undefined,
	right: string | undefined,
	windowInnerWidth: number
) => {
	return {
		marginTop: toSpacingUnit(top ?? 0),
		marginBottom: toSpacingUnit(bottom ?? 0),

		// vw unit causes horizontal scrollbar, but we use it until useEffect has given us the innerWidth
		marginLeft: `calc(-${windowInnerWidth ? `${windowInnerWidth / 2}px` : '50vw'} + ${toSpacingUnit(
			left ?? '0px'
		)})`,
		marginRight: `calc(-${windowInnerWidth ? `${windowInnerWidth / 2}px` : '50vw'} + ${toSpacingUnit(
			right ?? '0px'
		)})`,
		width: `calc(${windowInnerWidth ? `${windowInnerWidth}px` : '100vw'} - ${toSpacingUnit(
			left ?? '0px'
		)} - ${toSpacingUnit(right ?? '0px')})`
	}
}

const getPaddings = (
	top: string | undefined,
	bottom: string | undefined,
	left: string | undefined,
	right: string | undefined
) => {
	return {
		paddingTop: toSpacingUnit(top),
		paddingBottom: toSpacingUnit(bottom),
		paddingLeft: toSpacingUnit(left),
		paddingRight: toSpacingUnit(right)
	}
}

export interface SectionProps extends Except<SectionSettings, '_type' | 'pageWidth'>, HTMLElementProps<'section'> {
	pageWidth?: PageWidth | 'none'
	isInnerSection?: boolean // does not expand (using negative margins) outside parent element
	windowInnerWidth: number
	headerHeight: number
	showBackgroundImage: boolean
}

export const Section = forwardRef<
	HTMLElement,
	Except<SectionProps, 'ref' | 'windowInnerWidth' | 'headerHeight' | 'showBackgroundImage'>
>(
	(
		{
			htmlId,
			theme: current,
			themeSettings,
			children,
			backgroundPosition,
			enabled = true,
			backgroundSize = 'cover',
			id: propsId,
			className,
			padding,
			margin,
			...props
		},
		ref
	) => {
		const idContext = useContext(IdContext)
		const id = htmlId?.current ?? propsId ?? idContext
		const theme = useTheme({ current, settings: themeSettings })
		const { bodyWidth } = useWindowSize()
		const headerHeight = useHeaderHeight()

		const videoAsset = useMedia<VideoAsset>(props.backgroundMedia)
		const [hasMuxMedia, setHasMuxMedia] = useState(props.backgroundMedia?.[0]?._type === 'mux.video')

		useEffect(() => {
			setHasMuxMedia(props.backgroundMedia?.[0]?._type === 'mux.video' && !!videoAsset)
		}, [videoAsset, props.backgroundMedia])

		return enabled ? (
			<ThemeProvider theme={theme}>
				<StyledSection
					id={id}
					ref={ref}
					className={`${props.pageWidth && ['full', 'none'].includes(props.pageWidth) ? className : ''}`}
					padding={padding}
					margin={margin}
					{...props}
					style={
						props.backgroundMedia && !hasMuxMedia
							? { backgroundSize, backgroundPosition, backgroundRepeat: 'no-repeat', ...props.style }
							: props.style
					}
					windowInnerWidth={bodyWidth}
					headerHeight={headerHeight}
					showBackgroundImage={hasMuxMedia}
				>
					{hasMuxMedia && !!videoAsset && (
						<div className="absolute h-full w-full">
							<MuxVideoPlayer
								video={videoAsset}
								autoPlay={true}
								muted={true}
								loop={true}
								playsInline={true}
								className="h-full object-cover"
								style={
									{
										'--controls': 'none',
										'--media-object-fit': 'cover'
									} as React.CSSProperties
								}
								onError={() => setHasMuxMedia(false)}
								onAbort={() => setHasMuxMedia(false)}
							/>
						</div>
					)}
					{props.pageWidth !== 'full' ? (
						<Container
							pageWidth={
								props.pageWidth === 'medium' || props.pageWidth === 'small'
									? props.pageWidth
									: 'standard'
							}
							className={className}
						>
							{children}
						</Container>
					) : (
						<div className="w-full">{children}</div>
					)}
					<ScrollDownButton
						title={props.height?.scrollDownText}
						desktopEnabled={props.height?.desktopHeight === 'windowHeight' && props.height.scrollDownArrow}
						mobileEnabled={props.height?.mobileHeight === 'windowHeight' && props.height.scrollDownArrow}
						scrollDownTargetId={props.height?.scrollDownTargetId}
					/>
				</StyledSection>
			</ThemeProvider>
		) : null
	}
)
Section.displayName = 'Section'
