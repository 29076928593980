/* eslint-disable @next/next/no-img-element */
import { colors, screenSizes } from '@nomonosound/gravity'
import styled from 'styled-components'
import type { Theme } from '~/types'
import { getBorderColor } from '~/utils'

interface FooterBottomProps {
	theme: Theme
}

export const FooterBottom = styled.footer<FooterBottomProps>`
	border-top: 1px solid ${getBorderColor};
	color: ${colors.gray50};
	width: 100%;
	grid-column: 1 / -1;

	> * {
		margin: 1rem 0;
	}

	> ul > li > a {
		color: ${colors.gray50};

		&:hover {
			color: ${colors.white};
		}
	}

	@media (min-width: ${screenSizes.medium}) {
		display: block;
		margin-bottom: 1rem;
		display: flex;
		flex-wrap: wrap;

		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
		ul {
			border: none;
		}
	}

	> ul {
		display: flex;
		@media (min-width: ${screenSizes.medium}) {
			justify-items: end;
		}
		padding: 0;
		margin: 0;

		a {
			display: block;
			padding: 1em 0.5em;
			text-underline-offset: 0.25em;
			text-decoration: none;
			color: inherit;
		}

		@media (max-width: ${screenSizes.small}) {
			li:first-child {
				a {
					padding-left: 0;
				}
			}
		}

		li:last-child {
			a {
				padding-right: 0;
			}
		}
	}
`
