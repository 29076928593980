import { V2 } from '@nomonosound/gravity'
import { useDrag } from '@use-gesture/react'
import { clamp } from 'lodash'
import { MouseEventHandler, useEffect, useRef, useState } from 'react'
import { animated, useSprings } from 'react-spring'
import type { HTMLElementProps, Media, MediaType } from '~/types'
import { cn, isTouchDevice, useWindowSize } from '~/utils'
import { imageUrl } from '../Image'

export const ChangeSlide: React.FC<HTMLElementProps<'button'>> = ({ color, className, children, ...props }) => (
	<button
		className={cn('fill:stroke-current group absolute top-0 h-full w-20 rounded-none', color, className)}
		{...props}
	>
		<div className="svg:h-8 bg-bc-emphasis group-hover:bg-bc-hover mx-auto inline-block rounded p-2 text-black">
			{children}
		</div>
	</button>
)

export type Props = HTMLElementProps<'div'> & {
	images: MediaType[]
	index: number
	className?: string
	onUpdate: (i: number) => void
	background?: 'cover' | 'contain' | 'backdrop'
	onTripleClick?: MouseEventHandler
	showControls?: boolean
	controlsColor?: string
}
export const Slideshow: React.FC<Props> = ({
	images,
	index = 0,
	className,
	onUpdate,
	onClick,
	onDoubleClick,
	onTripleClick,
	onKeyDown,
	children,
	showControls = true,
	controlsColor = 'text-gray-800',
	background = 'contain',
	...props
}) => {
	const ref = useRef<HTMLDivElement | null>(null)
	const [dragging, setDragging] = useState(false)
	const windowWidth = useWindowSize().width
	const width = ref.current?.clientWidth || windowWidth || 9999
	const { isMobile } = useWindowSize()

	useEffect(() => {
		onUpdate(clamp(index, 0, images.length - 1))
	}, [index, onUpdate, images])

	const [springs, api] = useSprings(
		images.length,
		(i) => ({
			x: (i - index) * width,
			scale: 1
		}),
		[width, windowWidth, index]
	)

	const bind = useDrag(
		({ active, movement: [mx], direction: [xDir], cancel }) => {
			if (active && Math.abs(mx) > width / 6) {
				setDragging(false)
				onUpdate(index + (xDir > 0 ? -1 : 1))
				cancel()
			}
			api.start((i) => {
				setDragging(true)
				const x = (i - index) * width + (active ? mx : 0)
				const scale = active ? 1 - Math.abs(mx) / width : 1
				return { x, scale }
			})
		},
		{ threshold: 5 }
	)

	const handleKey: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
		if (e.key == 'ArrowLeft') onUpdate(index - 1)
		else if (e.key == 'ArrowRight') onUpdate(index + 1)
		if (onKeyDown) onKeyDown(e)
	}

	return (
		<div
			ref={ref}
			className={cn('relative aspect-square cursor-move', className)}
			onKeyDown={handleKey}
			onClick={(e) => e.detail === 3 && onTripleClick && onTripleClick(e)}
			tabIndex={0}
			{...props}
		>
			{!isTouchDevice() && (
				<input onKeyDown={handleKey} className="m-0 block h-0 cursor-default border-none p-0 opacity-0" />
			)}
			{springs.map(({ x, scale }, i) => {
				const image = images[i]
				return (
					<animated.div
						className="absolute h-full w-full touch-pan-y"
						{...bind()}
						key={getKey(images, i)}
						style={{ x }}
					>
						<animated.div
							onClick={(e) => !dragging && onClick && onClick(e)}
							onMouseUp={(_) => {
								// Prevent onClick handler from firing while dragging
								setTimeout(() => setDragging(false), 0)
							}}
							onDoubleClick={onDoubleClick}
							className={cn(
								'absolute inset-0 touch-pan-y bg-center bg-no-repeat ',
								background === 'cover' ? 'bg-cover' : 'bg-contain'
							)}
							style={{ scale, backgroundImage: `url(${imageUrl(image)})` }}
							role="img"
							aria-label={'alt' in image ? image.alt : undefined}
						/>
					</animated.div>
				)
			})}
			<div
				className="contents"
				onClick={(e) => {
					e.stopPropagation()
					e.preventDefault()
					e.nativeEvent.stopImmediatePropagation()
				}}
			>
				{showControls && images.length > 1 && (
					<>
						{!isMobile && (
							<>
								<ChangeSlide
									className="left-0"
									onClick={(_) => onUpdate(index - 1)}
									color={controlsColor}
									aria-label="Change slide left"
								>
									<V2.ChevronLeftLineIcon />
								</ChangeSlide>
								<ChangeSlide
									className="right-0"
									onClick={(_) => onUpdate(index + 1)}
									color={controlsColor}
									aria-label="Change slide right"
								>
									<V2.ChevronRightLineIcon />
								</ChangeSlide>
							</>
						)}
						<div className="absolute inset-x-0 bottom-4	text-center">
							{images.map((_, i) => (
								<span
									key={i}
									className={cn('m-1 inline-block h-2 w-2 rounded-full', {
										'bg-[#1F1F21]': i === index,
										'bg-[#1F1F2166]': i !== index
									})}
									onClick={() => onUpdate(i)}
								/>
							))}
						</div>
					</>
				)}
			</div>
			{children}
		</div>
	)
}

function getKey(images: Media, index: number): number | string {
	return images[index]?._key ?? index
}
