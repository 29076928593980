import type { FunctionComponent } from 'react'
import { Heading, Paragraph } from '~/components/FontStyles'
import type { CustomizableTable, SanityTable } from '~/types'
import { Table } from './TechSpecs.styled'

export const SpecsTable: FunctionComponent<CustomizableTable> = ({ table, ...props }) => {
	return (
		<Table {...props}>
			<tbody>
				{(table as SanityTable)?.rows?.map((row, y) => {
					return (
						<tr key={y}>
							{row.cells.map((cell, x) =>
								x > 0 ? (
									<td key={x}>
										<Paragraph type="bodySm">{cell}</Paragraph>
									</td>
								) : (
									<th key={x}>
										<Heading type="titleSm">{cell}</Heading>
									</th>
								)
							)}
						</tr>
					)
				})}
			</tbody>
		</Table>
	)
}
