import { DarkTheme, LightTheme } from '@nomonosound/gravity'
import { isObject } from 'lodash'
import Link from 'next/link'
import type { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'
import type { Nullable, Page, PreviewData } from '~/types'
import { StyledTheme, ThemeProvider, getBackgroundColor, getColor, useIsomorphicEffect, useSettings } from '~/utils'
import { Container } from './Containers'
import { Footer } from './Footer/Footer'
import { Header } from './Header/Header'
import { PreviewMessage } from './PreviewMessage'

const SiteContainer = styled(Container)`
	flex: 1 0 100%;
`

const Wrapper = styled.div<DefaultLayoutProps>`
	color: ${getColor};
	background-color: ${getBackgroundColor};
	min-height: 100vh;
	max-width: 100vw;

	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
`

interface DefaultLayoutProps extends PropsWithChildren {
	className?: string
	preview?: Nullable<boolean | PreviewData>
	theme?: StyledTheme
	page?: Page
	overrideSiteTheme?: boolean
	noMargin?: boolean
	header?: ReactNode
	footer?: ReactNode
}

export const DefaultLayout: FunctionComponent<DefaultLayoutProps> = ({
	children,
	preview,
	className,
	header,
	footer,
	overrideSiteTheme = false,
	page,
	theme: pageTheme,
	...props
}) => {
	const settings = useSettings().siteSettings
	const siteTheme = settings.theme ?? 'light'
	const theme = !pageTheme || pageTheme.current === 'inherit' ? siteTheme : pageTheme.current
	const GravityThemeProvider = theme === 'dark' ? DarkTheme : LightTheme
	useIsomorphicEffect(() => {
		document.body.style.backgroundColor = getBackgroundColor({ theme })
	}, [theme])

	return (
		<Outer className={`theme--${theme} ${className}`}>
			<div className="dark-theme">
				<GravityThemeProvider>
					<ThemeProvider theme={{ current: theme, settings: pageTheme?.settings ?? null }}>
						{preview && (
							<PreviewMessage className="space-x-1">
								<span>Previewing</span>
								{isObject(preview) && (
									<>
										<span>dataset</span>
										<code>{preview.dataset ?? 'unknown'}</code>
									</>
								)}
								<Link href="/api/preview?cancel=true" prefetch={false}>
									<a>Cancel</a>
								</Link>
							</PreviewMessage>
						)}
						<Wrapper>
							{header !== undefined ? (
								header
							) : (
								<Header
									{...props}
									theme={overrideSiteTheme ? theme : undefined}
									page={page}
									headerItems={settings.headerItems}
								/>
							)}
							<div id="hubspotForm" />
							<SiteContainer>
								<main id="main-content" className="[&>*]:scroll-mt-16">
									{children}
								</main>
							</SiteContainer>
							{footer !== undefined ? footer : <Footer theme={overrideSiteTheme ? theme : undefined} />}
						</Wrapper>
					</ThemeProvider>
				</GravityThemeProvider>
			</div>
		</Outer>
	)
}

const Outer = styled.div`
	position: relative;
	color: ${getColor};
	background-color: ${getBackgroundColor};
`
