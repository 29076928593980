import { screenSizes } from '@nomonosound/gravity'
import styled from 'styled-components'
import { CustomizableTable } from '../PortableText'

export const Table = styled(CustomizableTable)`
	padding: 0;
	margin: 0;
	border: 0;
	border-collapse: collapse;
	font-size: 16px;
	line-height: 1.4;
	width: 100%;

	th,
	td {
		vertical-align: top;
		padding: 8px 24px 8px 0;
		width: 50%;
	}

	p {
		margin: 0;
	}

	p + p {
		margin-top: 4px;
	}

	@media (max-width: ${screenSizes.small}) {
		th,
		td,
		tr {
			display: block;
			width: 100%;
			padding: 0;
		}
		td {
			margin-top: 4px;
		}
		tr {
			margin-bottom: 16px;
		}
	}
`
