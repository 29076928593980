import type { PortableTextMarkComponentProps } from '@portabletext/react'
import { captureException } from '@sentry/nextjs'
import type { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import type { Nullable, PriceDetails, PricingValue, SanityObject, SanityRef, State } from '~/types'
import { displayPrice, getPriceDetails, isValidFormDetails, useCoupon, useMarket, useProduct } from '~/utils'
import type { Serializers } from '../blocks/PortableText/types'

export interface VariableMarkDef extends SanityObject {
	type?: PricingValue
	source?: SanityRef
}

export interface VariableMarkProps extends PortableTextMarkComponentProps<VariableMarkDef> {
	markType: keyof Serializers['marks']
	prices: Nullable<PriceDetails>
}

export const VariableMark: FunctionComponent<VariableMarkProps> = ({ text, children, markType, value, ...props }) => {
	const product = useProduct()
	const market = useMarket()
	const isBusiness = useSelector((state: State) =>
		isValidFormDetails(state.checkout.details.billing) ? state.checkout.details.billing.isCompany : true
	)
	const coupon = useCoupon(value?.type === 'coupon')
	if (!markType) return null

	const prices =
		props.prices ?? (product ? getPriceDetails({ product, quantity: 1 }, coupon ? [coupon] : [], market) : null)
	if (markType === 'variable' && value) {
		if (!prices) return <>{text || children}</>
		switch (value.type) {
			case undefined:
				captureException(new Error(`Expected Mark of type ${markType} to contain .type, but got ${value.type}`))
				return null

			case 'coupon':
				if (!coupon) return null
				return <>{coupon.name}</>

			case 'zeroTaxReason':
				return (
					<>
						{prices.tax
							? null
							: isBusiness
							? market?.taxCalculationReason
							: market?.b2cTaxCalculationReason ?? market?.taxCalculationReason}
					</>
				)

			default:
				return <>{displayPrice(prices, value.type, market) || text || children}</>
		}
	} else {
		return <>{children}</>
	}
}
