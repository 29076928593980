import Script from 'next/script'
import { type FunctionComponent } from 'react'
import styled from 'styled-components'
import { useHubspotForm, useIsomorphicEffect, useSettings } from '~/utils'
import type { HubspotFormProps } from './HubspotForm'
import { legalConsentCss } from './HubspotForm'

const FormWrapped = styled.div`
	input:not([type='submit']):not([type='radio']):not([type='file']),
	select,
	textarea {
		border: 1px solid var(--base-neutral-100);
		border-radius: 0.5rem;
		padding: 0.5rem 1rem;

		width: 100% !important;
	}
	.hs-form .hs_email label {
		display: block;
	}

	fieldset {
		max-width: none !important;
		margin: 0.75rem 0 !important;

		&.form-columns-2 {
			display: flex;

			> div.hs-form-field {
				flex: 1;
				width: auto;
				float: none;
			}
		}
	}

	${legalConsentCss}
	.legal-consent-container {
		.hs-richtext > p {
			margin-left: 0;
			margin-right: 0;
		}
	}

	label {
		display: inline-block;
		margin-bottom: 0.25em;
	}

	h1 {
		font: var(--display-lg);
	}
	h2 {
		font: var(--display-lg);
		font-size: 36px;
	}
	h3 {
		font: var(--display-lg);
		font-size: 27px;
	}
	h4 {
		font: var(--heading-md);
	}
	h5 {
		font: var(--heading-sm);
	}

	.hs-form-radio-display {
		display: flex;
		align-items: center;
		input + span {
			margin-left: 0.25em;
		}
	}

	input[type='submit'] {
		width: 100%;
		padding: 0.625rem 1rem;
		border-radius: 0.5rem;
		color: var(--text-on-light);
		background: var(--primary);
	}
`

export const HubspotContactForm: FunctionComponent<HubspotFormProps> = ({ className, ...props }) => {
	// Get default settings from siteSettings and use those if values are not set
	const { siteSettings } = useSettings()

	const { region = 'eu1', formId, portalId, style } = { ...siteSettings?.hubspotNewsletterForm, ...props }
	const { form: settings, isLoading } = useHubspotForm(formId)
	if (!settings && !isLoading) throw new Error(`Could not find form with formId ${formId}`)

	if (!formId || !portalId) throw new Error('Missing required HubspotForm parameters')

	useIsomorphicEffect(() => {
		const interval = setInterval(() => {
			if (!window.hbspt?.forms) return
			window.hbspt.forms.create({ region, portalId, formId, target: `#hsForm_wrapper_${formId}` })
			clearInterval(interval)
		}, 100)
		return () => clearInterval(interval)
	}, [formId, portalId, region])

	useIsomorphicEffect(() => {
		const listener = async (event: MessageEvent) => {
			if (
				event.data.type === 'hsFormCallback' &&
				event.data.eventName === 'onFormSubmitted' &&
				event.data.id === 'c66c1de9-f9ae-420f-91ae-80eee6a56e4c'
			) {
				await fetch(
					`/api/hubspot/travelCoupon?email=${encodeURIComponent(event.data.data.submissionValues.email)}`,
					{
						method: 'GET',
						headers: { 'Content-Type': 'application/json' }
					}
				)
			}
		}

		window.addEventListener('message', listener)

		return () => window.removeEventListener('message', listener)
	}, [])

	return (
		<>
			<Script key="hs-form-sdk" type="text/javascript" src="//js-eu1.hsforms.net/forms/embed/v2.js" />
			<FormWrapped
				id={`hsForm_wrapper_${formId}`}
				className={`${className} style--${style} global-theme dark-theme`}
			>
				<div />
			</FormWrapped>
		</>
	)
}
