import { screenSizes } from '@nomonosound/gravity'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import styled from 'styled-components'
import type { IterableElement } from 'type-fest'
import type { Grid as GridProps, Justify } from '~/types'
import { ThemeProvider, cn, getLinkPath, getLinkText, useTheme } from '~/utils'
import { ResponsiveImage } from '../Image'
import { Card } from './Cards'
import { PortableText } from './PortableText'
import { IconItem } from './PortableText/IconList'

interface GridContainerProps {
	cols: number
	'data-type': `${GridProps['_type']}--${IterableElement<GridProps['list']>['_type']}`
	mobileMultiColumn?: boolean
	$justify?: Justify
	$justifyItems?: string
	mobileColNumber?: number
}

const GridContainer = styled.div<GridContainerProps>`
	display: grid;
	gap: ${(p) => (p['data-type'] === 'grid--iconItem' ? 1 : 2)}rem;
	justify-content: ${(p) => p.$justify};
	justify-items: ${(p) => p.$justifyItems};
	grid-template-columns: repeat(${(p) => p.mobileColNumber}, ${(p) => (p.$justify ? 'auto' : 'minmax(0, 1fr)')});
	@media (min-width: ${(p) => (p.mobileMultiColumn ? 0 : screenSizes.small)}) {
		grid-template-columns: repeat(${(p) => p.cols}, ${(p) => (p.$justify ? 'auto' : 'minmax(0, 1fr)')});
	}
`

export const Grid: FunctionComponent<GridProps> = ({
	_type,
	list = [],
	theme: override,
	columns,
	itemClass,
	justify,
	justifyItems,
	mobileMultiColumn,
	mobileColNumber
}) => {
	const theme = useTheme(override)
	return (
		<ThemeProvider theme={theme}>
			<GridContainer
				cols={columns ?? list.length}
				data-type={`${_type}--${list[0]?._type}`}
				mobileMultiColumn={mobileMultiColumn}
				$justify={justify}
				$justifyItems={justifyItems}
				mobileColNumber={mobileColNumber}
			>
				{list.map((item) =>
					item._type === 'link' ? (
						<Link key={item._key} href={getLinkPath(item) ?? '#'} passHref prefetch={false}>
							<a
								className="relative block"
								{...(item.openInNewWindow
									? { target: '_blank', rel: 'noopener noreferrer nofollow' }
									: {})}
							>
								{item.image && <ResponsiveImage layout="auto" image={item.image} />}
								{item.text && (
									<span className="absolute inset-0 z-10 flex items-center justify-center p-4">
										<span className="text-xl text-white">{getLinkText(item)}</span>
									</span>
								)}
							</a>
						</Link>
					) : item._type === 'card' ? (
						<Card key={item._key} {...item} />
					) : item._type === 'iconItem' ? (
						<IconItem key={item._key} className={cn('gap-x-4 rounded-2xl p-6', itemClass)} {...item} />
					) : item._type === 'dynamicContent' ? (
						<PortableText key={item._key} {...item} blocks={item.content} />
					) : null
				)}
			</GridContainer>
		</ThemeProvider>
	)
}
