import { V2 } from '@nomonosound/gravity'
import { type FunctionComponent, type PropsWithChildren } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import styled from 'styled-components'
import type { Except } from 'type-fest'
import type { StyledProps } from '~/types'
import { useWindowSize } from '~/utils'
import { Paragraph } from './FontStyles'
import { useHeaderHeight } from './layout/Header'

interface ScrollDownButtonProps extends PropsWithChildren, Except<StyledProps<'button'>, 'onClick'>, V2.ButtonParams {
	title?: string
	scrollDistance?: number
	desktopEnabled?: boolean
	mobileEnabled?: boolean
	scrollDownTargetId?: string
}

const AnimateDown = styled.div`
	animation: sdb04 2s infinite;

	@keyframes sdb04 {
		0% {
			transform: translate(0, 0);
		}
		20% {
			transform: translate(0, 10px);
		}
		40% {
			transform: translate(0, 0);
		}
	}
`
/**
 * TODO: Consider native scrolling, see https://codingbeautydev.com/blog/react-scroll-to-element/?expand_article=1
 *       The problem is to target correct nested element as we do have inner sections as well.
 *
 * Scroll to target if it's set and it exists, otherwise scroll by distance.
 *
 * @param props
 * @returns
 */
export const ScrollDownButton: FunctionComponent<ScrollDownButtonProps> = (props) => {
	const { isMobile, height } = useWindowSize()
	const headerHeight = useHeaderHeight()

	const scrollPx = props.scrollDistance || height - headerHeight

	const shouldScroll = scrollPx || props.scrollDownTargetId

	function doScroll() {
		// scroll to target if it exists
		if (props.scrollDownTargetId) {
			const target = document.querySelector(`#${props.scrollDownTargetId}`)
			if (target) {
				target.scrollIntoView({ behavior: 'smooth' })
				return
			}
		}

		// fallback, scroll by distance
		scroll.scrollMore(scrollPx)
	}

	return ((isMobile && props.mobileEnabled) || (!isMobile && props.desktopEnabled)) && shouldScroll ? (
		<div className="absolute left-[50%] bottom-[2rem] flex translate-x-[-50%] flex-col items-center md:bottom-[3rem]">
			{props.title && (
				<Paragraph type="bodyMd" className="mb-4 whitespace-nowrap">
					{props.title}
				</Paragraph>
			)}

			<V2.IconButton {...props} style={{ padding: '.5rem' }} onClick={() => doScroll()}>
				<AnimateDown>
					<svg width="24" height="24" viewBox="0 0 24 24" fillOpacity={0} xmlns="http://www.w3.org/2000/svg">
						<path
							d="M6 9L12 15L18 9"
							stroke="#F0F0F5"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</AnimateDown>
			</V2.IconButton>
		</div>
	) : null
}
