import { screenSizes } from '@nomonosound/gravity'
import useEmblaCarousel from 'embla-carousel-react'
import { useContext, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-cool-inview'
import styled from 'styled-components'
import { Breakout } from '~/components/layout/Containers'
import { getBackgroundColor, getColor } from '~/utils'
import { FeatureSection } from '../FeatureSection'
import { IdContext } from '../IdContext'
import { PortableText } from '../PortableText'
import { Testimonial } from './Testimonial'
import type { CarouselProps } from './common'

const Wrapper = styled.div`
	color: ${getColor};
	background-color: ${getBackgroundColor};

	.embla {
		position: relative;
		margin-left: auto;
		margin-right: auto;
	}

	.embla__viewport {
		overflow: hidden;
		width: 100%;
	}

	.embla__viewport {
		cursor: move;
		cursor: grab;
	}

	.embla__viewport.is-dragging {
		cursor: grabbing;
	}

	.embla__container {
		display: flex;
		user-select: none;
		-webkit-touch-callout: none;
		-khtml-user-select: none;
		-webkit-tap-highlight-color: transparent;
		margin-left: -10px;
	}

	.embla__slide {
		min-width: 100%;
		padding-left: 10px;
		overflow: hidden;

		@media (max-width: ${screenSizes.small}) {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		> * {
			max-height: 28rem;
			height: 100%;
		}
		> .featureSection > :last-child {
			padding-top: 0.5rem;
			padding-left: 1.25rem;
			@media (min-width: ${screenSizes.small}) {
				padding-left: 2.5rem;
			}
		}

		img {
			object-position: top left; // TODO: Should be fixed with sanity image hotspots
		}
	}
`

const ButtonsWrapper = styled.div`
	padding: 1.25rem;
	@media (min-width: ${screenSizes.small}) {
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 2.5rem;
	}

	> * + * {
		margin-left: 1rem;
	}

	button {
		color: ${getColor};
		background-color: transparent;
		border: 2px solid currentColor;
		border-radius: 999px;
		opacity: 0.65;
		:hover {
			opacity: 1;
		}
		&[aria-current] {
			opacity: 0.9;
		}

		font-size: 1.25rem;
		line-height: 0.5;
		padding: 0.6em;
		cursor: pointer;
	}
`

export const StepCarousel: React.FC<CarouselProps> = ({ testimonials, intro }) => {
	const StyledPortableText = styled(PortableText)`
		max-width: ${screenSizes.small};
		margin: auto;
		padding: 5rem 1.25rem 4rem;
		font-size: 1.5rem;
		text-align: center;
		line-height: 1.5;

		h2 {
			font-size: 3rem;
			line-height: 1;
			margin: 0;
		}
		> * + * {
			margin-top: 1rem;
			margin-bottom: 0;
		}
		em {
			font-style: normal;
			opacity: 0.7;
		}
	`

	const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false })
	const [currentSlides, setCurrentSlides] = useState(0)

	const wrapperRef = useRef<HTMLDivElement>(null)
	const { observe } = useInView({
		threshold: 0.05,
		onEnter: ({ unobserve }) => {
			if (wrapperRef.current) {
				wrapperRef.current.classList.add('cmp-in')
			}
			unobserve()
		}
	})

	useEffect(() => {
		if (!embla) return
		const onSelect = () => setCurrentSlides(embla.selectedScrollSnap())

		embla.on('select', onSelect)
		return () => {
			embla.off('select', onSelect)
		}
	}, [embla])

	const id = useContext(IdContext)
	return (
		<section ref={observe} id={id}>
			<Breakout>
				<Wrapper>
					<StyledPortableText blocks={intro} />
					<div ref={wrapperRef} className="embla cmp-out">
						<div className="embla__viewport" ref={viewportRef}>
							<div className="embla__container">
								{testimonials?.map((slide, i) => (
									<div
										className="embla__slide"
										key={slide._key}
										aria-current={currentSlides === i ? 'step' : undefined}
									>
										{slide._type === 'testamonial' ? (
											<Testimonial {...slide} />
										) : (
											<FeatureSection {...slide} imageLoading="eager" />
										)}
									</div>
								))}
							</div>
						</div>
					</div>
					<ButtonsWrapper>
						{testimonials?.map((_, i) => (
							<button
								key={i}
								onClick={() => embla?.scrollTo(i)}
								aria-current={currentSlides === i ? 'location' : undefined}
								aria-label={`Scroll to slide ${i + 1}`}
							>
								{i + 1}
							</button>
						))}
					</ButtonsWrapper>
				</Wrapper>
			</Breakout>
		</section>
	)
}
