import { V2 } from '@nomonosound/gravity'
import dynamic from 'next/dynamic'
import { useCallback, useState, type FunctionComponent } from 'react'
import styled from 'styled-components'
import { isPresent } from 'ts-extras'
import type { HeaderMenu } from '~/types'
import { cn, resolve, useSettings } from '~/utils'

// Has to be imported dynamically because createPortal doesn't work on server
const SideMenu = dynamic(async () => (await import('./SideMenu')).SideMenuPortal, { ssr: false })

const Icon = styled(V2.Icon)`
	height: auto;
	display: block;

	path {
		stroke: currentColor !important;
	}
`

export const MenuButton: FunctionComponent<HeaderMenu> = (props) => {
	const opCount = useSettings().siteSettings.opCount
	const [menuOpen, toggleMenu] = useState<boolean>(false)

	const submenus = props.menus?.map(resolve).filter(isPresent)

	const handleCloseMenu = useCallback(() => {
		toggleMenu(!menuOpen)
	}, [menuOpen])

	return (
		<>
			<button
				className="relative flex items-center space-x-1"
				aria-label="Toggle expand menu"
				onClick={() => toggleMenu(!menuOpen)}
			>
				<span className="hidden lg:inline">{menuOpen ? 'Close' : props.title ?? 'Menu'}</span>
				<div className={cn('transition-transform', { 'rotate-90': menuOpen })}>
					{menuOpen ? <Icon icon="cross-line" /> : <Icon icon="menu-filled" />}
				</div>
			</button>
			<SideMenu open={menuOpen} handleCloseMenu={handleCloseMenu} items={submenus} opCount={opCount} />
		</>
	)
}
