import type { FunctionComponent, MouseEventHandler, PropsWithChildren } from 'react'
import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import type { HTMLElementProps } from '~/types'
import { cn, useTheme } from '~/utils'

interface Props {
	open?: boolean
	/** Set this to always render children even when open is false */
	alwaysRender?: boolean
	modal?: boolean | 'fullscreen'

	/** Note: Only in effect while modal is true */
	className?: HTMLElementProps['className']
	/** Note: Only in effect while modal is true */
	onClick?: MouseEventHandler<HTMLElement>
	/** Note: Only in effect while modal is true */
	onClose?: () => void
}

const Modal: FunctionComponent<PropsWithChildren<Props>> = ({
	className,
	modal: mode,
	onClick,
	onClose,
	children,
	...props
}) => {
	const theme = useTheme()
	const ref = useRef<HTMLDivElement>(null)
	useEffect(() => {
		const modal = ref.current
		function onKey(e: KeyboardEvent) {
			if (e.key === 'Escape' && onClose) onClose()
			if (!modal) return
			const focusableElements: NodeListOf<HTMLElement> = modal.querySelectorAll(
				'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
			)
			const first = focusableElements[0]
			const last = focusableElements[focusableElements.length - 1]

			if (e.key !== 'Tab') return
			if (e.shiftKey) {
				if (document.activeElement === first) {
					last.focus()
					e.preventDefault()
				}
			} else {
				if (document.activeElement === last) {
					first.focus()
					e.preventDefault()
				}
			}
		}
		modal?.addEventListener('keydown', onKey)
		return () => {
			modal?.removeEventListener('keydown', onKey)
		}
	})
	return (
		<div
			className={cn(`portal text-2xl ${theme} theme--${theme} global-theme`)}
			aria-modal
			role="dialog"
			tabIndex={-1}
			{...props}
			ref={ref}
		>
			<div className="pointer-events-none absolute inset-0 bottom-[initial] z-40 flex items-center justify-center">
				<div
					className={cn(
						'container pointer-events-auto',
						'dark:bg-gray-950 bg-white  dark:text-gray-100',
						mode === 'fullscreen'
							? 'h-full w-full max-w-none'
							: 'max-h-full p-8 sm:max-w-4xl sm:rounded-xl md:max-h-[90vh] xl:max-w-6xl',
						className
					)}
				>
					{children}
				</div>
			</div>
			<div className="absolute inset-0 z-30 bg-gray-200 opacity-70" onDoubleClick={onClick} />
		</div>
	)
}

export const Portal: React.FC<PropsWithChildren<Props>> = ({ open, alwaysRender, ...props }) => {
	useEffect(() => {
		if (open === true) document.body.classList.add('overflow-hidden')
		else document.body.classList.remove('overflow-hidden')
		return () => document.body.classList.remove('overflow-hidden')
	}, [open])

	if (!open && !alwaysRender) return null
	else return createPortal(props.modal ? <Modal {...props} /> : <>{props.children}</>, document.body)
}
