import { colors, screenSizes } from '@nomonosound/gravity'
import type { UseNextSanityImageOptions } from 'next-sanity-image'
import styled from 'styled-components'
import { PortableText } from '~/components/blocks'
import { DefaultImage } from '~/components/Image'
import type { Testamonial as TestimonialProps } from '~/types'

const TestimonialWrapper = styled.article`
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: space-between;
	padding-left: 2rem;

	@media (max-width: ${screenSizes.medium}) {
		flex-direction: column;
		padding-left: 0;
		justify-content: space-between;
		height: 100%;
	}
`

const Quote = styled.div`
	font-size: 3.5rem;
	line-height: 4rem;
	max-width: 737px;
	width: 60%;
	margin-right: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	p {
		font-weight: 400;
		margin: 2rem 0 0 0;
	}
	strong {
		font-weight: 600;
	}

	.quotee {
		font-size: 1.125rem;
		line-height: 1.75rem;
		padding-top: 1rem;
		display: flex;
		align-items: center;
		p {
			margin: 0;
		}
		.name {
			font-weight: bold;
		}
		.occupation {
			color: #4e4e52;
		}
	}
	@media (max-width: ${screenSizes.large}) {
		font-size: 2.5rem;
		line-height: 3rem;
	}
	@media (max-width: ${screenSizes.medium}) {
		width: 100%;
		max-width: none;
		font-size: 2rem;
		line-height: 2.5rem;
		p,
		strong {
			font-weight: 500;
			margin: 0 0 1rem 0;
		}
		.quotee {
			padding: 0 0 2rem 0;
			.occupation {
				color: ${colors.gray70};
			}
		}
	}
`

const ImageContainer = styled.figure`
	margin: 0;
	width: 31rem;
	height: 25rem;
	border-radius: 1rem;
	overflow: hidden;
	span,
	img {
		width: 100% !important;
		height: 100% !important;
	}
	@media (max-width: ${screenSizes.medium}) {
		width: 100%;
		max-height: 17.1rem;
	}
`

const LogoWrapper = styled.figure`
	width: 3.5rem;
	height: 3.5rem;
	margin: 0;
	margin-right: 1rem;
`

export const Testimonial: React.FC<TestimonialProps> = ({ occupation, name, image, quote, logo }) => {
	const options: UseNextSanityImageOptions = {
		imageBuilder: (imageUrlBuilder, opts) => {
			const newAspect = (imageUrlBuilder.options?.source as any)?.newAspect
			const { width } = opts

			const height = width && Math.round(width / newAspect)
			if (width && height) {
				return imageUrlBuilder.width(width).height(height)
			}
			return imageUrlBuilder
		}
	}

	return (
		<TestimonialWrapper>
			<Quote>
				<PortableText blocks={quote} />
				<div className="quotee">
					{logo && (
						<LogoWrapper>
							<DefaultImage
								image={logo}
								alt="Company logo"
								objectFit="cover"
								options={options}
								width={56}
								height={56}
								unoptimized={true}
							/>
						</LogoWrapper>
					)}
					<div>
						<p className="name">{name}</p>
						<p className="occupation">{occupation}</p>
					</div>
				</div>
			</Quote>
			{image && (
				<ImageContainer>
					<DefaultImage
						image={image}
						aspectRatio={3 / 4}
						alt="Image"
						objectFit="cover"
						options={options}
						sizes="(max-width: 400px) 100vw, 400px"
						unoptimized={true}
					/>
				</ImageContainer>
			)}
		</TestimonialWrapper>
	)
}
