import type { FunctionComponent, PropsWithChildren } from 'react'
import styled from 'styled-components'
import type { CustomizableTable as CustomizableTableType, SanityTable } from '~/types'

const Table = styled.table<Omit<CustomizableTableProps, '_type'>>`
	text-align: ${(p) => p.textAlign ?? 'inherit'};
`

export interface CustomizableTableProps extends PropsWithChildren<CustomizableTableType> {
	table?: SanityTable
}

export const CustomizableTable: FunctionComponent<CustomizableTableProps> = ({
	table,
	children,
	hasHeaderRow,
	sortable: _,
	...props
}) => {
	const rows = hasHeaderRow ? table?.rows.slice(1) : table?.rows
	const headerRow = hasHeaderRow ? table?.rows[0] : null
	return (
		<Table {...props}>
			{children}
			{table && (
				<tbody>
					{headerRow && (
						<tr>
							{headerRow.cells.map((cell, i) => (
								<th key={i}>{cell}</th>
							))}
						</tr>
					)}
					{rows?.map((row) => (
						<tr key={row._key}>
							{row.cells.map((cell, i) => (
								<td key={i}>{cell}</td>
							))}
						</tr>
					))}
				</tbody>
			)}
		</Table>
	)
}
