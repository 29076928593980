import { FC, PropsWithChildren, useRef, useState } from 'react'
import styled from 'styled-components'
import type { YoutubeVideo as YoutubeVideoProps } from '~/types'
import { assetUrl, useIsomorphicEffect, useOnScreen } from '~/utils'

const IFrame = styled.iframe`
	margin-left: auto;
	margin-right: auto;
	border-radius: 1rem;
	overflow: hidden;
	aspect-ratio: ${(p) => (p.height ? `${p.width} / ${p.height}` : '16 / 9')};
	height: auto;
	max-width: 100%;
	width: ${(p) => p.width}px;
`

const Img = styled.img`
	margin-left: auto;
	margin-right: auto;
	border-radius: 1rem;
	overflow: hidden;
	aspect-ratio: ${(p) => (p.height ? `${p.width} / ${p.height}` : '16 / 9')};
	height: auto;
	max-width: 100%;
	width: ${(p) => p.width}px;
`

const styleProps = { width: '100%', height: '56.25%' }

// this is very similar at the moment with the Vimeo Video (ExternalVideo component)
// but I expect things will diverge more in the future
export const YoutubeVideo: FC<PropsWithChildren<YoutubeVideoProps>> = ({ width, height, children, ...props }) => {
	const [isResponsive, setIsResponsive] = useState(false)
	const containerRef = useRef<HTMLDivElement>(null)
	const lockRef = useRef(false)
	const isOnScreen = useOnScreen(containerRef) // used to lazy load youtube iframes (they don't work out of the box)

	if (isOnScreen && !lockRef.current) {
		lockRef.current = true
	}

	// Prevent hydration errors.
	useIsomorphicEffect(() => {
		setIsResponsive(!width && !height)
	}, [width, height])

	const url = assetUrl(props)

	const responsiveProps = isResponsive
		? { className: 'absolute top-0 left-0 h-full w-full' }
		: {
				width,
				height: height ?? (width ? Math.round(Number.parseInt(width) / (16 / 9)) : undefined)
		  }

	const IframeVideoComponent = (
		<IFrame
			src={url}
			title="YouTube video player"
			frameBorder="0"
			allowFullScreen
			{...responsiveProps}
			loading="lazy"
		/>
	)

	const PlaceholderImageComponent = <Img {...responsiveProps} />

	return (
		<div ref={containerRef} style={styleProps}>
			{lockRef.current ? (
				<>
					{isResponsive ? (
						<div className="relative pt-[56.25%]">{IframeVideoComponent}</div>
					) : (
						{ IframeVideoComponent }
					)}
					{children}
				</>
			) : isResponsive ? (
				<div className="relative pt-[56.25%]">{PlaceholderImageComponent}</div>
			) : (
				<>{PlaceholderImageComponent}</>
			)}
		</div>
	)
}
