import { screenSizes } from '@nomonosound/gravity'
import { PortableText as SanityPortableText, PortableTextProps as SanityPortableTextProps } from '@portabletext/react'
import type { FunctionComponent, PropsWithChildren } from 'react'
import styled from 'styled-components'
import type { DefaultPortableText, Nullable, StyledProps } from '~/types'
import { IdContext } from '../IdContext'
import { serializers } from './serializers'

export interface PortableTextProps extends Omit<SanityPortableTextProps, 'value'>, StyledProps<'div'> {
	className?: string
	blocks: Nullable<DefaultPortableText>
	wrapper?: boolean
}

// TODO: Use https://tailwindcss.com/docs/typography-plugin#element-modifiers for Sanity content
const PortableTextWrapper = styled.div`
	> p {
		&:not(:first-child) {
			margin-top: 1em;
			margin-bottom: 1em;
		}
		> .button,
		> button {
			display: inline-block;
			width: 100%;
			max-width: 100%;
			text-align: center;

			@media (min-width: ${screenSizes.small}) {
				width: auto;
				min-width: 25rem;
				padding-left: 5em;
				padding-right: 5em;
			}
		}
	}

	> ul {
		list-style-type: disc;
		padding-left: 1em;
	}
`

export const PortableText: FunctionComponent<PropsWithChildren<PortableTextProps>> = ({
	wrapper = true,
	blocks,
	className,
	children,
	...props
}) => {
	if (!blocks) return null

	return wrapper ? (
		<PortableTextWrapper className={`portableText ${className}`} {...props}>
			{/** Don't provide ID to portable text components as they are not standalone sections */}
			<IdContext.Provider value="">
				<SanityPortableText value={blocks} components={serializers} {...props} />
				{children}
			</IdContext.Provider>
		</PortableTextWrapper>
	) : (
		<IdContext.Provider value="">
			<SanityPortableText value={blocks} components={serializers} {...props} />
			{children}
		</IdContext.Provider>
	)
}
