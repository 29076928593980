import { V2 } from '@nomonosound/gravity'
import Script from 'next/script'
import { FunctionComponent, useEffect } from 'react'
import { css } from 'styled-components'
import { getPriceDetails, useCountry, useCoupon, useMarket, useProduct } from '~/utils'

export interface KlarnaPlacementProps extends Partial<JSX.IntrinsicElements['klarna-placement']> {
	price?: number
}

const style = css`
	klarna-placement[data-key='credit-promotion-badge'] {
		display: inline;
		height: 1.75rem;
	}

	klarna-placement[data-key='credit-promotion-badge']::part(osm-container) {
		height: 1.75rem;
		padding: 0;
		background: none;
		border: none;
		margin-left: -10px;
	}

	klarna-placement[data-key='credit-promotion-badge']::part(osm-badge) {
		display: none;
		/* width: auto; */
		/* height: 1.75rem; */
	}

	klarna-placement[data-key='credit-promotion-badge']::part(osm-cta),
	klarna-placement[data-key='credit-promotion-badge']::part(osm-message) {
		color: rgba(31, 31, 33, 0.67); /* is --text-muted in figma, but gravity has wrong color for that one */
		font-size: 13px;
		margin-top: -7px;
	}

	klarna-placement[data-key='credit-promotion-badge']::part(osm-message) {
		max-width: 20rem;
		line-height: 1.33;
	}

	klarna-placement[data-key='credit-promotion-badge']::part(osm-legal) {
		display: none;
	}
`

export const KlarnaPlacement: FunctionComponent<KlarnaPlacementProps> = (props) => {
	const product = useProduct()
	const coupon = useCoupon()
	const [country] = useCountry()
	const market = useMarket()
	const details = product ? getPriceDetails({ product, quantity: 1 }, coupon ? [coupon] : [], market) : null

	useEffect(() => {
		try {
			window.Klarna?.OnsiteMessaging?.refresh()
			// eslint-disable-next-line no-empty
		} catch {}
	}, [details?.total, country?.code])

	if (details?.total === 0) return <V2.KlarnaPaymentMethodsIcon />

	return (
		<>
			<Script
				async
				key="klarna-placement"
				// only production works, preview doesn't
				data-environment="production"
				src="https://js.klarna.com/web-sdk/v1/klarna.js"
				data-client-id="a1b0a133-2d72-58fc-b378-e5d9e9ec1213"
				strategy="afterInteractive"
			/>
			<V2.KlarnaPaymentMethodsIcon />
			<klarna-placement
				data-key="credit-promotion-badge"
				data-locale={`en-${country?.code.toUpperCase()}`}
				data-purchase-amount={(details?.total || 0) * 100}
				{...props}
			/>
			<style>{style.toString()}</style>
		</>
	)
}
