import { screenSizes } from '@nomonosound/gravity'
import type { FunctionComponent, ImgHTMLAttributes } from 'react'
import { useContext } from 'react'
import styled from 'styled-components'
import type { FeatureSection as FeatureSectionType, Theme } from '~/types'
import { styles } from '~/utils'
import { Media } from '../Media'
import { IdContext } from './IdContext'
import { PortableText } from './PortableText'
import { Section } from './Section'

type StyleProps = Partial<
	Pick<FeatureSectionType, 'imageStyle' | 'imageLayout' | 'mobileImageLayout' | 'verticalAlign'>
>

const Feature = styled.div<{ columns: string } & StyleProps>`
	padding: 0rem;

	display: grid;
	align-items: center;

	grid-template-rows: minmax(0, 100%);
	grid-auto-flow: row;
	grid-template-columns: 1fr;
	grid-gap: ${(p) => (p.imageStyle !== 'full' ? '1rem' : '0')};

	@media (min-width: ${screenSizes.small}) {
		grid-gap: ${(p) => (p.imageStyle !== 'full' ? '5rem' : '0')};
		grid-template-columns: ${(p) => p.columns};
		> * {
			grid-row-start: 1;
		}
	}
`

const ContentContainer = styled.div<StyleProps & { textContentWidth: number }>`
	grid-row-start: 2;
	align-self: ${(p) => p.verticalAlign};
	> * {
		margin: ${(p) => (p.verticalAlign === 'center' && p.imageStyle === 'full' ? 4 : 0)}rem 0;
	}

	grid-row-start: ${(p) => (p.mobileImageLayout === 'top' ? 2 : 1)};
	@media (min-width: ${screenSizes.small}) {
		${(p) => (p.imageStyle === 'full' ? `padding-${p.imageLayout}: 1.25rem` : '')};
		grid-row-start: 1;
		grid-column-start: ${(p) => (p.imageLayout === 'left' ? 2 : 1)};
	}
	@media (min-width: ${screenSizes.medium}) {
		padding-left: ${(p) => (p.textContentWidth <= 40 ? 2.5 : 5)}rem;
		padding-right: ${(p) => (p.textContentWidth <= 40 ? 2.5 : 5)}rem;
	}
`

const ImageContainer = styled.div<StyleProps>`
	margin: 0 -${(p) => (p.imageStyle !== 'rounded' ? styles.spacingMobile : 0)};

	grid-row-start: ${(p) => (p.mobileImageLayout === 'top' ? 1 : 2)};
	@media (min-width: ${screenSizes.small}) {
		margin-left: 0;
		grid-row-start: 1;
		grid-column-start: ${(p) => (p.imageLayout === 'left' ? 1 : 2)};
	}

	align-self: ${(p) => (p.imageStyle === 'full' ? 'stretch' : 'auto')};
	text-align: center;

	img,
	video {
		width: 100%;
		height: 100%;
		border-radius: ${(p) => (p.imageStyle === 'rounded' ? '1rem' : '0')};
		object-fit: ${(p) => (p.imageStyle === 'full' ? 'cover' : 'contain')};
		object-position: center;
	}
`
export interface FeatureSectionProps extends FeatureSectionType {
	/** @deprecated */
	theme?: Theme
	imageLoading?: ImgHTMLAttributes<any>['loading']
}
export const FeatureSection: FunctionComponent<FeatureSectionProps> = ({
	_type,
	imageLoading,
	imageLayout = 'right',
	mobileImageLayout,
	imageStyle = 'rounded',
	verticalAlign = 'center',
	textContentWidth = '50%',
	media,
	content,
	theme,
	settings
}) => {
	const TextContent = styled(PortableText)`
		margin-left: ${styles.spacingMobile};
		margin-right: ${styles.spacingMobile};
		@media (min-width: ${screenSizes.medium}) {
			max-width: 30rem;
			margin-right: auto;
			margin-left: auto;
		}

		> * {
			margin: 0.375em 0;
		}
		> [role='doc-subtitle'] {
			font-size: 0.8em;
		}
	`

	const numberWidth = Number.parseFloat(textContentWidth)
	const columns = numberWidth ? `${numberWidth}fr ${100 - numberWidth}fr` : `${textContentWidth} auto`
	const id = useContext(IdContext)
	return (
		<Section theme={theme} {...settings}>
			<Feature id={id} columns={columns} imageStyle={imageStyle} className={_type}>
				<ImageContainer mobileImageLayout={mobileImageLayout} imageLayout={imageLayout} imageStyle={imageStyle}>
					{media && (
						<Media
							media={media}
							loading={imageLoading}
							native
							layout={imageStyle === 'full' ? 'full' : undefined}
							height="min-content"
						/>
					)}
				</ImageContainer>
				<ContentContainer
					mobileImageLayout={mobileImageLayout}
					imageLayout={imageLayout}
					verticalAlign={verticalAlign}
					imageStyle={imageStyle}
					textContentWidth={numberWidth || 50}
				>
					<TextContent blocks={content} />
				</ContentContainer>
			</Feature>
		</Section>
	)
}
