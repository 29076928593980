import { V2 } from '@nomonosound/gravity'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import type { KbArticle, KbOverview, Nullable } from '~/types'
import { cn, resolve, useTheme } from '~/utils'
import { Heading } from '../FontStyles'
import { SanityLink } from '../SanityLink'

export const KnowledgeBaseOverview: FunctionComponent<KbOverview> = ({ type, categories }) => {
	const theme = useTheme()

	return type === 'grid-card' || type === 'grid-wide' ? (
		<ol className={cn('grid gap-8', type === 'grid-wide' ? 'sm:grid-cols-2' : 'sm:grid-cols-2 md:grid-cols-3')}>
			{categories?.map((category) => {
				const pageCategory = resolve(category?.ref)
				if (!pageCategory?.slug.current) return null
				return (
					<li key={category._key}>
						<Link href={pageCategory.slug.current} passHref prefetch={false}>
							<a
								className={cn(
									'hocus:shadow-lg block h-full cursor-pointer space-y-2 rounded-2xl transition-all',
									type === 'grid-wide' ? 'p-6' : 'p-8',
									{
										'bg-stone-200': theme.current === 'white',
										'bg-white': theme.current === 'light',
										'bg-brand-200 hocus:bg-brand-100 text-black': theme.current === 'dark'
									}
								)}
							>
								<V2.Icon icon={category.icon || pageCategory.icon || 'folder-open-line'} />
								<Heading type="headingSm" className="font-semibold">
									{category.title || pageCategory.title}
								</Heading>
								<p>{category.description}</p>
							</a>
						</Link>
					</li>
				)
			})}
		</ol>
	) : type === 'list' ? (
		<div>
			{categories?.map(({ _key, ref, title, description, showIcon = true, showTitle = true, ...category }) => {
				const pageCategory = resolve(ref)
				// Not normally present on type/document, added by contentQuery.ts
				const pages: Nullable<KbArticle[]> = (category as any).pages as any
				const icon = pageCategory?.icon || category.icon
				return (
					<article key={_key} className="relative">
						<a
							id={pageCategory?.slug?.current ?? pageCategory?.title}
							className="invisible absolute -top-20"
						/>
						{showTitle && (
							<>
								<Heading type="displayXs" className="mt-6 space-x-2">
									{icon && showIcon && <V2.Icon icon={icon} className="align-bottom" />}
									<span>{title || pageCategory?.title}</span>
								</Heading>
								{description && <p className="!mb-1">{description}</p>}
								<hr className="mb-3 !border-gray-400" />
							</>
						)}
						<ul className="space-y-0.5">
							{pages?.map((page) => (
								<li key={page._id} className="hover:underline">
									<SanityLink link={{ _type: 'link', pageReference: page }} />
								</li>
							))}
						</ul>
					</article>
				)
			})}
		</div>
	) : null
}
