import { screenSizes } from '@nomonosound/gravity'
import type { FunctionComponent } from 'react'
import styled from 'styled-components'
import { H2 } from '~/components/GeneralStyles'
import { SanityImage } from '~/components/Image'
import { PortableText } from '~/components/blocks'
import type { LogoSection as LogoSectionProps } from '~/types'
import { getSecondaryColor } from '~/utils'
import { Section } from '../Section'

const Heading = styled(H2)`
	text-align: center;
	margin-bottom: 4rem;
`

const HeadingSmall = styled.h2`
	margin-top: 0;
	font-size: 1rem;
	font-weight: normal;
	margin-bottom: 1.5rem;
	color: ${getSecondaryColor};
`

const LogoContainer = styled.ul<{ $size: number }>`
	display: grid;
	grid-gap: 2rem;
	grid-template-columns: repeat(1, 1fr);
	justify-content: center;
	padding: 0;

	@media (min-width: ${screenSizes.small}) {
		grid-gap: 4rem 5rem;
		grid-template-columns: repeat(2, 1fr);
	}

	@media (min-width: ${screenSizes.large}) {
		grid-gap: 4rem 5rem;
		grid-template-columns: repeat(${(p) => Math.min(4, p.$size)}, 1fr);
	}
`

const LogoItem = styled.li<Pick<LogoSectionProps, 'testimonialMode'>>`
	display: flex;
	flex-direction: column;

	div {
		flex: 1;
		font-size: 0.875rem;
		line-height: 1.4;
		margin-bottom: 1rem;
	}
`

export const LogoSection: FunctionComponent<LogoSectionProps> = ({
	title: sectionTitle,
	testimonialMode,
	logos = [],
	settings = {}
}) => {
	return (
		<Section {...settings}>
			{testimonialMode ? <HeadingSmall>{sectionTitle}</HeadingSmall> : <Heading>{sectionTitle}</Heading>}
			<LogoContainer $size={logos.length}>
				{logos.map(({ title, description, url, image, _key }) => (
					<LogoItem key={_key}>
						{description && <PortableText blocks={description} />}
						{image && (
							<a href={url} target="_blank" rel="noreferrer">
								<SanityImage layout="fixed" image={image} alt={title || ''} />
							</a>
						)}
					</LogoItem>
				))}
			</LogoContainer>
		</Section>
	)
}
